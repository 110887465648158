// this file added by Bearer Web Developer
import React from "react";
import styles from './send.module.scss';
import { Button } from "@material-ui/core";
import { useAppState } from "../../../../../../states/app-state";
import { validateEmailCode } from "../../../validation";

interface Props {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}
const Send: React.FC<Props> = ({ setLoading }) => {

    const appState = useAppState();
    const { accountVerification } = appState;
    const checkValidation = () => (!validateEmailCode(accountVerification.emailCode))
    const handleClick = async () => {
        if (!checkValidation()) return;
        setLoading(true);
        const result = await accountVerification?.fetchRegisterBusinessCustomerConfirmEmailCode();
        if (result?.code === 200) accountVerification?.setEmailCodeSituation("Verified");
        setLoading(false);
    }
    return (
        <div className={styles.send_part}>
            <Button className={styles.send} disableRipple={true}
                onClick={handleClick} disabled={!checkValidation()} >Verify</Button>
        </div>
    )
}
export default Send;