// this file added by Bearer Web Developer
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import styles from './verificationForm.module.scss';
import { observer } from 'mobx-react-lite';
import { validateEmailCode, validateMobileCode } from './validation';
import { useAppState } from '../../../../states/app-state';
import EmailCodeData from './emailCodeData';
import MobileCodeData from './mobileCodeData';
import DisabledClickLoading from '../../../../components/DisabledClickLoading';

const VerificationForm: React.FC = observer(() => {

    const appState = useAppState();
    const { accountVerification } = appState;
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (checkvalidation()) {
            console.log(accountVerification.getCodeData());
            setLoading(true);
            await accountVerification?.fetchRegisterBusinessCustomerSubmitVerification();
            setLoading(false);
        }
    }
    function checkvalidation() {
        let validation = true;
        validation = !validateEmailCode(accountVerification.emailCode) &&
            !validateMobileCode(accountVerification.mobileCode) &&
            accountVerification?.emailCodeSituation === "Verified" &&
            accountVerification?.mobileCodeSituation === "Verified"
        return validation;
    }
    useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [])
    return (
        <form onSubmit={handleSubmit} className={styles.account_form}>
            <EmailCodeData />
            <MobileCodeData />
            <div>
                <Button type='submit' className={styles.button} disabled={!checkvalidation()} disableRipple={true}>
                    <span>Verify</span>
                </Button>
            </div>
            {loading && <DisabledClickLoading />}
        </form>
    )
});
export default VerificationForm;