// this file added by Bearer Web Developer
import React, { useState } from 'react';
import BearerTextField from '../../../components/fields/BearerTextField';
import { Button } from '@material-ui/core';
import styles from './profileForm.module.scss';
import { useAppState } from '../../../states/app-state';
import { validateFirstName, validateGender, validateLastName, validatePlaceName, validatePostcode } from './validation';
import { observer } from 'mobx-react-lite';
import BusinessLogo from './businessLogo';
import BearerDateField from '../../../components/fields/BearerDateField';
import BearerOptionsField from '../../../components/fields/BearerOptionsField';
import { TGender } from '../../../states/completeProfileState';
import { log } from '../../../app/log';
import DisabledClickLoading from '../../../components/DisabledClickLoading';

const ProfileForm: React.FC = observer(() => {

    const appState = useAppState();
    const { completeProfile } = appState;
    const [loading, setLoading] = useState<boolean>(false);
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (checkvalidation()) {
            setLoading(true);
            log(completeProfile.getFormData());
            await completeProfile?.registerBusinessCustomerCompleteProfile();
            setLoading(false);
        }
    }

    function checkvalidation() {
        let validation = true;
        validation = !validateFirstName(completeProfile.givenName)
            && !validateLastName(completeProfile.lastName)
            && !validatePostcode(completeProfile.postCode)
            && !!completeProfile.birthDate && !!completeProfile.gender
        return validation;
    }
    return (
        <form onSubmit={handleSubmit} className={styles.account_form}>
            <BusinessLogo />
            <div className={styles.details}>
                <h6>Business Representative Details</h6>
                <div className={styles.name}>
                    <BearerTextField
                        colorStyle="gray"
                        label="First Name"
                        placeholder="..."
                        type="text"
                        name="first-name"
                        value={completeProfile.givenName}
                        onChange={(e) => completeProfile.setGivenName(e.target.value)}
                        clear={() => { completeProfile.setGivenName('') }} // added by Bearer Web Developer
                        validator={validateFirstName}
                    />
                    <BearerTextField
                        colorStyle="gray"
                        label="Last Name"
                        placeholder="..."
                        type="text"
                        name="last-name"
                        value={completeProfile.lastName}
                        onChange={(e) => completeProfile.setLastName(e.target.value)}
                        clear={() => { completeProfile.setLastName('') }}
                        validator={validateLastName}
                    />
                </div>
                <div className={styles.extra_data}>
                    <BearerDateField
                        label={'DOB'}
                        name="place-name"
                        colorStyle="gray"
                        value={completeProfile.birthDate}
                        onChange={(e) => completeProfile.setBirthDate(e.target.value)}
                        placeholder={'Place Name'}
                        clear={() => { completeProfile.setBirthDate('') }}
                        validator={validatePlaceName}
                    />
                    <BearerOptionsField
                        label={'Gender'}
                        name="gender"
                        value={completeProfile.gender}
                        colorStyle="gray"
                        options={[
                            { label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' },
                            { label: 'None-binary', value: 'None-binary' },
                            { label: 'Prefer not to say', value: 'Prefer not to say' },
                        ]}
                        onChange={(e) => completeProfile.setGender(e.target.value as TGender)}
                        validator={validateGender}
                    />
                </div>
                <div className={styles.post_code}>
                    <BearerTextField
                        colorStyle="gray"
                        label="Postcode"
                        placeholder="..."
                        type="text"
                        name="post-code"
                        value={completeProfile.postCode}
                        inputProps={{ maxLength: "4" }}
                        onChange={(e) => completeProfile.setPostcode(e.target.value)}
                        clear={() => { completeProfile.setPostcode('') }} // added by Bearer Web Developer
                        validator={validatePostcode}
                    />
                </div>
            </div>
            <div>
                <Button type='submit' className={styles.button} disabled={!checkvalidation()} disableRipple={true}>
                    <span>Save</span>
                </Button>
            </div>
            {loading && <DisabledClickLoading />}
        </form>
    )
});
export default ProfileForm;