// this file added by Bearer Web Developer
import { Instance, types } from "mobx-state-tree";

export const CreateAccountState = types
    .model("CreateAccountState", {
        open: types.optional(types.boolean, false),
        step: types.optional(types.integer, 0),
        sign_up_stage: types.optional(types.integer, 0),
        complete_registeration: types.optional(types.boolean, false),
        uid: types.optional(types.string, ''),
        sign_up_date: types.optional(types.string, ''),
        referred_by: types.optional(types.string, ''),
    })
    .actions(self => {
        return {
            clear() {
                self.open = false;
                self.step = 0;
                self.sign_up_stage = 0;
                self.uid = '';
                self.complete_registeration = false;
            },
            setUid(value: string) {
                self.uid = value;
            },
            setOpen(value: boolean) {
                self.open = value;
            },
            setStep(value: number) {
                self.step = value;
            },
            setSignUpStage(value: number) {
                self.sign_up_stage = value;
            },
            setCompleteRegisteration(value: boolean) {
                self.complete_registeration = value;
            },
            setSignUpDate(value: string) {
                self.sign_up_date = value;
            },
            setReferred_by(value: string) {
                self.referred_by = value;
            },
        }
    })
export interface ICreateAccountState extends Instance<typeof CreateAccountState> { };

