// this file added by Bearer Web Developer
import { Button } from "@material-ui/core";
import React from "react";
import styles from './completeProfileButton.module.scss';
import { observer } from "mobx-react-lite";
import { useAppState } from "../../../states/app-state";
import CompleteProfileMenu from "./completeProfileMenu";

const CompleteProfileButton: React.FC = observer(() => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!anchorEl) setAnchorEl(event.currentTarget);
    };
    const handleClose = () => { setAnchorEl(null); };
    const appState = useAppState();
    const sign_up_stage = appState?.createAccount?.sign_up_stage;
    const email = appState?.accountVerification?.email;
    const handleCompleteProfile = () => {
        if (sign_up_stage === 1) appState?.accountVerification.setOpen(true);
        else if (sign_up_stage === 2) appState?.completeProfile.setOpen(true);
    }
    return (
        <div className={styles.complete_profile_button_part}>
            <Button classes={{ root: styles.muibtn_root, text: styles.muibtn_text, }} type="button"
                onClick={handleCompleteProfile} onMouseEnter={handleMouseOver}
            >
                <div className={`${styles.complete_profile_content}`}>
                    <div className={`${styles.complete_profile} ${anchorEl ? styles.active : ''}`}>
                        <div className={sign_up_stage === 1 ? styles.persent_step_one : styles.persent_step_two}>
                            <div className={styles.circle}>
                                <div className={styles.persent_inner}>
                                    <div className={styles.circle_inner}>
                                        {sign_up_stage === 1 ? 40 : 60}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.text}><h5>Complete Your Profile</h5><h6>{email}</h6></div>
                        <div className={styles.expand}></div>
                    </div>
                    <div className={styles.hamburger_menu}>
                        <span><img src="/hamburger_menu.png" alt="" /></span>
                    </div>
                </div>
            </Button>
            <CompleteProfileMenu anchorEl={anchorEl} handleClose={handleClose} />
        </div>
    )
})
export default CompleteProfileButton;