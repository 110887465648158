/* this file added by Bearer Web Developer  */
import { Button, Dialog } from "@material-ui/core";
import React, { useState } from "react";
import styles from './changePasswordPopup.module.scss';
import BearerTextFieldPassword from "../../../components/fields/BearerTextFieldPassword";
import { useAppState } from "../../../states/app-state";
import { Observer } from "mobx-react-lite";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import { handleCurrentPassword, handlePassword, handleRepeatPassword } from "./validation";

interface IChangePasswordPopupProps { open: boolean, handleClose: () => void }
const ChangePasswordPopup: React.FC<IChangePasswordPopupProps> = ({ open, handleClose }) => {
    const appState = useAppState();
    const [passwordError, setPasswordError] = useState('');
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [loading, setLoading] = useState(false);

    const checkValidation = () => {
        return (
            !handlePassword(appState.login.newPassword) &&
            !handleRepeatPassword(appState.login.repeatPassword, appState.login?.newPassword)
            && !handleCurrentPassword(appState.login.currentPassword)
        )
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (checkValidation()) {
            setLoading(true);
            const result = await appState.login.fetchChangePassword();
            setLoading(false);
            if (!result.error) {
                await appState.login.logout();
                window.location.reload();
            } else {
                if (result?.errorType === "update password") {
                    if (result.error.message === "Firebase: Password should be at least 6 characters (auth/weak-password).") {
                        setPasswordError("Password should be at least 6 characters");
                    } else {
                        setPasswordError(result.error.message);
                    }
                } else if (result?.errorType === "current password") {
                    if (result.error.message === "Firebase: Error (auth/wrong-password).") {
                        setCurrentPasswordError("your current password is incorrect")
                    } else {
                        setCurrentPasswordError(result.error.message);
                    }
                }
            }
        }
    }
    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ className: styles.root, style: { borderRadius: "10px" } }}>
            <form onSubmit={handleSubmit} className={styles.change_password}>
                <div className={styles.header}>
                    <h5>Change your password</h5>
                    <div>
                        <Button onClick={handleClose}>
                            <img src="/back.png" alt="back" />
                        </Button>
                    </div>
                </div>
                <p>Please enter your new password</p>
                <Observer>{() => {

                    return (
                        <>
                            <div className={styles.password_input}>
                                <BearerTextFieldPassword
                                    value={appState.login.currentPassword}
                                    name="current-password"
                                    label="Current Password"
                                    placeholder="Current Password"
                                    autoComplete="current-password"
                                    onChange={(e) => {
                                        // e.target.value
                                        if (currentPasswordError) setCurrentPasswordError('');
                                        appState.login.setCurrentPassword(e.target.value)
                                    }}
                                    // onKeyPress={handleKeyPress}
                                    validator={handleCurrentPassword}
                                    errorField={currentPasswordError}
                                    colorStyle="gray"
                                />
                            </div>
                            <div className={styles.password_input}>
                                <BearerTextFieldPassword
                                    value={appState.login.newPassword}
                                    name="new-password"
                                    label="New Password"
                                    placeholder="New Password"
                                    autoComplete="new-password"
                                    onChange={(e) => {
                                        // e.target.value 
                                        if (passwordError) setPasswordError('');
                                        appState.login.setNewPasword(e.target.value)
                                    }}
                                    // onKeyPress={handleKeyPress}
                                    validator={handlePassword}
                                    errorField={passwordError}
                                    colorStyle="gray"
                                />
                            </div>
                            <div className={styles.repeat_password_input}>
                                <BearerTextFieldPassword
                                    value={appState.login.repeatPassword}
                                    name="repeat-password"
                                    label="Repeat Password"
                                    placeholder="Repeat Password"
                                    autoComplete="repeat-password"
                                    onChange={(e) => { appState.login.setRepeatPassword(e.target.value) }}
                                    // onKeyPress={handleKeyPress}
                                    validator={
                                        (value) => (handleRepeatPassword(value, appState.login?.newPassword))
                                    }
                                    colorStyle="gray"
                                />
                                <Button className={styles.submit} disabled={!checkValidation()} type='submit'>Confirm</Button>
                                {loading && <DisabledClickLoading />}
                            </div>
                        </>
                    )
                }}
                </Observer>
            </form>
        </Dialog>
    )
}
export default ChangePasswordPopup;