import React from 'react';
import { TextFieldProps } from '@material-ui/core';
import styles from './BearerTextField.module.scss'; // added by Bearer Web Developer
type IColorStyle = "blue" | "gray" | "white";
type BearerTextFieldProps = TextFieldProps & { validator?: (value: string) => string | undefined }
    & { colorStyle?: IColorStyle } & { clear?: () => void } // extended by Bearer Web Developer
    & { inputSmallStyle?: boolean } & { withoutLabel?: boolean } // extended by Bearer Web Developer
    & { asyncError?: string | undefined }; // extended by Bearer Web Developer
// inputProperties interface added by Bearer Web Developer
interface inputProperties {
    label?: string
    value?: string
    checked?: boolean
    onChange?: () => {}
    onClick?: () => {}
    disabled?: boolean
    placeholder?: string
    name?: string
}
const BearerTextField: React.FC<BearerTextFieldProps> = ({ validator, asyncError, withoutLabel, inputSmallStyle, colorStyle, ...props }) => {
    const [touched, setTouched] = React.useState(false);
    const [error, setError] = React.useState({ value: null as null | string, error: false, helperText: '' }); // value: '' changed to value: null as null for not showing empty value errors by Bearer Web Developer

    // modify by Bearer Web Developer start (add asyncError)
    React.useEffect(() => {
        if (validator) { // validator && props.value changed to validator for not showing empty value errors by Bearer Web Developer
            const value = props.value as string;
            if (error.value !== value) {
                const helperText = validator(value);
                if (helperText) {
                    setError({ value, error: true, helperText });
                } else if (asyncError) {
                    setError({ value, error: true, helperText: asyncError });
                } else {
                    if (error.error) {
                        setError({ value, error: false, helperText: '' });
                    }
                }
            }
        }
    }, [validator, props.value, touched, error, setError, asyncError])
    // modify by Bearer Web Developer end (add asyncError)

    const handleFocus = () => {
        setTouched(true);
    }
    // modified by Bearer Web Developer start
    const { inputProps, label, name, placeholder, clear, ...otherProps } = props;
    // added by Bearer Web Developer start
    const handleCloseClear = () => {
        if (clear) clear();
    }
    // added by Bearer Web Developer end
    return (
        <div className={otherProps.disabled ? `${styles.disabled} ${styles.text_input}` : styles.text_input}>
            <div className={`${colorStyle ? styles?.[colorStyle] : styles.blue}`}>
                {
                    !withoutLabel &&
                    <div className={styles.label}>
                        <label htmlFor={name || label?.toString()}>{label}</label>
                    </div>
                }
                <div className={`${styles.input_part} ${inputSmallStyle ? styles.input_small : ''}`}>
                    <input {...inputProps} name={name || label?.toString()}
                        id={name || label?.toString()}
                        {...otherProps as inputProperties} onFocus={handleFocus} placeholder={placeholder} />
                    {/* close clear button added by Bearer Web Developer start */}
                    {
                        props.value && !otherProps.disabled &&
                        <img className={styles.close_clear}
                            src={`/close-clear-${colorStyle === "gray" ? "black" : "blue"}.svg`}
                            alt="" width={24} height={24}
                            onClick={handleCloseClear}
                        />
                    }
                    {/* close clear button added by Bearer Web Developer end */}
                </div>
                {error.error &&
                    <div className={styles.error}>
                        <img src="/error-red.png" alt="" />
                        <div className={!colorStyle || (colorStyle && colorStyle === "blue") ? styles.error_blue : ''}>
                            {error.helperText}
                        </div>
                    </div>}
            </div>
        </div>
    );
    // modified by Bearer Web Developer end
}
export default BearerTextField;