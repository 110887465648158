// this file added by Bearer Web Developer
export const validateEmailCode = (value: string) => {
    if (value.length === 0) return 'Email code is required';
    else if (!value.match(/^\d{6}$/)) return "6 Digit (Numbers Only)"
}
export const validateMobileCode = (value: string) => {
    if (value.length === 0) return 'Mobile code is required';
    else if (!value.match(/^\d{6}$/)) return "6 Digit (Numbers Only)"
}


