// this file added by Bearer Web Developer
import React from 'react';
import { Button } from '@material-ui/core';
import styles from './stepOne.module.scss';
import { useAppState } from '../../../states/app-state';
import { observer } from 'mobx-react-lite';
import AccountForm from './accountForm';


const StepTwo: React.FC = observer(() => {
    const appState = useAppState();
    const { createAccount } = appState;
    const handleClose = () => {
        createAccount?.setStep(0);
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <h5>Create Account</h5>
                <div className={styles.step_content}>
                    <div className={styles.step_number}><span>2</span>/2</div>
                    <Button onClick={handleClose}><img src="/back.png" alt="back" /></Button>
                </div>
            </div>
            <p>How did you hear about Bearer?</p>
            <AccountForm />
            <div className={styles.step_show}><div className={styles.fill}></div></div>
        </div>
    )
});
export default StepTwo;