// this file added by Bearer Web Developer
import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import styles from './accountForm.module.scss';
import { observer } from 'mobx-react-lite';
import data from './data.json';
import BearerTextField from '../../../../components/fields/BearerTextField';
import { useAppState } from '../../../../states/app-state';
import DisabledClickLoading from '../../../../components/DisabledClickLoading';
import { log } from '../../../../app/log';
import { InvateMethod } from '../../../../states/accountFormConfirmState';


const AccountForm: React.FC = observer(() => {

    const appState = useAppState();
    const { accountFormConfirm, createAccount } = appState;
    const { setActiveData, invateMethod, setInviteCode, inviteCode } = accountFormConfirm;
    const [loading, setLoading] = useState<boolean>(false);
    const handleCLick = (data: InvateMethod) => {
        setActiveData(data);
    }
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (checkvalidation()) {
            log({ invateMethod, inviteCode });
            setLoading(true);
            const resultIp = await accountFormConfirm?.createUserRequestFirebase();
            if (resultIp) {
                const registerResult = await accountFormConfirm?.fetchRegisterBusinessCustomerStepOne(resultIp);
                if (registerResult?.code === 200) {
                    createAccount?.setStep(2);
                }
            }
            setLoading(false);
        }
    }
    function checkvalidation() {
        return !!(invateMethod && (invateMethod?.title === "Referral Code" ? inviteCode : true))
    }
    return (
        <form onSubmit={handleSubmit} className={styles.account_form}>
            <ul>
                {data.map((known, i) => (
                    <li key={i} onClick={() => handleCLick(known as InvateMethod)}
                        className={known?.id === invateMethod?.id ? styles.active : ''}>
                        <div className={styles.circle}>
                            <img src={`${known?.src}-${known?.id === invateMethod?.id ? 'white' : 'grey'}.webp`}
                                alt={known?.title} width={32} height={32} />
                        </div>
                        <h5>{known?.title}</h5>
                    </li>
                ))}
            </ul>
            {
                invateMethod && invateMethod?.title === "Referral Code" &&
                <div className={styles.input}>
                    <BearerTextField
                        colorStyle="gray"
                        label="Invite Code"
                        placeholder="..."
                        type="text"
                        name="invite-code"
                        value={inviteCode}
                        onChange={(e) => setInviteCode(e.target.value)}
                        clear={() => { setInviteCode('') }}
                    />
                </div>
            }
            <div>
                <Button type='submit' className={styles.button} disabled={!checkvalidation()} disableRipple={true}>
                    <span>Confirm</span>
                </Button>
                {loading && <DisabledClickLoading />}
            </div>
        </form>
    )
});
export default AccountForm;