// this file added by Bearer Web Developer
import { Button, Dialog } from "@material-ui/core"
import { observer } from "mobx-react-lite";
import styles from './editMobileVertificationPopup.module.scss';
import { useAppState } from "../../../states/app-state";
import BearerTextField from "../../../components/fields/BearerTextField";
import { log } from "../../../app/log";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import { useState } from "react";
import { validatePhone } from "./validation";
import klaviyo from "../../../states/klaviyo";

interface Props {
    open: boolean
}
const EditMobileVertificationPopup: React.FC<Props> = observer(({ open }) => {

    const appState = useAppState();
    const { editMobileVertification, accountVerification } = appState;
    const [loading, setLoading] = useState(false);
    const handleClose = () => { editMobileVertification?.setOpen(false); };
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (checkvalidation()) {
            log({ mobileNumber: editMobileVertification?.mobileNumber });
            setLoading(true);
            const result = await editMobileVertification?.fetchRegisterBusinessCustomerEditMobile();
            if (result?.code === 200) {
                const resultSend = await accountVerification?.fetchRegisterBusinessCustomerSendSMSCode();
                if (resultSend?.code === 200) {
                    accountVerification?.setMobileCodeSituation("Verifying");
                    handleClose();
                }
            }
            setLoading(false);
        }
    }
    function checkvalidation() {
        let validation = true;
        validation = !validatePhone(editMobileVertification?.mobileNumber);
        return validation;
    }

    return (
        <Dialog open={open} hideBackdrop={true} PaperProps={{ className: styles.root, style: { borderRadius: "10px" } }}
            onClose={handleClose}>
            <div className={styles.main_content}>
                <div className={styles.header}>
                    <h5>Edit Mobile</h5>
                    <Button onClick={handleClose}><img src="/back.png" alt="back" /></Button>
                </div>
                <p>Enter a new number</p>
                <form onSubmit={handleSubmit} className={styles.account_form}>
                    <div className={styles.input}>
                        <BearerTextField
                            colorStyle="gray"
                            label="Mobile Number"
                            inputProps={{ maxLength: "12" }} // add maxlength of Phone Number of destination by Bearer Web Developer
                            placeholder={""}
                            type="text"
                            name="mobile-number"
                            value={editMobileVertification?.mobileNumber}
                            onChange={(e) => {
                                const beginning = "+614", beginningNumber = 4;
                                const text = e.target.value.slice(0, beginningNumber) !== beginning ? beginning + e.target.value.slice(beginningNumber) : e.target.value;
                                editMobileVertification.setMobileNumber(text);
                            }}
                            clear={() => { editMobileVertification.setMobileNumber('+614') }} // added by Bearer Web Developer
                            validator={validatePhone}
                        />
                    </div>
                    <Button type='submit' className={styles.button} disabled={!checkvalidation()} disableRipple={true}>
                        <span>Update</span>
                    </Button>
                </form>
            </div>
            {loading && <DisabledClickLoading />}
        </Dialog>
    )
});
export default EditMobileVertificationPopup;
