// this file added by Bearer Web Developer
import { getRemoteConfigData } from "../../api/bearer-api";
interface IKlaviyoProfileData {
    type: 'profile',
    attributes: {
        email: string,
        properties: {
            external_id?: string,
            last_open?: string, // Today FORMAT YYYY-MM-DD
            platform_type?: "web",
            phone_number?: string,
            first_name?: string,
            last_name?: string,
            organization?: string,
            user_type?: 'Business',
            sign_up_date?: string,
            referred_by?: string,
            gender?: string,
            dob?: string,
            loyalty_tier?: 'Basic',
            is_profile_completed?: boolean,
        }
    }
}
interface IKlaviyoEventData {
    event: string,
    email: string,
    phone_number?: string,
    organization?: string,
    user_type?: 'Business',
    sign_up_date?: string, // Today FORMAT YYYY-MM-DD
    platform_type?: 'web',
    referred_by?: string,
    external_id?: string,
    first_name?: string,
    last_name?: string,
    gender?: string,
    dob?: string,  // FORMAT YYYY-MM-DD
    loyalty_tier?: 'Basic',
    is_profile_completed?: boolean,


}
const createOrUpdateProfile = async (data: IKlaviyoProfileData) => {

    const { klaviyoApiPublicKey } = await getRemoteConfigData();
    const url = `https://a.klaviyo.com/client/profiles/?company_id=${klaviyoApiPublicKey}`;
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            revision: '2024-10-15',
            'content-type': 'application/json'
        },
        body: JSON.stringify({ data: data })
    };

    await fetch(url, options)
        .then(res => {
            // console.log(res);
        })
        .catch(err => {
            // console.error('error:' + err)
        });
}

const createEvent = async (data: IKlaviyoEventData) => {
    const { event, email } = data;
    const { klaviyoApiPublicKey } = await getRemoteConfigData();
    const url = `https://a.klaviyo.com/client/events/?company_id=${klaviyoApiPublicKey}`;
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            revision: '2024-10-15',
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                type: 'event',
                attributes: {
                    properties: {},
                    metric: { data: { type: 'metric', attributes: { name: event } } },
                    profile: {
                        data: {
                            type: 'profile', attributes: { email: email, properties: {}, }
                        }
                    }
                }
            }
        })
    };

    await fetch(url, options)
        .then(res => {
            // console.log(res);
        })
        .catch(err => {
            // console.error('error:' + err)
        });
}

const createClientSubscription = async (data: { email: string, phone_number: string }) => {
    const { email, phone_number } = data;
    const { environmentCode, klaviyoApiPublicKey } = await getRemoteConfigData();
    const list_id = environmentCode === "PRODUCTION" ? "Wxv7Cf" : "UXLf7K";
    const url = `https://a.klaviyo.com/client/subscriptions/?company_id=${klaviyoApiPublicKey}`;
    const options = {
        method: 'POST',
        headers: { revision: '2024-10-15.pre', 'content-type': 'application/vnd.api+json' },
        body: JSON.stringify({
            data: {
                type: "subscription",
                attributes: {

                    profile: {
                        data: {
                            type: "profile",
                            attributes: {
                                email,
                                phone_number,
                                subscriptions: {
                                    email: {
                                        marketing: {
                                            consent: "SUBSCRIBED",
                                        }
                                    },
                                    sms: {
                                        marketing: {
                                            consent: "SUBSCRIBED",
                                        },
                                        transactional: {
                                            consent: "SUBSCRIBED",
                                        }
                                    }
                                },
                            },

                        }
                    },

                },
                relationships: {
                    list: {
                        data: {
                            type: "list",
                            id: list_id
                        }
                    }
                }
            }
        }),
    };

    await fetch(url, options)
        .then(res => {
            console.log(res);
        })
        .catch(err => {
            console.error('error:' + err)
        });
}
const klaviyo = { createOrUpdateProfile, createEvent, createClientSubscription };
export default klaviyo;



