// this file added by Bearer Web Developer
import { observer } from 'mobx-react-lite';
import BearerTextField from '../../../../../components/fields/BearerTextField';
import { useAppState } from '../../../../../states/app-state';
import { validateMobileCode } from '../validation';
import styles from './mobileCodeData.module.scss';
import { CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { log, error } from '../../../../../app/log';
import Buttons from './buttons';

const MobileCodeData: React.FC = observer(() => {

    const appState = useAppState();
    const { accountVerification } = appState;
    const mobileNumber = accountVerification?.mobileNumber;
    const verifying = accountVerification?.mobileCodeSituation === "Verifying";
    const verified = accountVerification?.mobileCodeSituation === "Verified";
    const [loading, setLoading] = useState(false);
    const getRegisterMobileCode = async () => {
        if (verifying) return;
        try {
            if (!loading) setLoading(true);
            const result = await accountVerification?.fetchRegisterBusinessCustomerSendSMSCode();
            if (result?.code === 200) accountVerification?.setMobileCodeSituation("Verifying");
            setLoading(false);
        } catch (err) {
            setLoading(false);
            error(err);
        }
    }
    useEffect(() => {
        getRegisterMobileCode();
        return () => {
            setLoading(false);
        }
    }, [])

    return (
        <div className={styles.mobile_code_data}>
            {
                loading ? <div className={styles.loading}><CircularProgress /></div> :
                    <>
                        <div className={styles.label}>
                            <label htmlFor="Mobile Code">
                                Enter the code sent to your <span>mobile ({mobileNumber}).</span>
                            </label>
                        </div>
                        <div className={styles.field}>
                            <BearerTextField
                                colorStyle="gray"
                                withoutLabel={true}
                                disabled={verified}
                                inputProps={{ maxLength: "6" }}
                                name='Mobile Code'
                                placeholder={""}
                                value={accountVerification.mobileCode}
                                onChange={(e) => {
                                    accountVerification.setAsyncErrorMobile('');
                                    accountVerification.setMobileCode(e.target.value);
                                }}
                                clear={() => { accountVerification.setMobileCode('') }}
                                validator={validateMobileCode}
                                asyncError={accountVerification?.asyncErrorMobile}
                            />
                            <Buttons getRegisterMobileCode={getRegisterMobileCode} setLoading={setLoading} />
                        </div>
                    </>
            }
        </div>
    )
})
export default MobileCodeData;