// this file added by Bearer Web Developer
import BearerTextField from '../../../../components/fields/BearerTextField';
import { useAppState } from '../../../../states/app-state';
import { validateEmailCode } from '../validation';
import styles from './emailCodeData.module.scss';
import { CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { error } from '../../../../app/log';
import Buttons from './buttons';

const EmailCodeData: React.FC = observer(() => {

    const appState = useAppState();
    const { accountVerification } = appState;
    const email = accountVerification?.email;
    const verifying = accountVerification?.emailCodeSituation === "Verifying";
    const verified = accountVerification?.emailCodeSituation === "Verified";
    const [loading, setLoading] = useState(false);
    const getRegisterEmailCode = async () => {
        if (verifying) return;
        try {
            if (!loading) setLoading(true);
            const result = await accountVerification?.fetchRegisterBusinessCustomerSendEmailCode();
            if (result?.code === 200) accountVerification?.setEmailCodeSituation("Verifying");
            setLoading(false);
        } catch (err) {
            setLoading(false);
            error(err);
        }
    }
    useEffect(() => {
        getRegisterEmailCode();
        return ()=>{
            setLoading(false);
        }
    }, [])
    return (
        <div className={styles.email_code_data}>
            {
                loading ? <div className={styles.loading}><CircularProgress /></div> :
                    <>
                        <div className={styles.label}>
                            <label htmlFor="Email Code">
                                Enter the code sent to your <span>email ({email}).</span>
                            </label>
                        </div>
                        <div className={styles.field}>
                            <BearerTextField
                                disabled={verified}
                                colorStyle="gray"
                                withoutLabel={true}
                                inputProps={{ maxLength: "6" }}
                                name='Email Code'
                                placeholder={""}
                                value={accountVerification.emailCode}
                                onChange={(e) => {
                                    accountVerification.setAsyncErrorEmail('');
                                    accountVerification.setEmailCode(e.target.value);
                                }}
                                clear={() => { accountVerification.setEmailCode('') }}
                                validator={validateEmailCode}
                                asyncError={accountVerification?.asyncErrorEmail}
                            />
                            <Buttons getRegisterEmailCode={getRegisterEmailCode} setLoading={setLoading} />
                        </div>
                    </>
            }
        </div>
    )
})
export default EmailCodeData;