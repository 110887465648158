// this file added by Bearer Web Developer
import { Menu, MenuItem } from "@material-ui/core";
import React from "react";
import styles from './completeProfileMenu.module.scss';
import { observer } from "mobx-react-lite";
import { useAppState } from "../../../../states/app-state";
import LogoutConfirm from "../../../../components/profile/logoutConfirm";

interface Props {
    anchorEl: HTMLElement | null
    handleClose: () => void
}

const CompleteProfileMenu: React.FC<Props> = observer(({ anchorEl, handleClose }) => {

    const open = Boolean(anchorEl);
    const appState = useAppState();
    const [logoutConfirmOpen, setLogoutConfirmOpen] = React.useState(false); // added by Bearer Web Developer
    const handleLogoutConfirm = () => {
        setLogoutConfirmOpen(true);
    }
    const handleLogoutConfirmClose = () => {
        setLogoutConfirmOpen(false);
    }
    return (
        <>
            <Menu
            disablePortal={true}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    onMouseLeave: handleClose
                }}
                classes={{ paper: styles.paper }}
                style={{ top: "55px", borderRadius: "10px" }}
                PaperProps={{
                    style: {
                        background: "var(--neutral-hsb-89, #E3E3E3)",
                        boxShadow: "0px 38px 31px -27px rgba(53, 53, 53, 0.37)"
                    }
                }}
            >
                <div className={styles.complete_profile_menu}>
                <MenuItem style={{ padding: "0 14px" }} className={styles.menu_item} button component="a" href={appState.webAppRemoteConfigParameters?.supportUrl} target="_blank" ><span className={styles.text}>Support</span></MenuItem>
                <MenuItem style={{ padding: "0 14px" }} className={styles.menu_item} button component="a" href={appState.webAppRemoteConfigParameters?.businessCustomerLegalUrl} target="_blank">
                    <span className={styles.text}>Legal</span>
                </MenuItem>
                <MenuItem style={{ padding: "0 14px" }} className={styles.menu_item} onClick={handleLogoutConfirm}><span className={styles.text_end}>Log out</span></MenuItem>
                </div>
            </Menu>
            {logoutConfirmOpen && <LogoutConfirm open={logoutConfirmOpen} handleClose={handleLogoutConfirmClose} />}
        </>
            
    )
})
export default CompleteProfileMenu;