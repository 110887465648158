// this file added by Bearer Web Developer
import { flow, getEnv, getParentOfType, Instance, types } from "mobx-state-tree";
import { AppState, EnvType } from "../app-state";
import { error, log } from "../../app/log";
import { getAuth, updateEmail, User } from "firebase/auth";
import registerBusinessCustomerEditEmail from "./registerBusinessCustomerEditEmail";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import klaviyo from "../klaviyo";

export const EditEmailVertificationState = types
    .model("EditEmailVertificationState", {
        open: types.optional(types.boolean, false),
        email: types.optional(types.string, ''),
        password: types.optional(types.string, ''),
    })
    .actions(self => {
        return {
            setOpen(value: boolean) {
                self.open = value;
            },
            setEmail(value: string) {
                self.email = value;
            },
            setPassword(value: string) {
                self.password = value;
            },
        }
    }).actions(self => {
        return {
            reauthenticateWithCredentialFirebase: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const auth = getAuth();
                    const user = auth?.currentUser;
                    const credential = EmailAuthProvider.credential(user?.email as string, self?.password);
                    yield reauthenticateWithCredential(user as User, credential)
                    // Re-authentication successful. Proceed with the sensitive operation.
                    console.log('Re-authenticated successfully');
                    return true;
                } catch (err: any) {
                    // An error occurred
                    console.error('Error during re-authentication', err?.message);
                    const appState = getParentOfType(self, AppState);
                    const errorMessage = err?.message === "Firebase: Error (auth/wrong-password)." ?
                        "your password is incorrect" : err?.message;
                    appState.setSnackMessage({
                        message: errorMessage || 'unknown error!',
                    });
                }
            }),
            fetchRegisterBusinessCustomerEditEmail: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                const result = yield registerBusinessCustomerEditEmail(self.email);
                try {
                    if (result?.code === 401 || result?.code === 499 || result.code === 500) {
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result.message || 'unknown error!',
                        });
                    } else if (result?.code === 200) {
                        const appState = getParentOfType(self, AppState);
                        appState?.accountVerification.setEmail(self.email);
                        appState?.profile?.setEmail(self?.email);
                        // klaviyo client subscription create profile start
                        klaviyo?.createClientSubscription({
                            email: self.email,
                            phone_number: `+61${appState?.profile?.phoneNumber.slice(1)}`
                        })
                        // klaviyo client subscription create profile end
                        return result;
                    } else {
                        log(result);
                    }
                } catch (err: any) {
                    error(err);
                }
            }),
        }
    }).actions(self => {
        return {
            updateEmailUserRequestFirebase: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result = yield self.reauthenticateWithCredentialFirebase();
                    if (result) {
                        const auth = getAuth();
                        yield updateEmail(auth.currentUser as User, self.email);
                        // Email updated! in firebase
                        const editEmailResult = yield self.fetchRegisterBusinessCustomerEditEmail();
                        if (editEmailResult?.code === 200) {
                            return true;
                        }
                    }
                } catch (err: any) {
                    // An error occurred
                    error(err);
                    const appState = getParentOfType(self, AppState);
                    const errorMessage = err?.message === "Firebase: Error (auth/email-already-in-use)." ?
                        "This email is already in use" : err?.message;
                    appState.setSnackMessage({
                        message: errorMessage || 'unknown error!',
                    });
                }
            }),
        }
    })
export interface IEditEmailVertificationState extends Instance<typeof EditEmailVertificationState> { };

