// this file added by Bearer Web Developer
import { flow, getEnv, getParentOfType, Instance, types } from "mobx-state-tree";
import { AppState, EnvType, IProfileState, MessageEnum } from "../app-state";
import { error, log } from "../../app/log";
import registerBusinessCustomerCompleteProfile from "./registerBusinessCustomerCompleteProfile";
import { TRegisterBusinessCustomerCompleteProfileData, TRegisterBusinessCustomerCompleteProfileResponse } from "./models/registerBusinessCustomerCompleteProfile";
import klaviyo from "../klaviyo";
import { analytics, getRemoteConfigData } from "../../api/bearer-api";
import { logEvent } from "firebase/analytics";
import ReactPixel from 'react-facebook-pixel'; // added by Bearer Web Developer
import { ICreateAccountState } from "../createAccountState";
export type TGender = "Male" | "Female" | 'None-binary' | "Prefer not to say" | '';

export const CompleteProfileState = types
    .model("CompleteProfileState", {
        open: types.optional(types.boolean, false),
        givenName: types.optional(types.string, ''),
        lastName: types.optional(types.string, ''),
        birthDate: types.optional(types.string, ''),
        postCode: types.optional(types.string, ''),
        avatarUrl: types.optional(types.string, ''),
        previwAvatarUrl: types.optional(types.string, ''),
        gender: types.optional(types.frozen<TGender>(), '')
    })
    .actions(self => {
        return {
            getFormData() {
                return {
                    given_name: self.givenName,
                    last_name: self.lastName,
                    birth_date: self.birthDate,
                    gender: self.gender,
                    post_code: self.postCode,
                    avatar_url: self.avatarUrl,
                }
            },
            clear() {
                self.open = false;
                self.givenName = '';
                self.lastName = '';
                self.birthDate = '';
                self.postCode = '';
                self.avatarUrl = '';
                self.previwAvatarUrl = '';
                self.gender = '';
            },
            setOpen(value: boolean) {
                self.open = value;
            },
            setGivenName(value: string) {
                self.givenName = value;
            },
            setLastName(value: string) {
                self.lastName = value;
            },
            setBirthDate(value: string) {
                self.birthDate = value;
            },
            setGender(value: TGender) {
                self.gender = value;
            },
            setPostcode(value: string) {
                self.postCode = value;
            },
            setPreviwAvatarUrl(value: string) {
                self.previwAvatarUrl = value;
            },
            setAvatarUrl(value: string) {
                self.previwAvatarUrl = value;
            },
        }
    }).actions(self => {

        return {
            registerBusinessCustomerCompleteProfile: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const data: TRegisterBusinessCustomerCompleteProfileData = self.getFormData();
                    const result: TRegisterBusinessCustomerCompleteProfileResponse = yield registerBusinessCustomerCompleteProfile(data);
                    if (result?.code === 401 || result?.code === 499) {
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result.message || 'unknown error!',
                        });
                    } else if (result?.code === 200) {
                        const createAccount : ICreateAccountState = getParentOfType(self, AppState)?.createAccount;
                        createAccount?.setSignUpStage(result?.sign_up_stage);
                        // Firebase Analytics (Google Analytics) User Profile Completed start
                        console.log("sign_up_completed Firebase Analytics (Google Analytics) User Profile Completed")
                        logEvent(analytics, "sign_up_completed", {
                            method: "Ggl.F.B. Email",
                            user_type: "Business"
                        });
                        // Firebase Analytics (Google Analytics) User Profile Completed end
                        // klaviyo profile RegistrationCompleted start
                        const profile: IProfileState = getParentOfType(self, AppState)?.profile;
                        profile?.setFirstName(data?.given_name);
                        profile?.setLastName(data?.last_name);
                        yield klaviyo.createOrUpdateProfile({
                            type: 'profile',
                            attributes: {
                                email: profile?.email,
                                properties: {
                                    phone_number: `+61${profile?.phoneNumber.slice(1)}`,
                                    first_name: data?.given_name,
                                    last_name: data?.last_name,
                                    gender: self.gender,
                                    dob: self.birthDate,  // FORMAT YYYY-MM-DD 
                                    user_type: 'Business',
                                    loyalty_tier: 'Basic',
                                    is_profile_completed: true,
                                    platform_type: 'web',
                                    external_id: profile?.uid?.slice(-7) || '',
                                    sign_up_date: createAccount?.sign_up_date
                                }
                            },
                        });
                        // klaviyo profile RegistrationCompleted end
                        // klaviyo SignUpCompleted start
                        yield klaviyo.createEvent({ event: "SignUpCompleted", email: profile?.email });
                        // klaviyo SignUpCompleted end
                        // klaviyo profile end
                        // RegistrationCompleted (Custom Event) Facebook – Pixel – Meta start
                        const { environmentCode } = yield getRemoteConfigData(); // added by Bearer Web Developer
                        if (environmentCode === "PRODUCTION") {
                            ReactPixel.trackSingleCustom('3587653761499122', 'RegistrationCompleted'); // For tracking custom events.
                        }
                        // RegistrationCompleted (Custom Event) Facebook – Pixel – Meta end
                        return result;
                    } else if (result.code === 500) {
                        log(result);
                    }


                } catch (err: any) {
                    error(err);
                }
            }),
            deleteProfileImage: flow(function* () {
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                const uid: string = getParentOfType(self, AppState)?.createAccount?.uid;
                try {
                    if (!uid) return;
                    yield api.deleteProfileImage(uid);
                    self.avatarUrl = "";
                } catch (err: any) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: err?.message || 'unknown error!',
                        error: MessageEnum.Unknown
                    })
                }
            }),
            updateAvatar: flow(function* (image: Blob) {
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                const uid: string = getParentOfType(self, AppState)?.createAccount?.uid;
                try {
                    if (!uid) return;
                    const result = yield api.uploadProfileImage(uid, image);
                    self.avatarUrl = result;
                } catch (err: any) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: err?.message || 'unknown error!',
                        error: MessageEnum.Unknown
                    })
                }
            }),
        }
    })
export interface ICompleteProfileState extends Instance<typeof CompleteProfileState> { };

