// this file added by Bearer Web Developer
import { Button, Dialog } from "@material-ui/core"
import { observer } from "mobx-react-lite";
import styles from './accountVerificationPopup.module.scss';
import { useAppState } from "../../states/app-state";
import VerificationForm from "./verificationForm";
import EditEmailVertificationPopup from "./../accountVerificationExtraPopups/editEmailVertificationPopup";
import EditMobileVertificationPopup from "./../accountVerificationExtraPopups/editMobileVertificationPopup";
import FreeDelivery from "./freeDelivery";

interface Props {
    open: boolean
}
const AccountVerificationPopup: React.FC<Props> = observer(({ open }) => {

    const appState = useAppState();
    const { createAccount, accountVerification, completeProfile,
        editEmailVertification, editMobileVertification
    } = appState;
    const sign_up_stage = appState?.createAccount?.sign_up_stage;
    const handleClose = () => {
        if (sign_up_stage === 2) {
            if (createAccount?.complete_registeration) completeProfile?.setOpen(true);
            accountVerification?.setOpen(false);
        } else {
            // sign_up_stage = 1
            if (createAccount?.complete_registeration) return;
            accountVerification?.setOpen(false);
        }
    }
    return (
        <>
            <Dialog open={open} PaperProps={{ className: styles.root, style: { borderRadius: "10px" } }} onClose={handleClose}>
                {
                    sign_up_stage === 2 ? <FreeDelivery /> :
                        <div className={styles.main_content}>
                            <div className={styles.header}>
                                <h5>Account Verification</h5>
                                <div><Button onClick={handleClose}><img src="/cancel.png" alt="X" /></Button></div>
                            </div>
                            <p>Verify your email and phone to place your <span>FREE</span> order and unlock more features.</p>
                            <VerificationForm />
                        </div>
                }
            </Dialog>
            {editEmailVertification?.open && <EditEmailVertificationPopup open={editEmailVertification?.open} />}
            {editMobileVertification?.open && <EditMobileVertificationPopup open={editMobileVertification?.open} />}
        </>
    )
});
export default AccountVerificationPopup;
