// this file added by Bearer Web Developer
export const validateEmail = (value: string) => {
    if (!(value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) return 'Email address is not valid!'
}
export const handlePassword = (value: string) => {
    if (value.length === 0) return "Password is required";
    if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/))
        return "Minimum 8 characters, at least 1 Uppercase letter, 1 Lowercase letter, 1 Number and 1 Special character"
    // if (value.length < 3) {
    //     return "Password must be at least three characters";
    // }
    return '';
}
