/* this file added by Bearer Web Developer  */
import { firebaseApp } from "../../../api/bearer-api";
import { error } from "../../../app/log";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { TRegisterBusinessCustomerConfirmSMSCodeResponse } from "../models/registerBusinessCustomerConfirmSMSCode";


const registerBusinessCustomerConfirmSMSCode = async (token: string): Promise<TRegisterBusinessCustomerConfirmSMSCodeResponse| void> => { // void type added by Bearer Web Developer
    try {
        // add the location string as you call getFunctions
        const functions = getFunctions(firebaseApp, 'australia-southeast2');
        const t = httpsCallable<any, TRegisterBusinessCustomerConfirmSMSCodeResponse>(functions, 'registerBusinessCustomerConfirmSMSCode');
        const result = await t({ token });
        return result.data;
    } catch (err: any) {
        error(err); // modify by Bearer Web Developer
        error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer 
    }
}
export default registerBusinessCustomerConfirmSMSCode;