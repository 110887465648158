// this file added by Bearer Web Developer
import { Menu } from "@material-ui/core";
import React from "react";
import styles from './accessingFeatureMenu.module.scss';
import { observer } from "mobx-react-lite";
import { useAppState } from "../../states/app-state";

const AccessingFeatureMenu: React.FC<any> = observer(({ accessingFeatureMenuElement, setAccessingFeatureMenuElement }) => {
    
    const appState = useAppState();
    const { accountVerification, completeProfile, createAccount } = appState;
    const sign_up_stage = createAccount?.sign_up_stage;
    const text  = sign_up_stage === 1 ? 'Email & Phone Verification' :'Completing The Profile';
    const open = Boolean(accessingFeatureMenuElement);
    const handleClose = () => {
        setAccessingFeatureMenuElement(null);
    };
    const handleClickText = () => {
        if (sign_up_stage === 1) accountVerification.setOpen(true);
        else if (sign_up_stage === 2) completeProfile.setOpen(true);
        setAccessingFeatureMenuElement(null);
    }

    return (
        <>
            <Menu
                id="basic-menu"
                anchorEl={accessingFeatureMenuElement}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                getContentAnchorEl={undefined}
                anchorOrigin={{ vertical: -50 + accessingFeatureMenuElement?.clientHeight!, horizontal: 'center' }}
                transformOrigin={{ vertical: -50, horizontal: 'center' }}
                classes={{ paper: styles.paper }}
            >
                <div>
                    <div className={styles.arrow}>
                        <svg onClick={handleClickText} xmlns="http://www.w3.org/2000/svg" width="31" height="23" viewBox="0 0 31 23" fill="none">
                            <path d="M12.0642 2.76326C13.6156 0.160934 17.3844 0.160931 18.9358 2.76325L31 23L0 23L12.0642 2.76326Z" fill="white" />
                        </svg>
                    </div>
                    <div onClick={handleClickText} className={styles.accessing_feature}>
                        {`${text} needed for accessing this feature`}
                    </div>
                </div>
            </Menu>
        </>
    )
})
export default AccessingFeatureMenu;