// this file added by Bearer Web Developer
export const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

export async function compressImage(
    imageSrc: string,
): Promise<Blob | null> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    const imageType = 'image/JPEG';

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0);

    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            resolve(file);
        }, imageType, 0.9);
    });
}
export function readFile(file: File): Promise<string | null> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener(
            "load",
            () => resolve(reader.result as string),
            false
        );
        reader.readAsDataURL(file);
    });
}