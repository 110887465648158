// this file added by Bearer Web Developer

export const validateFirstName = (value: string) => {
    if (value.length === 0) return "First Name is required";
    else if (!value.match(/^[a-zA-Z\s]{2,}$/)) return "Minimum 2 Letters, No Numbers or Symbols"
}
export const validateLastName = (value: string) => {
    if (value.length === 0) return "Last Name is required";
    else if (!value.match(/^[a-zA-Z\s]{2,}$/)) return "Minimum 2 Letters, No Numbers or Symbols"
}
export const validatePostcode = (value: string) => {
    if (value.length === 0) return "Post code is required";
    else if (!value.match(/^\d{4}$/)) return "4 Digit Number";
}
export const validateGender = (value: string) => {
    if (value.length === 0) return "Gender is required";
}
export const validatePlaceName = (value: string) => {
    if (value.length === 0) return "Place Name is required";
}

