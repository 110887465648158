// this file added by Bearer Web Developer
import { Button, Dialog } from "@material-ui/core"
import { observer } from "mobx-react-lite";
import styles from './editEmailVertificationPopup.module.scss';
import { useAppState } from "../../../states/app-state";
import BearerTextField from "../../../components/fields/BearerTextField";
import { log } from "../../../app/log";
import { handlePassword, validateEmail } from "./validation";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import { useState } from "react";
import BearerTextFieldPassword from "../../../components/fields/BearerTextFieldPassword";

interface Props {
    open: boolean
}
const EditEmailVertificationPopup: React.FC<Props> = observer(({ open }) => {

    const appState = useAppState();
    const { editEmailVertification, accountVerification } = appState;
    const [loading, setLoading] = useState(false);
    const handleClose = () => { editEmailVertification?.setOpen(false); };
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (checkvalidation()) {
            log({ email: editEmailVertification?.email });
            setLoading(true);
            const result = await editEmailVertification?.updateEmailUserRequestFirebase();
            if (result) {
                const resultSend = await accountVerification?.fetchRegisterBusinessCustomerSendEmailCode();
                if (resultSend?.code === 200) {
                    accountVerification?.setEmailCodeSituation("Verifying");
                    handleClose();
                }
            }
            // const result = await editEmailVertification?.fetchRegisterBusinessCustomerEditEmail();
            // if (result?.code === 200) {
            //     const resultSend = await accountVerification?.fetchRegisterBusinessCustomerSendEmailCode();
            //     if (resultSend?.code === 200) {
            //         accountVerification?.setEmailCodeSituation("Verifying");
            //         handleClose();
            //     }
            // }
            setLoading(false);
        }
    }
    function checkvalidation() {
        let validation = true;
        validation = !validateEmail(editEmailVertification?.email) && !handlePassword(editEmailVertification?.password);
        return validation;
    }

    return (
        <Dialog open={open} hideBackdrop={true} PaperProps={{ className: styles.root, style: { borderRadius: "10px" } }}
            onClose={handleClose}>
            <div className={styles.main_content}>
                <div className={styles.header}>
                    <h5>Edit Email</h5>
                    <Button onClick={handleClose}><img src="/back.png" alt="back" /></Button>
                </div>
                <p>Enter a new address</p>
                <form onSubmit={handleSubmit} className={styles.account_form}>
                    <div className={styles.input}>
                        <BearerTextField
                            colorStyle="gray"
                            label="Email address"
                            placeholder="email@example.com"
                            type="email"
                            name="email"
                            value={editEmailVertification.email}
                            onChange={(e) => editEmailVertification.setEmail(e.target.value)}
                            clear={() => { editEmailVertification.setEmail('') }} // added by Bearer Web Developer
                            validator={validateEmail}
                        />
                    </div>
                    <div className={styles.input}>
                        <BearerTextFieldPassword
                            value={editEmailVertification.password}
                            name="password"
                            label="Current Password"
                            placeholder=""
                            autoComplete="password"
                            onChange={(e) => { editEmailVertification.setPassword(e.target.value); }}
                            validator={handlePassword}
                            colorStyle="gray"
                        />
                    </div>
                    <Button type='submit' className={styles.button} disabled={!checkvalidation()} disableRipple={true}>
                        <span>Update</span>
                    </Button>
                </form>
            </div>
            {loading && <DisabledClickLoading />}
        </Dialog>
    )
});
export default EditEmailVertificationPopup
