// this file added by Bearer Web Developer
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog } from '@material-ui/core';
import styles from './createAccountPopup.module.scss';
import StepOne from './stepOne';
import { useAppState } from '../../states/app-state';
import StepTwo from './stepTwo';
import EndStep from './endStep';

interface Props {
    open: boolean
}

const CreateAccountPopup: React.FunctionComponent<Props> = observer(({ open }) => {
    const appState = useAppState();
    const { createAccount, accountVerification } = appState;
    const { step } = createAccount
    const handleClose = () => {
        if (step === 2) {
            if (createAccount?.complete_registeration) accountVerification?.setOpen(true);
            createAccount?.setOpen(false);
        } else {
            if (createAccount?.complete_registeration) return;
            createAccount?.setOpen(false);
        }
    }
    return (
        <Dialog open={open}
            PaperProps={{
                className: step === 2 ? styles.end_step_root : styles.root, style: { borderRadius: "10px" },
            }}
            classes={{ paperWidthSm: step === 2 ? styles.paper_width_sm : undefined }}
            onClose={handleClose}>
            <div className={styles.main_content}>
                {step === 0 ? <StepOne /> : step === 1 ? <StepTwo /> : <EndStep />}
            </div>
        </Dialog>
    );
});

export default CreateAccountPopup;
