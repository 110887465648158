/* this file added by Bearer Web Developer  */
import styles from './customCheckbox.module.scss';
interface Props {
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    label: string
    checked: boolean
}
const CustomCheckbox: React.FC<Props> = ({ label, checked, handleChange }) => {
   
    return (
        <div className={styles.custom_checkbox}>
            <input name={label} id={label} type="checkbox" checked={checked} onChange={handleChange} />
            <label htmlFor={label}>
                <div className={styles.checkbox}>{checked && <div className={styles.checked}></div>}</div>
                {label}
            </label>
        </div>
    )
}
export default CustomCheckbox;