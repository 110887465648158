// this file added by Bearer Web Developer
import { Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useAppState } from "../../../states/app-state";
import styles from './congratulations.module.scss';
import DisabledClickLoading from "../../../components/DisabledClickLoading";

const Congratulations: React.FC = observer(() => {
    const appState = useAppState();
    const [loading, setLoading] = useState(false);
    const { completeProfile, createAccount } = appState;
    const handleClose = async () => {
        setLoading(true);
        await appState?.login?.loadState(createAccount?.uid);
        // window.location.reload();
        setLoading(false);
        completeProfile?.setOpen(false);
    }
    return (
        <div className={styles.congratulations}>
            <header>
                <h5>Congratulations!</h5>
                <Button onClick={handleClose}><img src="/cancel.png" alt="X" width={28} height={28} /></Button>
            </header>
            <p>Your profile is now complete, and you can access all the app's features.</p>
            <Button type='button' className={styles.button} disableRipple={true} onClick={handleClose}>
                <span>OK</span>
            </Button>
            {loading && <DisabledClickLoading />}
        </div>
    )
})
export default Congratulations;