// this file added by Bearer Web Developer
import { Dialog } from "@material-ui/core"
import { observer } from "mobx-react-lite";
import styles from './accountVerificationPopup.module.scss';
import { useAppState } from "../../states/app-state";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";

interface Props {
    open: boolean
}
const AccountVerificationTwoStepPopup: React.FC<Props> = observer(({ open }) => {

    const appState = useAppState();
    const sign_up_stage = appState?.createAccount?.sign_up_stage;
    const handleClose = () => {
        if (sign_up_stage === 1 || sign_up_stage === 2) return;
    }
    return (
        <>
            <Dialog open={open} PaperProps={{ className: styles.root, style: { borderRadius: "10px" } }} onClose={handleClose}>
                {sign_up_stage === 1 ? <StepOne /> : <StepTwo />}
            </Dialog>
        </>
    )
});
export default AccountVerificationTwoStepPopup;
