// this file added by Bearer Web Developer
import { flow, getEnv, getParentOfType, Instance, types } from "mobx-state-tree";
import { AppState, EnvType, IAppState, ILoginState } from "../app-state";
import { error, log } from "../../app/log";
import { TRegisterBusinessCustomerStepOneResponse } from "./models/registerBusinessCustomerStepOne";
import registerBusinessCustomerStepOne from "./registerBusinessCustomerStepOne";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { IAccountFormState } from "../accountFormState";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { firebaseApp } from "../../api/bearer-api";
import { ICreateAccountState } from "../createAccountState";

type TitleMethod = "Social Media Ads" | "Phone Calls" | "Shopify App Store" | "Other" |
    "Email & Newsletters" | "Search Engine" | "Referral Code" | "Flyers (Public Campaigns)"
export interface InvateMethod {
    title: TitleMethod
    src: string
    id: number
}
export const AccountFormConfirmState = types
    .model("AccountFormConfirmState", {
        inviteCode: types.optional(types.string, ""),
        invateMethod: types.maybeNull(types.frozen<InvateMethod>()),
    })
    .actions(self => {
        return {
            getFormData() {
                return {
                    invateMethod: self.invateMethod,
                    inviteCode: self.inviteCode,
                }
            },
            clear() {
                self.inviteCode = '';
                self.invateMethod = null;
            },
            setActiveData(value: InvateMethod | null) {
                self.invateMethod = value;
            },
            setInviteCode(value: string) {
                self.inviteCode = value;
            },

        }
    }).actions(self => {
        return {
            createUserRequestFirebase: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const response = yield fetch('https://jsonip.com');
                    const Ipdata = yield response.json();
                    const ip = Ipdata?.ip || "";
                    log({ ip });
                    if (ip) {
                        const auth = getAuth();
                        const accountForm: IAccountFormState = getParentOfType(self, AppState)?.accountForm;
                        const { email, password } = accountForm;
                        const userCredential = yield createUserWithEmailAndPassword(auth, email, password);
                        // Signed up 
                        const user = userCredential?.user;
                        if (user) {
                            const appState = getParentOfType(self, AppState);
                            appState?.createAccount?.setUid(user?.uid);
                            appState?.login?.setUid(user?.uid);
                            return ip;
                        };
                    } else {
                        log({ ipResponse: Ipdata });
                    }
                } catch (err: any) {
                    error(err);
                    const appState = getParentOfType(self, AppState);
                    const errorMessage = err?.message === "Firebase: Error (auth/email-already-in-use)." ?
                        `${appState?.accountForm?.email} is already in use. Please update your email address.`
                        : (err?.message || 'unknown error!');
                    appState.setSnackMessage({
                        message: errorMessage,
                    });
                }
            }),
            fetchRegisterBusinessCustomerStepOne: flow(function* (ip) {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    // get ip start
                    // const response = yield fetch('https://jsonip.com');
                    // const Ipdata = yield response.json();
                    // const ip = Ipdata?.ip || "";
                    // get ip end
                    // get fcm_token start
                    const supported = yield isSupported();
                    log({ supported });
                    let fcm_token = '';
                    if (supported) {
                        const messaging = getMessaging(firebaseApp);
                        const token = yield getToken(messaging, { vapidKey: process.env.REACT_APP_VapIdKey });
                        fcm_token = token || '';
                    }
                    // get fcm_token end
                    const appState: IAppState = getParentOfType(self, AppState);
                    const accountForm: IAccountFormState = appState?.accountForm;
                    const accountFormConfirm = appState?.accountFormConfirm;
                    const accountVerification = appState?.accountVerification;
                    const createAccount: ICreateAccountState = appState?.createAccount;
                    const login: ILoginState = appState?.login;
                    const referred_by =
                        (accountFormConfirm?.invateMethod?.title === "Referral Code" ?
                            accountFormConfirm?.inviteCode : accountFormConfirm?.invateMethod?.title) as string;
                    const data = {
                        email_address: accountForm?.email,
                        mobile_number: accountForm?.mobileNumber,
                        abn: accountForm?.businessNumber,
                        fcm_token: fcm_token,
                        latest_tc_pp_vers_signed: api.bearerWebAppRemoteConfigParameters()?.version || '',
                        latest_tc_pp_vers_sign_ip: ip,
                        referred_by: referred_by || ''
                    }
                    log('registerStepOneData', data);
                    const result: TRegisterBusinessCustomerStepOneResponse = yield registerBusinessCustomerStepOne(data);
                    if (result.code === 200) {
                        login?.setPassword(accountForm?.password);
                        createAccount.setSignUpStage(result?.sign_up_stage);
                        accountVerification?.setEmail(accountForm?.email);
                        accountVerification?.setMobileNumber(accountForm?.mobileNumber);
                        yield login?.loadState(createAccount?.uid, true);
                        return result;
                    } else if (result.code === 499 || result.code === 401 || result.code === 428 || result.code === 429) {
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result.message || 'unknown error!',
                            handleClick: ()=>{
                                appState.login.logout();
                            }
                        });

                    } else if (result.code === 500) {
                        log(result);
                    }

                } catch (err: any) {
                    error(err);
                }
            })
        }
    })
export interface IAccountFormConfirmState extends Instance<typeof AccountFormConfirmState> { };

