// this file added by Bearer Web Developer
import { observer } from "mobx-react-lite";
import React from "react";
import { useAppState } from "../../../../../../states/app-state";
import Send from "./send";
import { Button } from "@material-ui/core";
import Second from "./second";
import Edit from "./edit";
import styles from './buttons.module.scss';

interface Props {
    getRegisterMobileCode: () => Promise<void>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}
const Buttons: React.FC<Props> = observer(({ getRegisterMobileCode, setLoading }) => {

    const appState = useAppState();
    const { accountVerification } = appState;
    const verifying = accountVerification?.mobileCodeSituation === "Verifying";
    const edit = accountVerification?.mobileCodeSituation === "Edit";
    const verified = accountVerification?.mobileCodeSituation === "Verified";
    return (
        <>
            {verified &&
                <div className={styles.verified_part}>
                    <div className={styles.verified}>Verified</div>
                </div>
            }
            {
                !verified
                &&
                <>
                    {<Send setLoading={setLoading} />}
                    <div className={`${styles.restart_part}`} >
                        <Button className={`${styles.restart}`} disabled={verifying}
                            onClick={getRegisterMobileCode}
                        >
                            <img src={`/restart-${verifying ? 'lightgrey' : 'white'}.webp`}
                                alt="restart" width={28} height={28} />
                        </Button>
                    </div>
                    {verifying && <Second />}
                    {edit && <Edit />}
                </>
            }
        </>
    )
})
export default Buttons;