import React from 'react';
import BearerTextField from '../../../components/fields/BearerTextField';
import { Button } from '@material-ui/core';
import RightRadioButton from '../../../components/rightradiobutton/RightRadioButton';
import { ApprovalMethodEnum, IDestinationState, IParcelState, useAppState } from '../../../states/app-state';
import { Observer } from 'mobx-react-lite';
import AddressField from '../../../components/fields/AddressField';
import FavouritePlacesList from '../../favouritePlaces/FavouritePlacesList';
import LoginPopup from '../../login/LoginPopup';
import { analytics, TFavouritePlacesInfo } from '../../../api/bearer-api';
import styles from './DestinationEditor.module.scss'; // added by Bearer Web Developer
import { logEvent } from 'firebase/analytics'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer


const DestinationEditor: React.FC<{ destination: IDestinationState, parcel: IParcelState }> = ({ destination, parcel }) => {

  const appState = useAppState();
  // move validatePhone to DestinationEditor by Bearer Web Developer start
  function validatePhone(value: string) {
    if (destination.approvalMethods === ApprovalMethodEnum.Code) {
      if (!(value.length === 10)) return "10 Digits (Beginning with a 04)";
      if (!(value.slice(0, 2) === "04")) return "10 Digits (Beginning with a 04)";
      if (!value.match(/^[0-9]*$/)) return "10 Digits (Beginning with a 04)";
    } else {
      if (!(value.length === 10)) return "10 Digits (Beginning with a 0)";
      if (!(value.slice(0, 1) === "0")) return "10 Digits (Beginning with a 0)";
      if (!value.match(/^[0-9]*$/)) return "10 Digits (Beginning with a 0)";
    }
  }
  // move validatePhone to DestinationEditor by Bearer Web Developer end
  const handleConfirm = () => {
    // validatePhone and validateName in the following added by Bearer Web Developer
    const valid = !!destination.address && !!(destination.phone.length === 10) && !!(destination.name.length >= 2) && !!destination.approvalMethods && !validatePhone(destination.phone) && !validateName(destination.name);
    if (valid) {
      // added by Bearer Web Developer start
      // Firebase Analytics Event Name Destination Add. Confirmed
      // App Event Destination Address Conformed by User
      logEvent(analytics, "destination_address_confirmed");
      // added by Bearer Web Developer end
      // added by Bearer Web Developer start
      // Firebase Analytics Event Name Parcel Security (Final)
      // App Event Destination Details + Destination Verification Method Chosen
      logEvent(analytics, "selected_drop_off_verification_method", {
        dropoff_verification_method: appState?.selectedOrder?.destination?.approvalMethods
      });
      // added by Bearer Web Developer end
      destination.setEditing(false);
      parcel.setEditing(true);
    }
  }




  const [logInPopupOpen, setLogInPopupOpen] = React.useState(false);
  // const handleLoginPopup = () => {
  //     setLogInPopupOpen(true);
  // }
  const handleLoginInPopupClose = () => {
    setLogInPopupOpen(false);
  }
  const [favouritePlacesListOpen, setFavouritePlacesListOpen] = React.useState(false);
  const handleFavouritePlacesList = () => {
    if (!appState.login.loggedin) {
      setLogInPopupOpen(true)
    } else {
      setFavouritePlacesListOpen(true);
    }
  }
  const handleFavouritePlacesListClose = () => {
    setFavouritePlacesListOpen(false);
  }
  const handleFavouriteSelected = (selected: TFavouritePlacesInfo) => {
    destination.setAddress({
      description: selected.address,
      location: {
        lat: selected.location_lat, lng: selected.location_lng
      }
    });
    destination.setMoreDetails(selected.details);
    // added by Bearer Web Developer start
    // Firebase Analytics Event Name Favourite Place Used
    // App Event Origin || Destination Address chosen from Favourite Places
    logEvent(analytics, "favourite_place_used");
    // added by Bearer Web Developer end
    setFavouritePlacesListOpen(false);
  }

  // modified by Bearer Web Developer start
  return (
    <>
      <LoginPopup open={logInPopupOpen} handleClose={handleLoginInPopupClose} />
      <FavouritePlacesList onSelect={handleFavouriteSelected} open={favouritePlacesListOpen} handleClose={handleFavouritePlacesListClose} />
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.destination}>
            <img src="/destination.png" alt="destination" />
            <h2>Destination</h2>
          </div>
          <Button variant="text" disableRipple={true} className={styles.clear} onClick={() => destination.clear()}>Clear Fields</Button>
        </div>
        <div className={styles.destination_address_field}>
          <Observer>{() => (
            <AddressField
              handleFavouritePlaces={handleFavouritePlacesList} // added by Bearer Web Developer
              value={destination.address || null}
              onChange={(value) => destination.setAddress(value)}
              name={"destination"}
            />
          )}</Observer>
        </div>
        <div className={styles.more_details_input}>
          <Observer>{() => (
            <BearerTextField
              name='More Details'
              placeholder={"e.g., Level 5 | Shop # G8 | The Glass Door"}
              label="More Details/ Message for the Rider"
              value={destination.moreDetails}
              onChange={(e) => destination.setMoreDetails(e.target.value)}
              clear={() => { destination.setMoreDetails('') }} // added by Bearer Web Developer
            />
          )}</Observer>
        </div>
        <div className={styles.delivery}>
          <p>Need to verify the recipient at the destination with a security code?</p>
        </div>
        <div>
          <Observer>{() => (
            <RightRadioButton
              label="Yes, secure with a code"
              value={ApprovalMethodEnum.Code}
              checked={destination.approvalMethods === ApprovalMethodEnum.Code}
              onClick={() => {
                destination.setApprovalMethods(ApprovalMethodEnum.Code);
                destination.setPhone("04");
              }}
            />
          )}</Observer>
        </div>
        <div>
          <Observer>{() => (
            <RightRadioButton
              label="No, verification not required"
              value={ApprovalMethodEnum.None}
              checked={destination.approvalMethods === ApprovalMethodEnum.None}
              onClick={() => {
                destination.setApprovalMethods(ApprovalMethodEnum.None);
                destination.setPhone("0");
              }}
            />
          )}</Observer>
        </div>
        <div className={styles.extra_inputs}>
          <div className={styles.phone_number}>
            <Observer>{() => (
              <BearerTextField
                label="Phone Number"
                placeholder={"e.g., 0300 000 000"}
                value={destination.phone}
                inputProps={{ maxLength: "10" }} // add maxlength of Phone Number of destination by Bearer Web Developer
                onChange={(e) => destination.setPhone(e.target.value)}
                clear={() => { destination.setPhone('') }} // added by Bearer Web Developer
                validator={validatePhone}
              />
            )}</Observer>
          </div>
          <div className={styles.recipient_name}>
            <Observer>{() => (
              <BearerTextField
                label="Recipient Name"
                placeholder={"e.g., John Citizen"}
                value={destination.name}
                onChange={(e) => destination.setName(e.target.value)}
                clear={() => { destination.setName('') }} // added by Bearer Web Developer
                validator={validateName}
              />
            )}</Observer>
          </div>
        </div>
        <div className={styles.buttons}>
          <Observer>{() => {
            // validatePhone and validateName in the following added by Bearer Web Developer
            const valid = !!destination.address && !!(destination.phone.length === 10) && !!(destination.name.length >= 2) && !!destination.approvalMethods && !validatePhone(destination.phone) && !validateName(destination.name);
            return (
              <Button disabled={!valid} className={styles.confirm_destination} onClick={handleConfirm}>Confirm Destination</Button>
            );
          }}</Observer>
        </div>
      </div>
    </>
  );
  // modified by Bearer Web Developer end
}
export default DestinationEditor;

// move validatePhone to DestinationEditor by Bearer Web Developer


function validateName(value: string) {
  if (!(value.length >= 2)) return "Minimum 2 letters (English Only)";
  if (!(value.match(/^[a-zA-Z\s]*$/))) return "Minimum 2 letters (English Only)";
}