// this file added by Bearer Web Developer
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@material-ui/core';
import styles from './stepTwo.module.scss';
import { useAppState } from '../../../states/app-state';
import ProfileForm from './profileForm';
import Congratulations from './congratulations';

const StepTwo: React.FunctionComponent = observer(() => {

    const appState = useAppState();
    const { accountVerification } = appState;
    const sign_up_stage = appState?.createAccount?.sign_up_stage;
    const handleClose = () => {
        if (sign_up_stage === 3) {
            window.location.reload();
            accountVerification?.setOpenTwoStep(false);
        } else if (sign_up_stage === 2) return;
    }
    return (
        <>
            {
                sign_up_stage === 3 ? <Congratulations /> :
                    <div className={styles.main}>
                        <div className={styles.header}>
                            <h5>Account Verification</h5>
                            <div className={styles.step_content}>
                                <div className={styles.step_number}><span>2</span>/2</div>
                                <Button onClick={handleClose}><img src="/back.png" alt="back" /></Button>
                            </div>
                        </div>
                        <p>Complete your profile to unlock all the exciting features of Bearer!</p>
                        <ProfileForm />
                        <div className={styles.step_show}><div className={styles.fill}></div></div>
                    </div>
            }
        </>
    );
});
export default StepTwo;
