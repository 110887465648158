import React from 'react';
import BearerTextField from '../../../components/fields/BearerTextField';
import AddressField from '../../../components/fields/AddressField';
import { Button } from '@material-ui/core';
import { IDestinationState, IOriginState, useAppState } from '../../../states/app-state';
import { Observer } from 'mobx-react-lite';
import LoginPopup from '../../login/LoginPopup';
import FavouritePlacesList from '../../favouritePlaces/FavouritePlacesList';
import { analytics, TFavouritePlacesInfo } from '../../../api/bearer-api';
import styles from './OriginEditor.module.scss'; // added by Bearer Web Developer
import { logEvent } from 'firebase/analytics'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const OriginEditor: React.FC<{ origin: IOriginState, destination: IDestinationState }> = ({ origin, destination }) => {

  const appState = useAppState();
  const handleConfirm = () => {
    // validatePhone and validateName in the following added by Bearer Web Developer
    const valid = !!origin.address && !!(origin.phone.length === 10) && !!origin.name && !validatePhone(origin.phone) && !validateName(origin.name);
    if (valid) {
      // added by Bearer Web Developer start
      // Firebase Analytics Event Name Origin Add. Confirmed
      // App Event Origin Address Confirmed by User
      logEvent(analytics, "origin_address_confirmed");
      // added by Bearer Web Developer end
      origin.setEditing(false);
      destination.setEditing(true);
    }
  }

  const [logInPopupOpen, setLogInPopupOpen] = React.useState(false);
  // const handleLoginPopup = () => {
  //     setLogInPopupOpen(true);
  // }
  const handleLoginInPopupClose = () => {
    setLogInPopupOpen(false);
  }
  const [favouritePlacesListOpen, setFavouritePlacesListOpen] = React.useState(false);
  const handleFavouritePlacesList = () => {
    if (!appState.login.loggedin) {
      setLogInPopupOpen(true)
    } else {
      setFavouritePlacesListOpen(true);
    }
  }
  const handleFavouritePlacesListClose = () => {
    setFavouritePlacesListOpen(false);
  }

  const handleFavouriteSelected = (selected: TFavouritePlacesInfo) => {
    origin.setAddress({
      description: selected.address,
      location: {
        lat: selected.location_lat, lng: selected.location_lng
      }
    });
    origin.setMoreDetails(selected.details);
    // added by Bearer Web Developer start
    // Firebase Analytics Event Name Favourite Place Used
    // App Event Origin || Destination Address chosen from Favourite Places
    logEvent(analytics, "favourite_place_used");
    // added by Bearer Web Developer end
    setFavouritePlacesListOpen(false);
  }
  // modified by Bearer Web Developer start
  return (
    <>
      <LoginPopup open={logInPopupOpen} handleClose={handleLoginInPopupClose} />
      <FavouritePlacesList onSelect={handleFavouriteSelected} open={favouritePlacesListOpen} handleClose={handleFavouritePlacesListClose} />
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.origin}>
            <img src="/origin.png" alt="origin" />
            <h2>Origin</h2>
          </div>
          <Button variant="text" disableRipple={true} className={styles.clear} onClick={() => origin.clear()}>Clear Fields</Button>
        </div>
        <div className={styles.more_details_input}>
          <Observer>{() => (
            <AddressField
              handleFavouritePlaces={handleFavouritePlacesList} // added by Bearer Web Developer
              value={origin.address || null}
              onChange={(value) => origin.setAddress(value)}
              name={"origin"}
            />
          )}</Observer>
        </div>
        <div className={styles.more_details_input}>
          <Observer>{() => (
            <BearerTextField
              placeholder={"e.g., Level 5 | Shop # G8 | The Glass Door"}
              label="More Details / Message for the Rider"
              value={origin.moreDetails}
              onChange={(e) => origin.setMoreDetails(e.target.value)}
              clear={() => { origin.setMoreDetails('') }} // added by Bearer Web Developer
            />
          )}</Observer>
        </div>
        <div className={styles.extra_inputs}>
          <div className={styles.phone_number}>
            <Observer>{() => (
              <>
                <BearerTextField
                  placeholder={"e.g., 0400 000 000"}
                  label="Phone Number"
                  value={origin.phone}
                  inputProps={{ maxLength: "10" }} // add maxlength of Phone Number of origin by Bearer Web Developer
                  onChange={(e) => origin.setPhone(e.target.value)}
                  clear={() => { origin.setPhone('') }} // added by Bearer Web Developer
                  validator={validatePhone}
                />
              </>
            )}</Observer>

          </div>
          <div className={styles.sender_name}>
            <Observer>{() => (
              <BearerTextField
                placeholder={"e.g., Jane Citizen"}
                label="Sender’s Name"
                value={origin.name}
                onChange={(e) => origin.setName(e.target.value)}
                clear={() => { origin.setName('') }} // added by Bearer Web Developer
                validator={validateName}
              />
            )}</Observer>
          </div>
        </div>
        <div className={styles.buttons}>
          <Observer>{() => {
            // validatePhone and validateName in the following added by Bearer Web Developer
            const valid = !!origin.address && !!(origin.phone.length === 10) && !!origin.name && !validatePhone(origin.phone) && !validateName(origin.name);
            return (
              <Button disabled={!valid} className={styles.confirm_origin} onClick={handleConfirm}>Confirm Origin</Button>
            );
          }}</Observer>
        </div>
      </div>
    </>
  );
  // modified by Bearer Web Developer end
}

export default OriginEditor;


function validatePhone(value: string) {
  if (!(value.length === 10)) return "10 Digits (Beginning with a 0)";
  if (!(value.slice(0, 1) === "0")) return "10 Digits (Beginning with a 0)";
  if (!value.match(/^[0-9]*$/)) return "10 Digits (Beginning with a 0)";
}

function validateName(value: string) {
  if (!(value.length >= 2)) return "Minimum 2 letters (English Only)";
  if (!(value.match(/^[a-zA-Z\s]*$/))) return "Minimum 2 letters (English Only)";
}