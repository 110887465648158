// this file added by Bearer Web Developer
import { flow, getEnv, Instance, types } from "mobx-state-tree";
import { EnvType } from "../app-state";
import checkCustomerABN from "./checkCustomerABN";
import { TCheckCustomerABNResponse } from "./models/checkCustomerABN";
import { error, log } from "../../app/log";

export const AccountFormState = types
    .model("AccountFormState", {
        email: types.optional(types.string, ""),
        mobileNumber: types.optional(types.string, "+614"),
        password: types.optional(types.string, ""),
        repeatPassword: types.optional(types.string, ""),
        bearerPrivacyPolicy: types.optional(types.boolean, false),
        bearerBusinessCustomerAgreement: types.optional(types.boolean, false),
        businessNumber: types.optional(types.string, ""),
        legal_name: types.optional(types.string, ""),
    })
    .actions(self => {
        return {
            getFormData() {
                return {
                    email: self.email,
                    mobileNumber: self.mobileNumber,
                    password: self.password,
                    businessNumber: self.businessNumber,
                    bearerPrivacyPolicy: self.bearerPrivacyPolicy,
                    bearerBusinessCustomerAgreement: self.bearerBusinessCustomerAgreement,
                }
            },
            clear() {
                self.email = '';
                self.mobileNumber = '';
                self.password = '';
                self.repeatPassword = '';
                self.businessNumber = '';
                self.bearerPrivacyPolicy = false;
                self.bearerBusinessCustomerAgreement = false;
                self.legal_name = '';
            },
            setEmail(value: string) {
                self.email = value;
            },
            setMobileNumber(value: string) {
                self.mobileNumber = value;
            },
            setPassword(value: string) {
                self.password = value;
            },
            setRepeatPassword(value: string) {
                self.repeatPassword = value;
            },
            setBusinessNumber(value: string) {
                self.businessNumber = value;
            },
            setPrivacyPolicy(value: boolean) {
                self.bearerPrivacyPolicy = value;
            },
            setBusinessCustomer(value: boolean) {
                self.bearerBusinessCustomerAgreement = value;
            },
            setLegalName(value: string) {
                self.legal_name = value;
            },
        }
    }).actions(self => {
        return {
            fetchCheckCustomerABN: flow(function* (str) {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result: TCheckCustomerABNResponse = yield checkCustomerABN(str);
                    if (result?.code === 200) {
                        self.legal_name = result?.legal_name;
                        return undefined;
                    } else if (result?.code === 428) {
                        return result?.message;
                    } else if (result?.code === 500) {
                        log(result);
                    }
                } catch (err: any) {
                    error(err);
                }
            })
        }
    })
export interface IAccountFormState extends Instance<typeof AccountFormState> { };

