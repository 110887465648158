// this file added by Bearer Web Developer
export const validateABN = (abn: string) => {
    // Check if the ABN is a string of 11 digits
    if (!/^\d{11}$/.test(abn)) {
        return "An ABN should include 11 numeric digits";
    }

    const errorText = 'Please provide a valid format for the "ABN" field';
    // Convert the ABN to an array of numbers
    const abnArray = abn.split('').map(Number);
    // Apply the ABN validation algorithm
    const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
    let sum = 0;
    for (let i = 0; i < 11; i++) {
        let digit = abnArray[i];
        // Subtract 1 from the first digit
        if (i === 0) {
            digit -= 1;
        }
        sum += digit * weights[i];
    }
    return sum % 89 === 0 ? '' : errorText;
}
