// this file added by Bearer Web Developer
import { Button } from "@material-ui/core";
import React from "react";
import styles from './FreeDelivery.module.scss';
import { useAppState } from "../../../states/app-state";

const FreeDelivery: React.FC = () => {

    const appState = useAppState();
    const { accountVerification, createAccount, completeProfile } = appState;
    const handleClose = () => {
        if (createAccount?.complete_registeration) completeProfile?.setOpen(true);
        accountVerification?.setOpen(false);
    }
    return (
        <div className={styles.free_delivery}>
            <div className={styles.header}>
                <h5>Enjoy a FREE Delivery!</h5>
                <div><Button onClick={handleClose}><img src="/cancel.png" alt="X" /></Button></div>
            </div>
            <p>
                A <span>FREE</span> delivery has been automatically applied to your account. Just place an order and save instantly!
            </p>
            <div className={styles.cover}>
                <img src="/free-order-cover.webp" alt="" width={254} />
            </div>
            <div className={''}>
                <Button className={styles.submit} onClick={handleClose}>OK</Button>
            </div>
        </div>
    )
}
export default FreeDelivery;