// this file added by Bearer Web Developer
import { flow, getEnv, getParentOfType, Instance, types } from "mobx-state-tree";
import { AppState, EnvType, IProfileState } from "../app-state";
import { error, log } from "../../app/log";
import registerBusinessCustomerConfirmEmailCode from "./registerBusinessCustomerConfirmEmailCode";
import registerBusinessCustomerConfirmSMSCode from "./registerBusinessCustomerConfirmSMSCode";
import registerBusinessCustomerSubmitVerification from "./registerBusinessCustomerSubmitVerification";
import { TRegisterBusinessCustomerSubmitVerificationResponse } from "./models/registerBusinessCustomerSubmitVerification";
import { TRegisterBusinessCustomerSendEmailCodeResponse } from "./models/registerBusinessCustomerSendEmailCode";
import registerBusinessCustomerSendEmailCode from "./registerBusinessCustomerSendEmailCode";
import { TRegisterBusinessCustomerConfirmEmailCodeResponse } from "./models/registerBusinessCustomerConfirmEmailCode";
import { TRegisterBusinessCustomerSendSMSCodeResponse } from "./models/registerBusinessCustomerSendSMSCode";
import registerBusinessCustomerSendSMSCode from "./registerBusinessCustomerSendSMSCode";
import { TRegisterBusinessCustomerConfirmSMSCodeResponse } from "./models/registerBusinessCustomerConfirmSMSCode";
import klaviyo from "../klaviyo";
import { logEvent } from "firebase/analytics";
import { analytics, getRemoteConfigData } from "../../api/bearer-api";
import ReactPixel from 'react-facebook-pixel'; // added by Bearer Web Developer
import { IAccountFormState } from "../accountFormState";
import { ICreateAccountState } from "../createAccountState";

type TCodeVerificationSituation = "None" | "Verifying" | "Edit" | "Verified";

export const AccountVerificationState = types
    .model("AccountVerificationState", {
        open: types.optional(types.boolean, false),
        openTwoStep: types.optional(types.boolean, false),
        email: types.optional(types.string, ''),
        mobileNumber: types.optional(types.string, ''),
        emailCode: types.optional(types.string, ''),
        emailCodeSituation: types.optional(types.frozen<TCodeVerificationSituation>(), 'None'),
        mobileCodeSituation: types.optional(types.frozen<TCodeVerificationSituation>(), 'None'),
        mobileCode: types.optional(types.string, ''),
        asyncErrorEmail: types.optional(types.string, ''),
        asyncErrorMobile: types.optional(types.string, ''),
    })
    .actions(self => {
        return {
            getCodeData() {
                return {
                    emailCode: self.emailCode,
                    mobileCode: self.mobileCode,
                }
            },
            clear() {
                self.open = false;
                self.email = '';
                self.mobileNumber = '';
                self.emailCode = '';
                self.emailCodeSituation = 'None';
                self.mobileCodeSituation = 'None';
                self.mobileCode = '';
                self.asyncErrorEmail = '';
                self.asyncErrorMobile = '';
            },
            setOpen(value: boolean) {
                self.open = value;
            },
            setOpenTwoStep(value: boolean) {
                self.openTwoStep = value;
            },
            setEmail(value: string) {
                self.email = value;
            },
            setMobileNumber(value: string) {
                self.mobileNumber = value;
            },
            setEmailCode(value: string) {
                self.emailCode = value;
            },
            setMobileCode(value: string) {
                self.mobileCode = value;
            },
            setEmailCodeSituation(value: TCodeVerificationSituation) {
                self.emailCodeSituation = value;
            },
            setMobileCodeSituation(value: TCodeVerificationSituation) {
                self.mobileCodeSituation = value;
            },
            setAsyncErrorEmail(value: string) {
                self.asyncErrorEmail = value;
            },
            setAsyncErrorMobile(value: string) {
                self.asyncErrorMobile = value;
            },
        }
    }).actions(self => {
        return {
            fetchRegisterBusinessCustomerSendEmailCode: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result: TRegisterBusinessCustomerSendEmailCodeResponse = yield registerBusinessCustomerSendEmailCode();
                    if (result?.code === 401 || result?.code === 499) {
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result.message || 'unknown error!',
                        });
                    } else if (result?.code === 200) {
                        return result;
                    } else if (result.code === 500) {
                        log(result);
                    }
                } catch (err: any) {
                    error(err);
                }
            }),
            fetchRegisterBusinessCustomerConfirmEmailCode: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result: TRegisterBusinessCustomerConfirmEmailCodeResponse = yield registerBusinessCustomerConfirmEmailCode(self.emailCode);
                    if (result?.code === 400) {
                        self?.setAsyncErrorEmail(result?.message);
                    } else if (result?.code === 401 || result?.code === 499 || result?.code === 423) {
                        const appState = getParentOfType(self, AppState);
                        if (result?.code === 423) {
                            appState.setSnackMessage({
                                message: result.message || 'unknown error!',
                                handleClick: async () => {
                                    const resultSend = await appState?.accountVerification?.fetchRegisterBusinessCustomerSendEmailCode();
                                    if (resultSend?.code === 200) {
                                        appState?.accountVerification?.setEmailCodeSituation("Verifying");
                                    }
                                },
                                hasLoadingForHandleClick: true
                            });
                        } else {
                            appState.setSnackMessage({
                                message: result.message || 'unknown error!',
                            });
                        }
                    } else if (result?.code === 200) {
                        return result;
                    } else if (result.code === 500) {
                        log(result);
                    }
                } catch (err: any) {
                    error(err);
                }
            }),
            fetchRegisterBusinessCustomerSendSMSCode: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result: TRegisterBusinessCustomerSendSMSCodeResponse = yield registerBusinessCustomerSendSMSCode();
                    if (result?.code === 200) {
                        return result;
                    } else if (result?.code === 401 || result?.code === 499) {
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result.message || 'unknown error!',
                        });

                    } else if (result.code === 500) {
                        log(result);
                    }
                } catch (err: any) {
                    error(err);
                }
            }),
            fetchRegisterBusinessCustomerConfirmSMSCode: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result: TRegisterBusinessCustomerConfirmSMSCodeResponse = yield registerBusinessCustomerConfirmSMSCode(self.mobileCode);
                    if (result?.code === 400) {
                        self?.setAsyncErrorMobile(result?.message);
                    } else if (result?.code === 401 || result?.code === 499 || result?.code === 423) {
                        const appState = getParentOfType(self, AppState);
                        if (result?.code === 423) {
                            appState.setSnackMessage({
                                message: result.message || 'unknown error!',
                                handleClick: async () => {
                                    const resultSend = await appState?.accountVerification?.fetchRegisterBusinessCustomerSendSMSCode();
                                    if (resultSend?.code === 200) {
                                        appState?.accountVerification?.setMobileCodeSituation("Verifying");
                                    }
                                },
                                hasLoadingForHandleClick: true
                            });
                        } else {
                            appState.setSnackMessage({
                                message: result.message || 'unknown error!',
                            });
                        }
                    } else if (result?.code === 200) {
                        return result;
                    } else if (result.code === 500) {
                        log(result);
                    }
                } catch (err: any) {
                    error(err);
                }
            }),
            fetchRegisterBusinessCustomerSubmitVerification: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result: TRegisterBusinessCustomerSubmitVerificationResponse = yield registerBusinessCustomerSubmitVerification();
                    if (result?.code === 401 || result?.code === 499) {
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result.message || 'unknown error!',
                        });
                    } else if (result?.code === 200) {
                        const appState = getParentOfType(self, AppState);
                        appState?.createAccount?.setSignUpStage(result?.sign_up_stage);
                        appState.fullDiscountAutomatically?.setFullDiscount(result?.is_full_discount);
                        appState.fullDiscountAutomatically?.setCouponId(result?.coupon_id);
                        appState.selectedOrder?.discount.setFullDiscountUser(true);
                        appState.selectedOrder?.discount?.setDiscountValue({
                            coupon_id: result?.coupon_id, transport_receivable_price: 0
                        });
                        appState?.profile?.setEmail(self?.email);
                        appState?.profile?.setPhoneNumber("0" + self?.mobileNumber.substring(3, self?.mobileNumber.length));
                        // Firebase Analytics (Google Analytics) Email Verified start
                        console.log("new_user_email_verified Firebase Analytics (Google Analytics) Email Verified")
                        logEvent(analytics, "new_user_email_verified", { user_type: "Business" });
                        // Firebase Analytics (Google Analytics) Email Verified end
                        // klaviyo profile RegistrationStep2 start
                        const profile: IProfileState = appState?.profile;
                        const createAccount: ICreateAccountState = appState?.createAccount;
                        const accountForm: IAccountFormState = appState?.accountForm;
                        yield klaviyo.createOrUpdateProfile({
                            type: 'profile',
                            attributes: {
                                email: self?.email,
                                properties: {
                                    phone_number: self?.mobileNumber, // start with +61
                                    organization: accountForm?.legal_name,
                                    referred_by: createAccount?.referred_by,
                                    sign_up_date: createAccount?.sign_up_date
                                }
                            },
                        });
                        // klaviyo profile RegistrationStep2 end
                        // klaviyo SignUpContinued start
                        yield klaviyo.createEvent({ event: "SignUpContinued", email: profile?.email });
                        // klaviyo SignUpContinued end
                        // Firebase Analytics (Google Analytics) SMS Verified start
                        console.log("new_user_phone_verified Firebase Analytics (Google Analytics) SMS Verified")
                        logEvent(analytics, "new_user_phone_verified", { user_type: "Business" });
                        // Firebase Analytics (Google Analytics) SMS Verified end
                        // RegistrationStep2 (Custom Event) Facebook – Pixel – Meta start
                        const { environmentCode } = yield getRemoteConfigData(); // added by Bearer Web Developer
                        if (environmentCode === "PRODUCTION") {
                            ReactPixel.trackSingleCustom('3587653761499122', 'RegistrationStep2'); // For tracking custom events.
                        }
                        // RegistrationStep2 (Custom Event) Facebook – Pixel – Meta end
                        return result;
                    } else if (result.code === 500) {
                        log(result);
                    }
                } catch (err: any) {
                    error(err);
                }
            })
        }
    })
export interface IAccountVerificationState extends Instance<typeof AccountVerificationState> { };

