import React from 'react';
import DestinationHandler from '../destination/DestinationHandler';
import ParcelHandler from '../parcels/ParcelHandler';
import TransporstHandler from '../transport/TransportsHandler';
import { useAppState } from '../../states/app-state';
import { observer } from 'mobx-react-lite';
import PriceHandler from '../price/PriceHandler';
import { Button } from '@material-ui/core';
import PaymentPopup from '../price/PaymentPopup';
import OriginHandler from '../origin/OriginHandler';
import styles from './OrderForm.module.scss'; // added by Bearer Web Developer
import DisabledClickLoading from '../../components/DisabledClickLoading'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const OrderForm: React.FC = observer(() => {

    const appState = useAppState();
    const order = appState.selectedOrder;
    const riderInfo = order?.transport.riderInfo;
    const fullDiscountUser = order?.discount?.fullDiscountUser; // added by Bearer Web Developer
    const sign_up_stage = appState?.createAccount?.sign_up_stage; // added by Bearer Web Developer
    const [loading, setLoading] = React.useState(false); // added by Bearer Web Developer
    // const realPrice = order?.selectedTransportOption?.price; // commented by Bearer Web Developer
    // const discountedPrice = order?.discount.value?.transport_receivable_price; // commented by Bearer Web Developer
    // modified by Bearer Web Developer (add fullDiscountUser and sign_up_stage condition) start 
    const handleConfirm = async () => {
        if (!appState.login.loggedin && sign_up_stage === 0) {
            return
        } else if (fullDiscountUser) {
            // Place Your FREE Order
            if (sign_up_stage === 1) appState?.accountVerification.setOpen(true);
            else {
                setLoading(true);
                await appState.selectedOrder?.payment.paymentAuthorization();
                setLoading(false);
            }
        } else {
            appState.selectedOrder?.payment.setOpen(order!);
        }
    }
    // modified by Bearer Web Developer (add fullDiscountUser and sign_up_stage condition) end

    if (!order) {
        return <></>
    }
    // modified by Bearer Web Developer start
    return (
        <form className={styles.root}>
            <PaymentPopup />
            <OriginHandler origin={order.origin} destination={order.destination} />
            <DestinationHandler destination={order.destination} parcel={order.parcelType} />
            <ParcelHandler parcel={order.parcelType} transport={order.transport} />
            <TransporstHandler transport={order.transport} selectedOrder={order} />
            <PriceHandler selectedOrder={order} />
            <div className={styles.confirm_part}>
                {riderInfo && <Button
                    className={styles?.['confirm_undiscounted']}
                    onClick={handleConfirm}
                    disabled
                // disabled={!order.isReadyForPayment}
                >
                    {/* modified by Bearer Web Developer start  */}
                    {/* commented by Bearer Web Developer start  */}
                    {/* {discountedPrice ?
                        <>
                            <div className={styles.real_price}>{realPrice}</div>
                            <div className={styles.discounted_text}>Discounted</div>
                            <div className={styles.discounted_price}>{discountedPrice}</div>
                        </>
                        :
                        <span>Payment Authorised</span>
                    } */}
                    {/* commented by Bearer Web Developer end  */}
                    <span>Payment Authorised</span> {/* added by Bearer Web Developer  */}
                    {/* modified by Bearer Web Developer end  */}
                </Button>}
                {/* fullDiscountUser, sign_up_stage condition in the following add by Bearer Web Developer (show one free order button when state is free order and sign_up_stage is 2 or 1)  */}
                { !(fullDiscountUser &&  sign_up_stage === 1) && !(fullDiscountUser &&  sign_up_stage === 2) && !riderInfo && <Button
                            className={styles.confirm}
                            onClick={handleConfirm}
                            disabled={!order.isReadyForPayment}
                >
                    {fullDiscountUser ? "Place Your FREE Order" : "Confirm & Pay"}  {/* modified by Bearer Web Developer (add fullDiscount condition)  */}
                </Button>}
                {/* added by Bearer Web Developer (show FREE Order button when sign_up_stage is 1) start  */}
                {
                    !riderInfo && (sign_up_stage === 1 || (sign_up_stage === 2 && fullDiscountUser)) &&
                    <Button className={`${styles.confirm} ${styles.confirm_free_order}`}
                        disabled={sign_up_stage !== 1 && !order.isReadyForPayment}
                        onClick={async () => {
                            if (sign_up_stage === 1) {
                                 appState?.accountVerification.setOpen(true);
                            } else if (sign_up_stage === 2) {
                                if (!order.isReadyForPayment) return;
                                const { is_full_discount, fullDiscountUsedOnce, coupon_id } = appState?.fullDiscountAutomatically;
                                if (is_full_discount && !fullDiscountUsedOnce) {
                                    order?.discount.setFullDiscountUser(true);
                                    order?.discount?.setDiscountValue({
                                        coupon_id, transport_receivable_price: 0
                                    });
                                }
                                setLoading(true);
                                await appState.selectedOrder?.payment.paymentAuthorization();
                                setLoading(false);
                            }
                        }}>
                        Place Your FREE Order
                    </Button>
                }
                {/* added by Bearer Web Developer (show FREE Order button when sign_up_stage is 1) end  */}
            </div>
            {loading && <DisabledClickLoading />} {/* added by Bearer Web Developer */}
        </form >
    );
    // modified by Bearer Web Developer end
});

export default OrderForm;

