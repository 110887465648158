// this file added by Bearer Web Developer
import { observer } from "mobx-react-lite";
import React from "react";
import { useAppState } from "../../../states/app-state";
import styles from './stepOne.module.scss';
import { Button } from "@material-ui/core";
import VerificationForm from "./verificationForm";
import EditEmailVertificationPopup from "./../../accountVerificationExtraPopups/editEmailVertificationPopup";
import EditMobileVertificationPopup from "./../../accountVerificationExtraPopups/editMobileVertificationPopup";

const StepOne: React.FC = observer(() => {

    const appState = useAppState();
    const { editEmailVertification, editMobileVertification } = appState;
    const sign_up_stage = appState?.createAccount?.sign_up_stage;
    const handleClose = () => {
        if (sign_up_stage === 1 || sign_up_stage === 2) return;
    }
    return (
        <>
            {
                <div className={styles.main}>
                    <div className={styles.header}>
                        <h5>Account Verification</h5>
                        <div className={styles.step_content}>
                            <div className={styles.step_number}><span>1</span>/2</div>
                            <Button onClick={handleClose}><img src="/back.png" alt="back" /></Button>
                        </div>
                    </div>

                    <p>Verify your email and phone to place your <span>FREE</span> order and unlock more features.</p>
                    <VerificationForm />
                    <div className={styles.step_show}><div className={styles.fill}></div></div>
                </div>
            }
            {editEmailVertification?.open && <EditEmailVertificationPopup open={editEmailVertification?.open} />}
            {editMobileVertification?.open && <EditMobileVertificationPopup open={editMobileVertification?.open} />}
        </>
    )
})
export default StepOne;