// this file added by Bearer Web Developer
import { flow, getEnv, getParentOfType, Instance, types } from "mobx-state-tree";
import { AppState, EnvType } from "../app-state";
import { error, log } from "../../app/log";
import registerBusinessCustomerEditMobile from "./registerBusinessCustomerEditMobile";
import klaviyo from "../klaviyo";

export const EditMobileVertificationState = types
    .model("EditMobileVertificationState", {
        open: types.optional(types.boolean, false),
        mobileNumber: types.optional(types.string, '+614'),
    })
    .actions(self => {
        return {
            setOpen(value: boolean) {
                self.open = value;
            },
            setMobileNumber(value: string) {
                self.mobileNumber = value;
            },
        }
    }).actions(self => {
        return {
            fetchRegisterBusinessCustomerEditMobile: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                const result = yield registerBusinessCustomerEditMobile(self.mobileNumber);
                try {
                    if (result?.code === 401 || result?.code === 499) {
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result.message || 'unknown error!',
                        });
                    } else if (result?.code === 200) {
                        const appState = getParentOfType(self, AppState);
                        appState?.accountVerification.setMobileNumber(self.mobileNumber);
                        appState?.profile?.setPhoneNumber( "0" + self?.mobileNumber.substring(3, self?.mobileNumber.length));
                        // klaviyo client subscription create profile start
                        klaviyo?.createClientSubscription({
                            email: appState?.profile?.email,
                            phone_number: self?.mobileNumber
                        })
                        // klaviyo client subscription create profile end
                        return result;
                    } else if (result.code === 500) {
                        log(result);
                    }
                } catch (err: any) {
                    error(err);
                }
            })
        }
    })
export interface IEditMobileVertificationState extends Instance<typeof EditMobileVertificationState> { };

