// this file added by Bearer Web Developer
import React from 'react';
import { Button } from '@material-ui/core';
import styles from './stepOne.module.scss';
import { useAppState } from '../../../states/app-state';
import { observer } from 'mobx-react-lite';
import AccountForm from './accountForm';


const StepOne: React.FC = observer(() => {
    const appState = useAppState();
    const { createAccount } = appState;
    const handleClose = () => {
        if (createAccount?.complete_registeration) return;
        createAccount?.setOpen(false);
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <h5>Create Account</h5>
                <div className={styles.step_content}>
                    <div className={styles.step_number}><span>1</span>/2</div>
                    <Button className={styles.back} onClick={handleClose}><img src="/back.png" alt="back" /></Button>
                </div>
            </div>
            <p>Enter your details to register.</p>
            <AccountForm />
            <div className={styles.step_show}><div className={styles.fill}></div></div>
        </div>
    )
});
export default StepOne;