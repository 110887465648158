// this file added by Bearer Web Developer
export const validateEmail = (value: string) => {
    if (!(value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) return 'Email address is not valid!'
}
export const validatePhone = (value: string) => {
    value = value?.slice(4);
    if (value.length === 0) return "Mobile Number is required (Beginning with +614)";
    if (!(value.length === 8)) return "8 Digits (Beginning with +614)";
    if (!value.match(/^[0-9]*$/)) return "8 Digits (Beginning with +614)";
}
export const handlePassword = (value: string) => {
    if (value.length === 0) return "New Password is required";
    if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/))
        return "Minimum 8 characters, at least 1 Uppercase letter, 1 Lowercase letter, 1 Number and 1 Special character"
    // if (value.length < 3) {
    //     return "New Password must be at least three characters";
    // }
    return '';
}


