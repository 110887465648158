/* this file added by Bearer Web Developer  */
export const handleRepeatPassword = (value: string, newPassword: string) => {
    if (value.length === 0) {
        return 'Repeat Password is required';
    } else if (value !== newPassword) {
        return 'Repeat Password is not the same as the New Password';
    } else return '';
}
export const handlePassword = (value: string) => {
    if (value.length === 0) return "New Password is required";
    if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/))
        return "Minimum 8 characters, at least 1 Uppercase letter, 1 Lowercase letter, 1 Number and 1 Special character"
    // if (value.length < 3) {
    //     return "New Password must be at least three characters";
    // }
    return '';
}
export const handleCurrentPassword = (value: string) => {
    if (value.length === 0) return "Current Password is required";
    if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/))
        return "Minimum 8 characters, at least 1 Uppercase letter, 1 Lowercase letter, 1 Number and 1 Special character"
    // if (value.length < 3) {
    //     return "Current Password must be at least three characters";
    // }
    return '';
}