// this file added by Bearer Web Developer
import React, { useEffect, useRef } from "react";
import styles from './profileForm.module.scss';
import { observer } from "mobx-react-lite";
import { useAppState } from "../../../../../states/app-state";
import { CircularProgress } from "@material-ui/core";
import { compressImage, readFile } from "./Imagefunctions";
import { fetchDownloadUrl } from "../../../../../api/bearer-api";


const BusinessLogo: React.FC = observer(() => {

    const appState = useAppState();
    const { completeProfile } = appState;
    const { avatarUrl, previwAvatarUrl, setPreviwAvatarUrl } = completeProfile;
    const [loading, setLoading] = React.useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null); // this is not be using yet
    const imageRef = useRef<HTMLImageElement>(null); // this is not be using yet

    const uploadProfileImage = async (file: File) => {
        // added by Bearer Web Developer start
        setLoading(true);
        const image = await readFile(file);
        if (!image) return;
        const compressedImage = await compressImage(image);
        if (!compressedImage) {
            setLoading(false);
            return;
        } else {
            if (appState.completeProfile.avatarUrl) appState.completeProfile.deleteProfileImage();
        };
        await appState.completeProfile.updateAvatar(compressedImage);
        setLoading(false);
        // added by Bearer Web Developer end
    }
    useEffect(() => {
        if (avatarUrl) {
            (async () => {
                setLoading(true); // added by Bearer Web Developer
                const result = await fetchDownloadUrl(avatarUrl);
                setLoading(false); // added by Bearer Web Developer
                if (result) {
                    setPreviwAvatarUrl(result);
                }
            })();
        } else {
            setPreviwAvatarUrl('');
        }
    }, [avatarUrl])
    return (
        <div className={styles.business_logo}>
            <h4>Business Logo</h4>
            <div className={styles.business}>
                {
                    loading ?
                        <div className={styles.loading}><CircularProgress /></div>
                        :
                        <div className={avatarUrl ? styles.avatar : styles.none_avatar}>
                            <img src={previwAvatarUrl || '/avatar.png'}
                                alt="" width={avatarUrl ? "auto" : 56}
                                height={avatarUrl ? 118 : 56}
                                ref={imageRef} />
                        </div>
                }
                <label htmlFor="avatar-image" className={styles.edit}>
                    <img src='/edit-blue.webp' alt="" width={24} height={24} />
                    <input
                        type="file"
                        name="avatar-image"
                        id="avatar-image"
                        ref={fileInputRef}
                        accept="image/*"
                        onChange={(event: any) => {
                            const file = event.target.files[0];
                            if (file && file.type.substr(0, 5) === 'image') {
                                uploadProfileImage(file);
                            }
                        }}
                    />
                </label>
                <h5>{appState?.profile?.businessName || ''}</h5>
            </div>
        </div>
    )
})
export default BusinessLogo;