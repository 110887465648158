// this file added by Bearer Web Developer
import React from 'react';
import BearerTextField from '../../../../components/fields/BearerTextField';
import { Button } from '@material-ui/core';
import styles from './accountForm.module.scss';
import { useAppState } from '../../../../states/app-state';
import { handlePassword, validateEmail, validatePhone } from './validation';
import { observer } from 'mobx-react-lite';
import BearerTextFieldPassword from '../../../../components/fields/BearerTextFieldPassword';
import CustomChecbox from '../../../../components/fields/customCheckbox';
import BusinessNumberInput from './businessNumberInput';
import { log } from '../../../../app/log';

const AccountForm: React.FC = observer(() => {

    const appState = useAppState();
    const { accountForm, createAccount } = appState;
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (checkvalidation()) {
            log(accountForm.getFormData());
            createAccount?.setStep(1);
        }
    }
    const handleRepeatPassword = (value: string) => {
        if (value.length === 0) {
            return 'Repeat Password is required';
        } else if (value !== accountForm.password) {
            return 'Repeat Password is not the same as the New Password';
        } else return '';
    }
    function checkvalidation() {
        let validation = true;
        validation = !validateEmail(accountForm.email) && !validatePhone(accountForm.mobileNumber)
            && !handlePassword(accountForm.password) && !handleRepeatPassword(accountForm.repeatPassword)
            && !!accountForm?.legal_name && accountForm?.bearerBusinessCustomerAgreement
            && accountForm?.bearerPrivacyPolicy
        return validation;
    }
    return (
        <form onSubmit={handleSubmit} className={styles.account_form}>
            <div className={styles.input}>
                <BearerTextField
                    colorStyle="gray"
                    label="Email address"
                    placeholder=""
                    type="email"
                    name="email"
                    value={accountForm.email}
                    onChange={(e) => accountForm.setEmail(e.target.value)}
                    clear={() => { accountForm.setEmail('') }} // added by Bearer Web Developer
                    validator={validateEmail}
                />
            </div>
            <div className={styles.input}>
                <BearerTextField
                    colorStyle="gray"
                    label="Mobile Number"
                    inputProps={{ maxLength: "12" }} // add maxlength of Phone Number of destination by Bearer Web Developer
                    placeholder={""}
                    value={accountForm.mobileNumber}
                    onChange={(e) => {
                        const beginning = "+614", beginningNumber = 4;
                        const text = e.target.value.slice(0, beginningNumber) !== beginning ? beginning + e.target.value.slice(beginningNumber) : e.target.value;
                        accountForm.setMobileNumber(text);
                    }}
                    clear={() => { accountForm.setMobileNumber('+614') }} // added by Bearer Web Developer
                    validator={validatePhone}
                />
            </div>
            <BusinessNumberInput />
            <div className={styles.password}>
                <BearerTextFieldPassword
                    value={accountForm.password}
                    name="password"
                    label="Password"
                    placeholder=""
                    autoComplete="password"
                    onChange={(e) => { accountForm.setPassword(e.target.value); }}
                    // onKeyPress={handleKeyPress}
                    validator={handlePassword}
                    colorStyle="gray"
                />
                <BearerTextFieldPassword
                    value={accountForm.repeatPassword}
                    name="repeat-password"
                    label="Repeat Password"
                    placeholder=""
                    autoComplete="repeat-password"
                    onChange={(e) => { accountForm.setRepeatPassword(e.target.value); }}
                    // onKeyPress={handleKeyPress}
                    validator={handleRepeatPassword}
                    colorStyle="gray"
                />
            </div>
            <div className={styles.privacy}>
                <CustomChecbox checked={accountForm?.bearerPrivacyPolicy}
                    label='Bearer Privacy Policy'
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        accountForm.setPrivacyPolicy(e?.target?.checked)
                    }}
                />
            </div>
            <div className={styles.business_customer}>
                <CustomChecbox checked={accountForm?.bearerBusinessCustomerAgreement}
                    label='Bearer Business Customer Agreement'
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        accountForm.setBusinessCustomer(e?.target?.checked)
                    }}
                />
            </div>
            <div>
                <Button type='submit' className={styles.button} disabled={!checkvalidation()} disableRipple={true}>
                    <span>Next</span>
                </Button>
            </div>
        </form>
    )
});
export default AccountForm;