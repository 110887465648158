import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, onAuthStateChanged } from "firebase/auth";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { getStorage, ref, deleteObject, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";
import { getDatabase, set, child, onValue, get, off } from "firebase/database";
import { ref as databaseRef } from "firebase/database";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { ParcelType, Location, IMessageStateSnapshot, IOrderState, IAppState, AppState, MessageEnum } from '../states/app-state';
import { detectIncognito } from "detectincognitojs"; // installed and added by Bearer Web Developer
import { error, log } from "../app/log"; // added by Bearer Web Developer
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";  // added by Bearer Web Developer
const polyUtil = require('polyline-encoded');

const firebaseConfig = {
    apiKey: process.env.REACT_APP_ApiKey,
    authDomain: process.env.REACT_APP_AuthDomain,
    databaseURL: process.env.REACT_APP_DatabaseURL,
    projectId: process.env.REACT_APP_ProjectId,
    storageBucket: process.env.REACT_APP_StorageBucket,
    messagingSenderId: process.env.REACT_APP_MessagingSenderId,
    appId: process.env.REACT_APP_AppId,
    measurementId: process.env.REACT_APP_MeasurementId,
};
// Bearer Web Developer: Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig); // export added by Bearer Web Developer

const storage = getStorage();
const database = getDatabase();
const firestore = getFirestore();
// Bearer Web Developer: Messaging service 
// const messaging = getMessaging(firebaseApp); // modify by Bearer Web Developer (sent firebaseApp as argument) // COMMENTED BY Amir Siabi for Solving WebKit Issue 15 Aug 2024
export const functions = getFunctions(firebaseApp); // export added by Bearer Web Developer
const auth = getAuth();
const remoteConfig = getRemoteConfig();
// added by Bearer Web Developer start
// Firebase Analytics
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(firebaseApp);
// added by Bearer Web Developer end
// added by Bearer Web Developer start
interface IRemoteConfigData {
    environmentCode: string
    klaviyoApiPublicKey: string
    klaviyoApiPrivateKey: string
}
export const getRemoteConfigData = async (): Promise<IRemoteConfigData> => {

    try {
        await fetchAndActivate(remoteConfig);
        const environmentCode = getValue(remoteConfig, "environment_code").asString();
        const klaviyoApiPublicKey = getValue(remoteConfig, "klaviyo_api_public_key").asString();
        const klaviyoApiPrivateKey = getValue(remoteConfig, "klaviyo_api_private_key").asString();
        return { environmentCode, klaviyoApiPublicKey, klaviyoApiPrivateKey };

    } catch (error) {
        log(error)
        return { environmentCode: '', klaviyoApiPublicKey: '', klaviyoApiPrivateKey: '' };
    }
}
// added by Bearer Web Developer end
// added by Bearer Web Developer start
export function checkSafari() {
    // const ua = navigator.userAgent.toLowerCase();
    // console.log(ua);
    // if (ua.indexOf('safari') === -1 || ua.indexOf('chrome') > -1) {
    //     console.log("not Safari browser");
    //     // not Safari browser
    //     return false;
    // } else {
    //     // Safari browser
    //     console.log("Safari browser");
    //     return true;
    // }
    // Safari 3.0+ "[object HTMLElementConstructor]" 
    let isSafari = /constructor/i.test(window?.HTMLElement?.toString()) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!(window as any)['safari'] || (typeof (window as any)['safari'] !== 'undefined' && (window as any)['safari']?.pushNotification));
    return isSafari;
}
// added by Bearer Web Developer end
// added by Bearer Web Developer start
export async function requestPermission() {
    //requesting permission using Notification API
    if ('Notification' in window) {
        const permission = await Notification?.requestPermission();
        const supported = await isSupported();
        if (permission === "granted") {
            if (supported) {
                const messaging = getMessaging(firebaseApp);
                const token = await getToken(messaging, {
                    vapidKey: process.env.REACT_APP_VapIdKey,
                });
                // We can send token to server
                log("Token generated : ", token);
                localStorage.setItem("Permission", "Enabled");
            }
        } else if (permission === "denied") {
            // notifications are blocked
            log("You denied for the notification");
        }
    }
}
// added by Bearer Web Developer end
// added by Bearer Web Developer start
export function setDefaultOrigin() {
    const appState = BearerApi?.appState;
    const favouritePlaces = appState?.favouritePlaces.favouritePlacesOptions?.map(fp => fp);
    const defaultOrigin = favouritePlaces?.filter((value) => (value.is_set_as_default_origin === true))?.[0];
    // const defaultOrigin = favouritePlaces?.filter((value)=>(value.details === "Level 5"))?.[0];
    if (appState?.login.loggedin && !appState.selectedOrder?.origin.address) {
        if (defaultOrigin) {
            const origin = appState.selectedOrder?.origin;
            origin?.setAddress({
                description: defaultOrigin.address,
                location: {
                    lat: defaultOrigin.location_lat, lng: defaultOrigin.location_lng
                }
            });
            origin?.setMoreDetails(defaultOrigin.details);
            appState.selectedOrder?.origin.setEditing(false);
            appState.selectedOrder?.destination.setEditing(true);
        }
    }
}
// added by Bearer Web Developer end
remoteConfig.settings.minimumFetchIntervalMillis = 0;

export type TDiscountRequest = {
    discount_token: string;
    transport_id: string;
    user_type: string;
}

export type TSetting = {
    account_updates: "push_not" | "text_msg",
    receive_payment_receipt: "automatic" | "manual",
    news_marketing_messages: "off" | "on",

}
export type TTransportRequest = {
    price_list_id: string,
    selected_mode_of_transport: string,
    origin_details: string,
    origin_phone_number: string,
    sender_name: string,
    destination_details: string,
    recipient_phone_number: string,
    recipient_name: string,
    dropoff_verification_method: string | null,
}
export type TFcmToken = {
    ios: string,
    android: string,
    web: string,
}

type TPricingRequest = {
    origin: {
        lat: number,
        lng: number,
    };
    destination: {
        lat: number,
        lng: number,
    };
    vehicle_type: {
        walking: boolean,
        riding: boolean,
        cycling: boolean,
    };
    parcel_type: string,
    parcel_description: string,
    parcel_min_weight: number,
    parcel_max_weight: number,
    parcel_img_url: string // added by Bearer Web Developer
};
export type TCardToken = {
    cardToken: string,
};
export type Metadata = {}

export type TCardInformation = {
    id: string;
    object: string;
    address_city?: any;
    address_country?: any;
    address_line1?: any;
    address_line1_check?: any;
    address_line2?: any;
    address_state?: any;
    address_zip: string;
    address_zip_check: string;
    brand: string;
    country: string;
    customer: string;
    cvc_check: string;
    dynamic_last4?: any;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    last4: string;
    metadata: Metadata;
    name?: any;
    tokenization_method?: any;
    isNew?: boolean // added by Bearer Web Developer
}

export type TTransportOption = {
    // price: string,
    price: number,
    overview_polyline?: string,
    time: string,
    destination_address: string,
    length: string,
    origin_address: string,
    distance?: number,
    duration: number,
    overview_polyline_latlng: Location[],
    type: string,
}
export type TRiding = TTransportOption & {
    type: 'riding',
};

export type TCycling = TTransportOption & {
    type: 'cycling',
};

export type TWalking = TTransportOption & {
    type: 'walking',
};

export type TPriceResponseSuccess = {
    status: 'Successful',
    code: 201 | 200,
    riding?: TRiding,
    cycling?: TCycling,
    walking?: TWalking,
    price_list_id: string,
    message: string,
}
export type TPriceResponseError = {
    status: 'Client Error' | 'Server Error',
    code: 403 | 500,
    message: string,
}


export type TSettingResponseSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
}
export type TSettingResponseError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 500,
    message: string,
}
export type TLoginResponse = {
    uid: string;
}
export type BearerTime = {
    _seconds: number;
    _nanoseconds: number;
}
export type BearerGeopoint = {
    _latitude: number;
    _longitude: number;
}
export type TTransport = {
    original_payable_to_rider?: number;
    shortened_origin_address?: string;
    price_create_time?: BearerTime;
    bearer_platform_fees?: number;
    dropoff_verification_method?: string;
    transport_receivable_price?: number;
    parcel_max_weight?: number,
    parcel_min_weight?: number,
    sender_name?: string,
    latest_update_time?: BearerTime,
    destination_address?: string,
    previous_status?: string,
    sender_phone_number?: string,
    destination_details?: string,
    origin_details?: string,
    order_number?: string,
    latest_state?: string,
    previous_state?: string,
    bearer_type?: string,
    latest_status?: string,
    price_list_id?: string,
    transport_time?: string,
    transport_type?: string,
    origin_geopoint?: BearerGeopoint,
    parcel_description?: string,
    parcel_type?: string,
    creation_time?: BearerTime,
    origin_address?: string,
    previous_update_time?: BearerTime;
    recipient_name?: string,
    transport_id: string,
    order_secret?: string,
    recipient_phone_number?: string,
    transport_original_price?: number,
    customer_user_id?: string
    destination_geopoint?: BearerGeopoint,
    transport_duration?: number,
    transport_length?: string,
    overview_polyline?: string,
}

export type TDiscount = {
    transport_receivable_price: number,
    coupon_id?: string,
}

export type TDiscountResponseSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
} & TDiscount;

export type TDiscountResponseError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 400 | 402 | 410 | 500,
    message: string,
}

export type TTransportSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
} & TTransport;

export type TTransportError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 402 | 500,
    message: string,
}

export type TStripeCardData = {
    data: TCardInformation[],
    object: "list",
    has_more: boolean,
    url: string,
}

export type TGetCardSuccess = {
    stripeData: TStripeCardData,
    status: 'Successful',
    code: 200,
    message: string,
}
export type TGetCardError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 500 | 501,
    message: string,
}
export type TStripeCardObject = {}
export type TAddCardSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
    response: TStripeCardObject,
}
export type TAddCardError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 500 | 501,
    message: string,
}
export type TRemoveCardSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
}
export type TRemoveCardError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 500 | 501,
    message: string,
}
export type TUpdateAppSuccess = {
    status?: 'Successful',
    code?: 200,
    message?: string,
    ipAlertMessage?: boolean, // added by Bearer Web Developer
}
export type TUpdateAppError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 500,
    message: string,
    ipAlertMessage?: boolean, // added by Bearer Web Developer
}
export type TUpdateApp = {
    bearer_type: "customer",
    version: string,
    ip: string,
}

export type TPaymentAuthorizationRequest = {
    cardToken: string;
    transport_id: string;
    device_type: 'web';
    coupon_id?: string;
    is_full_discount?: boolean, // added by Bearer Web Developer
}
export type TDynamicPassword = {
    actionUrl: string,
    actionType: string,
}

export type TPaymentAuthorizationSuccess = {
    status: 'Successful';
    code: 200;
    paymentStatus: string,
    message: string,
} | ({
    code: 201;
    status: 'Successful';
    paymentStatus: string,
    message: string,
} & TDynamicPassword);

export type TFareUpdated = {
    transport_original_price: number;
    oldPrice?: number,
}
export type TCardProblem = {
    paymentStatus: string,
}

export type TPaymentAuthorizationError = {
    code: 401 | 402 | 410 | 413 | 423 | 400 | 500,
    status: 'Client Error' | 'Server Error',
    message: string,
} | ({
    code: 412,
    status: 'Client Error',
    message: string,
} & TFareUpdated) | ({
    code: 425,
    status: 'Client Error',
} & TCardProblem)

export type TSearchRiderRequest = {
    transport_id: string;
}
export type TRiderInfo = {
    bearer_type: string,
    rider_given_name: string,
    rider_last_name: string,
    rider_avatar_url: string,
    motorbike_make?: string,
    motorbike_model?: string,
    motorbike_registration_number?: string,
    motorbike_registration_state?: string,
    origin_address: string,
    origin_lat?: number,
    origin_lng?: number,
    destination_address: string,
    destination_lat?: number,
    destination_lng?: number,
    order_secret?: string,
    cancellation_fee?: number,
    state_description?: string,
    transport_id: string,
    order_number?: string // added by Bearer Web Developer
}
export type TSearchRiderSuccess = ({
    status: 'Successful',
    code: 200 | 201,
    message: string,
});
export type TSearchRiderError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 402 | 410 | 411 | 421 | 500;
    message: string;
}

export type TTransportStateResponse = TTransportAccepted | TTransportStateResponseFirstSearch |
    TTransportStateResponseSecondSearch | TTransportCancelByCustomer | TTransportCanceledByRider | TTransportStateA | TTransportStateB |
    TTransportStateC | TTransportStateD | TBearerCompletedTransport | TExpiredTransport;

export type TTransportAccepted = {
    status: 'Successful',
    code: 200;
    message: string,
    realtime_code: 3,
} & TRiderInfo;
export type TTransportStateResponseFirstSearch = {
    state: string,
    code: 201,
    message: string,
    realtime_code: 1,
}
export type TTransportStateResponseSecondSearch = {
    state: string,
    code: 202,
    message: string,
    realtime_code: 2,
}
export type TTransportCancelByCustomer = {
    state: string,
    code: 422,
    message: string,
    realtime_code: 4,
}
export type TTransportCanceledByRider = {
    type: 'transport_cancel',
    state_description: string,
    transport_id: string,
    realtime_code: 5,
}
export type TTransportStateA = {
    type: 'transport_state',
    subtype: 'A',
    state_description: string,
    transport_id: string,
    realtime_code: 7,
}
export type TTransportStateB = {
    type: 'transport_state',
    subtype: 'B',
    state_description: string,
    transport_id: string,
    realtime_code: 8,
}
export type TTransportStateC = {
    type: 'transport_state',
    subtype: 'C',
    state_description: string,
    transport_id: string,
    realtime_code: 9,
}
export type TTransportStateD = {
    type: 'transport_state',
    subtype: 'D',
    state_description: string,
    transport_id: string,
    realtime_code: 10,
}
export type TBearerCompletedTransport = {
    type: 'transport_finished',
    state_description: string,
    transport_id: string,
    realtime_code: 11,
}
export type TExpiredTransport = {
    state: string,
    code: 423,
    message: string,
    realtime_code: 6,
}

export type TCancelTransportBeforeAcceptRequest = {
    transport_id: string;
}

export type TCallSupport = {
    message: string;
}
export type TCancelTransportBeforeAcceptError = {
    code: 401 | 402 | 410 | 421 | 422 | 423 | 500;
    status: 'Server Error' | 'Client Error';
    message: string;
} | ({
    code: 420;
    status: 'Client Error';
} & TCallSupport)

export type TCancelTransportBeforeAcceptSuccess = {
    status: 'Successful';
    code: 200;
    message: string;
}

export type TcustomerCancelTransportAfterAcceptRequest = {
    transport_id: string;
    cancelation_reason: string;
}

export type TcustomerCancelTransportAfterAcceptSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
}
export type TcustomerCancelTransportAfterAcceptError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 402 | 410 | 421 | 422 | 500,
    message: string,
}

export type TCustomerRateTransportRequest = {
    transport_id: string;
    safe_delivery: number;
    politeness: number;
    being_ontime: number;
}
export type TCustomerRateTransportSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
}
export type TCustomerRateTransportError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 402 | 410 | 421 | 424 | 500,
    message: string,
}
export type TTransportRequestAgainRequest = {
    transport_id: string;
}
export type TTransportRequestAgainSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
    new_transport_id: string,
}
export type TTransportRequestAgainError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 402 | 410 | 421 | 500;
    message: string,
}

export type TCustomerCancelTransportAfterCancelByRiderRequest = {
    transport_id: string;
}
export type TCustomerCancelTransportAfterCancelByRiderSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
}
export type TCustomerCancelTransportAfterCancelByRiderError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 402 | 410 | 421 | 422 | 500;
    message: string,
}

export type TCustomerTransportHistoryRequest = {
    cursor_transport_id?: string,
}

export type TransportTime = {
    _seconds: number;
    _nanoseconds: number;
}
export type Geopoint = {
    _latitude: number;
    _longitude: number;
}
export type TTransportsHistoryInfo = {
    transport_id: string,
    overview_polyline: string,
    destination_address: string,
    origin_address: string,
    assigned_bearer_type: string,
    motorbike_registration_number?: string,
    motorbike_make?: string,
    motorbike_model?: string,
    motorbike_registration_state?: string,
    order_number: string,
    origin_geopoint: Geopoint,
    transport_receivable_price: number,
    transport_original_price: number,
    destination_geopoint: Geopoint,
    transport_state: string,
    transport_time: TransportTime;
    rider_given_name: string,
    rider_last_name: string,
    rider_avatar_url: string,

}
export type TCustomerTransportHistorySuccess = {
    status: 'Successful',
    code: 200,
    message: string,
    cursor_transport_id?: string,
    transports: { [id: string]: TTransportsHistoryInfo },
    // transportList: TTransportsHistoryInfo[]

} | {
    status: 'Successful',
    code: 201,
    message: string,
}
export type TCustomerTransportHistoryError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 500,
    message: string,
}

export type TEmailTransportReceiptRequest = {
    transport_id: string;
    email: string;
}
export type TEmailTransportReceiptSuccess = {
    status: 'Successful',
    code: 200 | 201,
    message: string,
}

export type TEmailTransportReceiptError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 402 | 410 | 421 | 500,
    message: string,
}

export type TAddFavouritePlacesRequest = {
    place_name: string,
    address: string,
    location_lat: number,
    location_lng: number,
    details: string,
    is_set_as_default_origin: null | boolean // added by Bearer Web Developer
}

export type TAddFavouritePlacesSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
    favourite_place_id: string,
}

export type TAddFavouritePlacesError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 500,
    message: string,
}

export type TRemoveFavouritePlacesRequest = {
    favourite_place_id: string,
}
export type TRemoveFavouritePlacesSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
}
export type TRemoveFavouritePlacesError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 500 | 435, // 435 added by Bearer Web Developer
    message: string,
}

export type TFavouritePlacesInfo = {
    address: string;
    place_name: string;
    id: string;
    details: string;
    location_lat: number;
    location_lng: number;
    is_set_as_default_origin?: null | boolean // added by Bearer Web Developer
}

export type TGetFavouritePlacesSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
    favourite_places: { [id: string]: TFavouritePlacesInfo },
} | {
    status: 'Successful',
    code: 201,
    message: string,
}
export type TGetFavouritePlacesError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 500,
    message: string,
}
export type TCheckCustomerLoginPermissionRequest = {
    platform_type: string;
}
export type TCustomerData = {
    given_name: string,
    last_name: string,
    mobile_number: string,
    email_address: string,
    avatar_url: string,
    latest_tc_pp_vers_signed: string,
    user_type: string,
    business_name: string,
    order_limits: number,
    settings: TSetting,
    favourite_places: { [id: string]: TFavouritePlacesInfo },
    is_api_access_allowed: boolean, // added by Bearer Web Developer
    api_access_not_allowed_reason: string, // added by Bearer Web Developer
    is_api_access_enabled_by_user: boolean, // added by Bearer Web Developer
    allowed_intermediate_api_providers: string[], // added by Bearer Web Developer
    allowed_intermediate_api_platfroms: string[], // added by Bearer Web Developer
    api_key: String, // added by Bearer Web Developer
    coupon_id: string, // added by Bearer Web Developer
    is_full_discount: boolean, // added by Bearer Web Developer
    sign_up_stage: number, // added by Bearer Web Developer
    sign_up_date_in_digits: string, // added by Bearer Web Developer
    referred_by: string, // added by Bearer Web Developer
}
export type TCheckCustomerLoginPermissionSuccess = ({
    status: 'Successful',
    code: 200,
    message: string,
} & TCustomerData)

export type TCheckCustomerLoginPermissionError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 404 | 406 | 408 | 501 | 502 | 500,
    message: string,
}
export type TUpdateCustomerFcmTokenSuccess = {
    status: 'Successful',
    code: 200 | 404,
    message: string,
}
export type TUpdateCustomerFcmTokenError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 403 | 500,
    message: string,
}

export type TShowAlertServerTransportState = {
    state: 'Show Alert',
    alert_text: string,
    transport_id: string,
}

export type TNoActiveTransportServerTransportState = {
    state: 'No Active Transport'
}

export type TProcessingPaymentServerTransportState = {
    state: 'Processing Payment',
    transport_id: string,
    origin_address: string,
    origin_details: string,
    origin_lat: number,
    origin_lng: number,
    sender_name: string,
    sender_phone_number: string,
    destination_address: string,
    destination_details: string,
    destination_lat: number,
    destination_lng: number,
    recipient_name: string,
    recipient_phone_number: string,
    parcel_type: string,
    parcel_description: string,
    parcel_min_weight: number,
    parcel_max_weight: number,
    price_list_id: string,
    overview_polyline: string,
    transport_duration: number,
    transport_length: string,
    transport_time: string,
    transport_original_price: number,
    transport_receivable_price: number,
    order_number: string,
    order_secret: string,
    dropoff_verification_method: string,
    bearer_type: string,
    paymentStatus: string,
    actionUrl: string,
    actionType: string
}

export type T3DFailedServerTransportState = {
    state: '3DS Failed',
    transport_id: string,
    origin_address: string,
    origin_details: string,
    origin_lat: number,
    origin_lng: number,
    sender_name: string,
    sender_phone_number: string,
    destination_address: string,
    destination_details: string,
    destination_lat: number,
    destination_lng: number,
    recipient_name: string,
    recipient_phone_number: string,
    parcel_type: string,
    parcel_description: string,
    parcel_min_weight: number,
    parcel_max_weight: number,
    price_list_id: string,
    overview_polyline: string,
    transport_duration: number,
    transport_length: string,
    transport_time: string,
    transport_original_price: number,
    transport_receivable_price: number,
    order_number: string,
    order_secret: string,
    dropoff_verification_method: string,
    bearer_type: string,
    paymentStatus: string,
}

export type TAuthorisedServerTransportState = {
    state: 'Authorised',
    transport_id: string,
    origin_address: string,
    origin_details: string,
    origin_lat: number,
    origin_lng: number,
    sender_name: string,
    sender_phone_number: string,
    destination_address: string,
    destination_details: string,
    destination_lat: number,
    destination_lng: number,
    recipient_name: string,
    recipient_phone_number: string,
    parcel_type: string,
    parcel_description: string,
    parcel_min_weight: number,
    parcel_max_weight: number,
    price_list_id: string,
    overview_polyline: string,
    transport_duration: number,
    transport_length: string,
    transport_time: string,
    transport_original_price: number,
    transport_receivable_price: number,
    order_number: string,
    order_secret: string,
    dropoff_verification_method: string,
    bearer_type: string,
    paymentStatus: string
}

export type TFirstTryServerTransportState = {
    state: 'First Try',
    transport_id: string,
    origin_address: string,
    origin_details: string,
    origin_lat: number,
    origin_lng: number,
    sender_name: string,
    sender_phone_number: string,
    destination_address: string,
    destination_details: string,
    destination_lat: number,
    destination_lng: number,
    recipient_name: string,
    recipient_phone_number: string,
    parcel_type: string,
    parcel_description: string,
    parcel_min_weight: number,
    parcel_max_weight: number,
    price_list_id: string,
    overview_polyline: string,
    transport_duration: number,
    transport_length: string,
    transport_time: string,
    transport_original_price: number,
    transport_receivable_price: number,
    order_number: string,
    order_secret: string,
    dropoff_verification_method: string,
    bearer_type: string
}

export type TSecondTryServerTransportState = {
    state: 'Second Try',
    transport_id: string,
    origin_address: string,
    origin_details: string,
    origin_lat: number,
    origin_lng: number,
    sender_name: string,
    sender_phone_number: string,
    destination_address: string,
    destination_details: string,
    destination_lat: number,
    destination_lng: number,
    recipient_name: string,
    recipient_phone_number: string,
    parcel_type: string,
    parcel_description: string,
    parcel_min_weight: number,
    parcel_max_weight: number,
    price_list_id: string,
    overview_polyline: string,
    transport_duration: number,
    transport_length: string,
    transport_time: string,
    transport_original_price: number,
    transport_receivable_price: number,
    order_number: string,
    order_secret: string,
    dropoff_verification_method: string,
    bearer_type: string,
}

export type TRiderOnTheWayToOriginServerTransportState = {
    state: 'Rider on the way to Origin',
    state_description: string,
    transport_id: string,
    origin_address: string,
    origin_details: string,
    origin_lat: number,
    origin_lng: number,
    sender_name: string,
    sender_phone_number: string,
    destination_address: string,
    destination_details: string,
    destination_lat: number,
    destination_lng: number,
    recipient_name: string,
    recipient_phone_number: string,
    parcel_type: string,
    parcel_description: string,
    parcel_min_weight: number,
    parcel_max_weight: number,
    price_list_id: string,
    overview_polyline: string,
    transport_duration: number,
    transport_length: string,
    transport_time: string,
    transport_original_price: number,
    transport_receivable_price: number,
    order_number: string,
    order_secret: string,
    dropoff_verification_method: string,
    bearer_type: string,
    assigned_bearer_type: string,
    motorbike_make?: string,
    motorbike_model?: string,
    motorbike_registration_number?: string,
    motorbike_registration_state?: string,
    rider_given_name: string,
    rider_last_name: string,
    rider_avatar_url: string,
    cancellation_fee: number,
}
export type TArrivedTOriginServerTransportState = {
    state: 'Arrived to Origin',
    state_description: string,
    transport_id: string,
    origin_address: string,
    origin_details: string,
    origin_lat: number,
    origin_lng: number,
    sender_name: string,
    sender_phone_number: string,
    destination_address: string,
    destination_details: string,
    destination_lat: number,
    destination_lng: number,
    recipient_name: string,
    recipient_phone_number: string,
    parcel_type: string,
    parcel_description: string,
    parcel_min_weight: number,
    parcel_max_weight: number,
    price_list_id: string,
    overview_polyline: string,
    transport_duration: number,
    transport_length: string,
    transport_time: string,
    transport_original_price: number,
    transport_receivable_price: number,
    order_number: string,
    order_secret: string,
    dropoff_verification_method: string,
    bearer_type: string,
    assigned_bearer_type: string,
    motorbike_make?: string,
    motorbike_model?: string,
    motorbike_registration_number?: string,
    motorbike_registration_state?: string,
    rider_given_name: string,
    rider_last_name: string,
    rider_avatar_url: string,
    cancellation_fee: number,
}
export type TParcelPickedUpServerTransportState = {
    state: 'Parcel Picked Up',
    state_description: string,
    transport_id: string,
    origin_address: string,
    origin_details: string,
    origin_lat: number,
    origin_lng: number,
    sender_name: string,
    sender_phone_number: string,
    destination_address: string,
    destination_details: string,
    destination_lat: number,
    destination_lng: number,
    recipient_name: string,
    recipient_phone_number: string,
    parcel_type: string,
    parcel_description: string,
    parcel_min_weight: number,
    parcel_max_weight: number,
    price_list_id: string,
    overview_polyline: string,
    transport_duration: number,
    transport_length: string,
    transport_time: string,
    transport_original_price: number,
    transport_receivable_price: number,
    order_number: string,
    order_secret: string,
    dropoff_verification_method: string,
    bearer_type: string,
    assigned_bearer_type: string,
    motorbike_make?: string,
    motorbike_model?: string,
    motorbike_registration_number?: string,
    motorbike_registration_state?: string,
    rider_given_name: string,
    rider_last_name: string,
    rider_avatar_url: string,
    cancellation_fee: number,

}
export type TRiderArrivedToDestinationServerTransportState = {
    state: 'Rider Arrived to Destination',
    state_description: string,
    transport_id: string,
    origin_address: string,
    origin_details: string,
    origin_lat: number,
    origin_lng: number,
    sender_name: string,
    sender_phone_number: string,
    destination_address: string,
    destination_details: string,
    destination_lat: number,
    destination_lng: number,
    recipient_name: string,
    recipient_phone_number: string,
    parcel_type: string,
    parcel_description: string,
    parcel_min_weight: number,
    parcel_max_weight: number,
    price_list_id: string,
    overview_polyline: string,
    transport_duration: number,
    transport_length: string,
    transport_time: string,
    transport_original_price: number,
    transport_receivable_price: number,
    order_number: string,
    order_secret: string,
    dropoff_verification_method: string,
    bearer_type: string,
    assigned_bearer_type: string,
    motorbike_make?: string,
    motorbike_model?: string,
    motorbike_registration_number?: string,
    motorbike_registration_state?: string,
    rider_given_name: string,
    rider_last_name: string,
    rider_avatar_url: string,
    cancellation_fee: number,
}

export type TParcelDroppedOffAtDestinationServerTransportState = {
    state: 'Parcel Dropped Off at Destination',
    state_description: string,
    transport_id: string,
    origin_address: string,
    origin_details: string,
    origin_lat: number,
    origin_lng: number,
    sender_name: string,
    sender_phone_number: string,
    destination_address: string,
    destination_details: string,
    destination_lat: number,
    destination_lng: number,
    recipient_name: string,
    recipient_phone_number: string,
    parcel_type: string,
    parcel_description: string,
    parcel_min_weight: number,
    parcel_max_weight: number,
    price_list_id: string,
    overview_polyline: string,
    transport_duration: number,
    transport_length: string,
    transport_time: string,
    transport_original_price: number,
    transport_receivable_price: number,
    order_number: string,
    order_secret: string,
    dropoff_verification_method: string,
    bearer_type: string,
    assigned_bearer_type: string,
    motorbike_make?: string,
    motorbike_model?: string,
    motorbike_registration_number?: string,
    motorbike_registration_state?: string,
    rider_given_name: string,
    rider_last_name: string,
    rider_avatar_url: string,
    cancellation_fee: number,
}

export type TNotRatedServerTransportState = {
    state: 'Not Rated Yet',
    transport_id: string,
    origin_address: string,
    origin_details: string,
    destination_address: string,
    destination_details: string,
    bearer_type: string,
    assigned_bearer_type: string,
    motorbike_make?: string,
    motorbike_model?: string,
    motorbike_registration_number?: string,
    motorbike_registration_state?: string,
    rider_given_name: string,
    rider_last_name: string,
    rider_avatar_url: string,
    cancellation_fee: number,
}

export type TServerTransportState = TNoActiveTransportServerTransportState | TShowAlertServerTransportState | TProcessingPaymentServerTransportState
    | T3DFailedServerTransportState | TAuthorisedServerTransportState | TFirstTryServerTransportState
    | TSecondTryServerTransportState | TRiderOnTheWayToOriginServerTransportState | TArrivedTOriginServerTransportState
    | TParcelPickedUpServerTransportState | TRiderArrivedToDestinationServerTransportState
    | TParcelDroppedOffAtDestinationServerTransportState | TNotRatedServerTransportState;

export type TGetCustomerAppStateRequest = {
    platform_type: string,
}
export type TGetCustomerAppStateSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
    transports?: { [id: string]: TServerTransportState },  // modified by Bearer Web Developer
    state?: string // added by Bearer Web Developer
}
export type TGetCustomerAppStateError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 500,
    message: string,
}

export type TChooseAnotherPaymentCardRequest = {
    transport_id: string;
}

export type TChooseAnotherPaymentCardSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
}
export type TChooseAnotherPaymentCardError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 402 | 410 | 421 | 422 | 500,
    message: string,
}

export type TCustomerCancelTransportBeforPaymentRequest = {
    transport_id: string;
}

export type TCustomerCancelTransportBeforPaymentSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
}
export type TCustomerCancelTransportBeforPaymentError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 402 | 410 | 421 | 422 | 500,
    message: string,
}

export type TCustomerSkipRatingRequest = {
    transport_id: string,
}
export type TCustomerSkipRatingSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
}
export type TCustomerSkipRatingError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 402 | 410 | 421 | 424 | 500,
    message: string,
}

export type TCustomerUpdateProfileRequest = {
    given_name: string;
    last_name: string;
    mobile_number: string;
    avatar_url: string;
}
export type TCustomerUpdateProfileSuccess = {
    status: 'Successful',
    code: 200,
    message: string,
}
export type TCustomerUpdateProfileError = {
    status: 'Client Error' | 'Server Error',
    code: 401 | 500,
    message: string,
}


export type TPricingResponse = TPriceResponseSuccess | TPriceResponseError;
export type TSettingResponse = TSettingResponseSuccess | TSettingResponseError;
export type TDiscountResponse = TDiscountResponseSuccess | TDiscountResponseError;
export type TTransportResponse = TTransportSuccess | TTransportError;
export type TGetCardResponse = TGetCardSuccess | TGetCardError;
export type TAddCardResponse = TAddCardSuccess | TAddCardError;
export type TRemoveCardResponse = TRemoveCardSuccess | TRemoveCardError;
export type TUpdateAppResponse = TUpdateAppSuccess | TUpdateAppError;
export type TPaymentAuthorizationResponse = TPaymentAuthorizationSuccess | TPaymentAuthorizationError;
export type TSearchRiderResponse = TSearchRiderSuccess | TSearchRiderError;
export type TCancelTransportBeforeAcceptResponse = TCancelTransportBeforeAcceptSuccess | TCancelTransportBeforeAcceptError;
export type TCustomerCancelTransportAfterAcceptResponse = TcustomerCancelTransportAfterAcceptSuccess | TcustomerCancelTransportAfterAcceptError;
export type TCustomerRateTransportResponse = TCustomerRateTransportSuccess | TCustomerRateTransportError;
export type TTransportRequestAgainResponse = TTransportRequestAgainSuccess | TTransportRequestAgainError;
export type TCustomerCancelTransportAfterCancelByRiderResponse = TCustomerCancelTransportAfterCancelByRiderSuccess | TCustomerCancelTransportAfterCancelByRiderError;
export type TCustomerTransportHistoryResponse = TCustomerTransportHistorySuccess | TCustomerTransportHistoryError;
export type TEmailTransportReceiptResponse = TEmailTransportReceiptSuccess | TEmailTransportReceiptError;
export type TAddFavouritePlacesResponse = TAddFavouritePlacesSuccess | TAddFavouritePlacesError;
export type TRemoveFavouritePlacesResponse = TRemoveFavouritePlacesSuccess | TRemoveFavouritePlacesError;
export type TGetFavouritePlacesResponse = TGetFavouritePlacesSuccess | TGetFavouritePlacesError;
export type TCheckCustomerLoginPermissionResponse = TCheckCustomerLoginPermissionSuccess | TCheckCustomerLoginPermissionError;
export type TUpdateCustomerFcmTokenResponse = TUpdateCustomerFcmTokenSuccess | TUpdateCustomerFcmTokenError;
export type TGetCustomerAppStateResponse = TGetCustomerAppStateSuccess | TGetCustomerAppStateError;
export type TChooseAnotherPaymentCardResponse = TChooseAnotherPaymentCardSuccess | TChooseAnotherPaymentCardError;
export type TCustomerCancelTransportBeforPaymentResponse = TCustomerCancelTransportBeforPaymentSuccess | TCustomerCancelTransportBeforPaymentError;
export type TCustomerSkipRatingResponse = TCustomerSkipRatingSuccess | TCustomerSkipRatingError;
export type TCustomerUpdateProfileResponse = TCustomerUpdateProfileSuccess | TCustomerUpdateProfileError;

export type TBearerAcceptTransport = {
    type: 'transport_accepted',
    state_description: string,
    transport_id: string,
    bearer_type: string,
    rider_given_name: string,
    rider_last_name: string,
    rider_avatar_url: string,
    motorbike_make?: string,
    motorbike_model?: string,
    motorbike_registration_number?: string,
    motorbike_registration_state?: string,
    origin_address: string,
    origin_lat: string,
    origin_lng: string,
    destination_address: string,
    destination_lat: string,
    destination_lng: string,
    order_secret: string,
    cancellation_fee: string,
}
export type NoCloudFunctionData = {
    text: string, buttonAction: string, url: string, supportUrl: string,

}
export type NoWebAppData = {
    text: string, buttonAction: string, url: string, supportUrl: string,

}
export type WebAppRemoteConfigParameters = {
    supportUrl: string, legal: string, privacyPolicy: string, supportNumber: string, version: string, landingPageUrl: string,
    stripeKey: string,
    businessCustomerLegalUrl: string, // businessCustomerLegalUrl added by Bearer Web Developer
    businessCustomerOnboardingUrl: string, // businessCustomerOnboardingUrl added by Bearer Web Developer
    whatsappLiveSupportNumber: string, // whatsappLiveSupportNumber added by Bearer Web Developer
    apiDevDocumentsLandingpageUrl: string, // added by Bearer Web Developer
    apiAccessTcUrl: string, // added by Bearer Web Developer
    bearerGoogleReviewUrl: string, // added by Bearer Web Developer
    klaviyoApiPublicKey: string, // added by Bearer Web Developer
    klaviyoApiPrivateKey: string, // added by Bearer Web Developer
    realTimeParcelTrackingUrl: string, // added by Bearer Web Developer
}

export const fetchDownloadUrl = async (url: string): Promise<string> => {
    try {
        if (!url) return ''; // added by Bearer Web Developer
        const downloadUrl = await getDownloadURL(ref(storage, url))
        return downloadUrl;
    } catch (err) {
        console.error(err); // modified by Bearer Web Developer
        return url;
    }
};

export const BearerApi = {
    appState: null as (IAppState | null),
    registerState: async (appState: IAppState) => {
        BearerApi.appState = appState;
        BearerApi.registerForMessages();
    },
    registerForMessages: async () => {
        if (!BearerApi.appState?.login.loggedin) {
            return;
        }
        if (!BearerApi.appState.login.uid) {
            error('the user is logged in but there is not uid!!'); // modify by Bearer Web Developer
            return;
        }
        try {
            const token = await BearerApi.updateMessageToken();

        } catch (err) {
            error(err); // modify by Bearer Web Developer
        }

    },
    callApiWithAppCheck: () => {
        try {
            const appCheck = initializeAppCheck(firebaseApp, {
                provider: new ReCaptchaV3Provider(process.env.REACT_APP_AppCheckKey || 'APP_CHECK_KEY_IS_MISSING'),
                isTokenAutoRefreshEnabled: true
            });
            return appCheck;
        } catch (err) {
            error(err); // modify by Bearer Web Developer
        }
    },
    pricing: async (data: TPricingRequest): Promise<TPricingResponse | void> => { // void type added by Bearer Web Developer
        try {
            const f = httpsCallable<TPricingRequest, TPricingResponse>(functions, 'pricing');
            const result = await f(data);
            if (result.data.code === 200 || result.data.code === 201) {
                if (result.data.walking?.overview_polyline) {
                    result.data.walking.overview_polyline_latlng = decodePolyline(result.data.walking.overview_polyline);
                }
                if (result.data.riding?.overview_polyline) {
                    result.data.riding.overview_polyline_latlng = decodePolyline(result.data.riding.overview_polyline);
                }
                if (result.data.cycling?.overview_polyline) {
                    result.data.cycling.overview_polyline_latlng = decodePolyline(result.data.cycling.overview_polyline);
                }
            }
            return result.data;
        } catch (err: any) {
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    fetchParcelTypes: async (): Promise<ParcelType[] | void> => { // void type added by Bearer Web Developer
        try {
            const result = await getDocs(collection(firestore, "bearerParcels"))
            const parcelTypes = result.docs.map(p => p.data()) as ParcelType[];
            await Promise.all(parcelTypes.map(async (parcelType) => {
                parcelType.parcel_img_url = await fetchDownloadUrl(parcelType.parcel_img_url);
            }));
            return parcelTypes;
        } catch (err: any) {
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer 
        }
    },
    postSetting: async (data: TSetting): Promise<TSettingResponse | void> => { // void type added by Bearer Web Developer
        try {
            const f = httpsCallable<TSetting, TSettingResponse>(functions, 'businessCustomerUpdateSettings');
            const result = await f(data);
            return result.data;
        } catch (err: any) {
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    login: async (email: string, password: string): Promise<TLoginResponse | undefined> => {
        try {
            const result = await signInWithEmailAndPassword(auth, email, password);
            if (result.user) {
                const user: TLoginResponse = {
                    uid: result.user.uid,
                };
                return user;
            } else {
                // to do
                error('please complete  the login function in bearer_api.tsx, there is no user in result'); // modify by Bearer Web Developer
            }
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
            throw err // added by Bearer Web Developer
        }
    },
    logout: async () => {
        try {
            await signOut(auth);
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer

        }
    },

    transport: async (data: TTransportRequest): Promise<TTransportResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TTransportRequest, TTransportResponse>(functions, 'requestForTransport');
            const result = await t(data);

            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    discount: async (data: TDiscountRequest): Promise<TDiscountResponse | void> => { // void type added by Bearer Web Developer
        try {
            const d = httpsCallable<TDiscountRequest, TDiscountResponse>(functions, 'checkDiscountCode');
            const result = await d(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    getCard: async (): Promise<TGetCardResponse | void> => { // void type added by Bearer Web Developer
        try {
            const g = httpsCallable<void, TGetCardResponse>(functions, 'getStripeCards');
            const result = await g();
            return result.data;
        } catch (err: any) {
            log(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },


    addCard: async (data: TCardToken): Promise<TAddCardResponse | void> => { // void type added by Bearer Web Developer
        try {
            const a = httpsCallable<TCardToken, TAddCardResponse>(functions, 'addStripeCard');
            const result = await a(data);
            return result.data;

        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    removeCard: async (data: TCardToken): Promise<TRemoveCardResponse | void> => { // void type added by Bearer Web Developer
        try {
            const r = httpsCallable<TCardToken, TRemoveCardResponse>(functions, 'removeStripeCard');
            const result = await r(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    updateAgreedAppVersion: async (): Promise<TUpdateAppResponse | void> => { // void type added by Bearer Web Developer
        try {
            const version = getValue(remoteConfig, "latest_tc_pp_version_business_customers").asString()
            // added by Bearer Web Developer start
            // const publicIp = require("react-public-ip");
            // const ipd = await publicIp.v4() || "";
            // const response = await fetch('https://geolocation-db.com/json/');
            // const Ipdata = await response.json();
            // const ip = Ipdata?.IPv4 || "";
            // log("ip", ip);
            const response = await fetch('https://jsonip.com');
            const Ipdata = await response.json();
            const ip = Ipdata?.ip || "";
            log("ip", ip);
            if (ip === undefined || ip === "" || ip === null) {
                return { ipAlertMessage: true };
            }
            // added by Bearer Web Developer end
            const data: TUpdateApp = {
                bearer_type: "customer",
                version,
                ip,
            }
            const u = httpsCallable<TUpdateApp, TUpdateAppResponse>(functions, 'tcPpVersionUpdate');
            const result = await u(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    isLoaded: (): boolean => {
        return !!auth.currentUser;
    },
    // modify by Bearer Web Developer start (add uid parameter)
    onLoad: (handleLoad: (uid: string) => void): void => {
        onAuthStateChanged(auth, (user: any) => {  // added (user: any) parameter by Bearer Web Developer
            // added by Bearer Web Developer start
            // Firebase Analytics Set user ID
            log({ user }) // added by Bearer Web Developer
            setUserId(analytics, user ? user?.uid?.slice(-7) : "Anonymous");
            // added by Bearer Web Developer end
            handleLoad(user?.uid || ''); // modify by Bearer Web Developer end (add uid argument)
        })
    },
    // modify by Bearer Web Developer end (add uid parameter)
    isAgreementNeeded: async (latest_tc_pp_vers_signed: string): Promise<boolean> => {

        try {
            await BearerApi.fetchRemoteConfigurations();
            const version = getValue(remoteConfig, "latest_tc_pp_version_business_customers").asString();
            // console.log({ version, latest_tc_pp_vers_signed: BearerApi.appState?.profile.latest_tc_pp_vers_signed }); // added by Bearer Web Developer
            // the following condition is modified by Bearer Web Developer
            if (BearerApi.appState?.profile.latest_tc_pp_vers_signed !== version && BearerApi.appState?.profile.latest_tc_pp_vers_signed != null && version != null) {
                return true;
            }
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
        return false;
    },

    bearerCloudFunctionIsRunning: (): NoCloudFunctionData | null => {
        const cloudFunctionsIsRunning = getValue(remoteConfig, "bearer_cloudfunctions_is_running").asBoolean();
        // const key = getValue(remoteConfig, "stripe_publishable_key").asString();
        if (cloudFunctionsIsRunning) return null;
        const text = getValue(remoteConfig, "bearer_cloudfunctions_status_message_text").asString();
        const buttonAction = getValue(remoteConfig, "bearer_cloudfunctions_status_message_button_action").asString();
        const url = getValue(remoteConfig, "bearer_cloudfunctions_action_url").asString();
        const supportUrl = getValue(remoteConfig, "business_customer_support_webpage_url").asString();
        return {
            text, buttonAction, url, supportUrl,
        };
    },

    bearerWebAppIsRunning: (): NoWebAppData | null => {
        const webAppIsRunning = getValue(remoteConfig, "bearer_customer_web_app_is_running").asBoolean();
        if (webAppIsRunning) return null;
        const text = getValue(remoteConfig, "bearer_customer_web_app_status_message_text").asString();
        const buttonAction = getValue(remoteConfig, "bearer_customer_web_app_status_message_button_action").asString();
        const url = getValue(remoteConfig, "bearer_customer_web_app_action_url").asString();
        const supportUrl = getValue(remoteConfig, "business_customer_support_webpage_url").asString();
        return {
            text, buttonAction, url, supportUrl,
        };
    },

    bearerWebAppRemoteConfigParameters: (): WebAppRemoteConfigParameters | null => {
        const supportUrl = getValue(remoteConfig, "business_customer_support_webpage_url").asString();
        const legal = getValue(remoteConfig, "business_customer_tc_url").asString();
        const privacyPolicy = getValue(remoteConfig, "business_customer_pp_url").asString();
        const supportNumber = getValue(remoteConfig, "business_customer_support_number").asString();
        const version = getValue(remoteConfig, "latest_tc_pp_version_business_customers").asString();
        const landingPageUrl = getValue(remoteConfig, "business_customer_landingpage_url").asString();
        const stripeKey = getValue(remoteConfig, "stripe_publishable_key").asString();
        const businessCustomerLegalUrl = getValue(remoteConfig, "business_customer_legal_url").asString(); // added by Bearer Web Developer
        const businessCustomerOnboardingUrl = getValue(remoteConfig, "business_customer_onboarding_url").asString(); // added by Bearer Web Developer
        const whatsappLiveSupportNumber = getValue(remoteConfig, "whatsapp_live_support_number").asString(); // added by Bearer Web Developer
        const apiDevDocumentsLandingpageUrl = getValue(remoteConfig, "api_dev_documents_landingpage_url").asString(); // added by Bearer Web Developer
        const apiAccessTcUrl = getValue(remoteConfig, "api_access_tc_url").asString(); // added by Bearer Web Developer
        const bearerGoogleReviewUrl = getValue(remoteConfig, "bearer_google_review_url").asString(); // added by Bearer Web Developer
        const klaviyoApiPublicKey = getValue(remoteConfig, "klaviyo_api_public_key").asString(); // added by Bearer Web Developer
        const klaviyoApiPrivateKey = getValue(remoteConfig, "klaviyo_api_private_key").asString(); // added by Bearer Web Developer
        const realTimeParcelTrackingUrl = getValue(remoteConfig, "real_time_parcel_tracking_url").asString(); // added by Bearer Web Developer

        return {
            supportUrl, legal, privacyPolicy, supportNumber, version, landingPageUrl, stripeKey, businessCustomerLegalUrl, businessCustomerOnboardingUrl,  // businessCustomerLegalUrl and businessCustomerOnboardingUrl added by Bearer Web Developer
            whatsappLiveSupportNumber, // added by Bearer Web Developer 
            apiDevDocumentsLandingpageUrl, // added by Bearer Web Developer 
            apiAccessTcUrl, // added by Bearer Web Developer 
            bearerGoogleReviewUrl, // added by Bearer Web Developer 
            klaviyoApiPublicKey, // added by Bearer Web Developer 
            klaviyoApiPrivateKey, // added by Bearer Web Developer 
            realTimeParcelTrackingUrl, // added by Bearer Web Developer 
        };
    },
    paymentAuthorization: async (data: TPaymentAuthorizationRequest): Promise<TPaymentAuthorizationResponse | void> => { // void type added by Bearer Web Developer
        try {
            // for testing -------------------------------
            // if (true) {
            //     return {
            //         code: 420,
            //         message: 'error',
            //         status: 'error',
            //         paymentStatus: "message",
            //     }
            // }
            // -------------------------------
            const t = httpsCallable<TPaymentAuthorizationRequest, TPaymentAuthorizationResponse>(functions, 'paymentAuthorization');
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    searchRiderByRealtime: async (data: TSearchRiderRequest): Promise<TSearchRiderResponse | void> => { // void type added by Bearer Web Developer
        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         const sampleRiderInfo: TSearchRiderResponse = {
        //             status: "Successful",
        //             code: 200,
        //             message: "This Transport Accepted",
        //             bearer_type: "cycling",
        //             cancellation_fee: 6,
        //             order_secret: '324811',
        //             origin_address: "Tehran Province, Tehran, Iran",
        //             origin_lat: 35.8069729,
        //             origin_lng: 51.4289075,
        //             destination_address: "Tehran Province, Tehran, Iran",
        //             destination_lat: 35.8069729,
        //             destination_lng: 51.4289075,
        //             rider_avatar_url: "RidersAvatar/Y8DhL8buxKU74Jps4sXpBWfY.JPEG",
        //             rider_given_name: "sara",
        //             rider_last_name: "json",
        //             motorbike_make: "HONDA",
        //             motorbike_model: "CG125",
        //             motorbike_registration_number: "ABCDE",
        //             motorbike_registration_state: "string",
        //         };
        //         resolve(sampleRiderInfo);
        //     }, 3000);
        // })
        try {
            const functions = getFunctions(firebaseApp, 'australia-southeast2');
            const t = httpsCallable<TSearchRiderRequest, TSearchRiderResponse>(functions, 'searchRiderByRealtimeSecondGen', { timeout: 180000 });
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    // DetachTransportState added by Bearer Web Developer start
    detachTransportState: async (orderState: IOrderState) => {
        const transportId = orderState.transport.value!.transport_id;
        const dbRef = databaseRef(database, 'messages');
        off(child(dbRef, `${transportId}/transport_state`));
    },
    // DetachTransportState added by Bearer Web Developer end
    registerForTransportState: async (orderState: IOrderState) => {
        const transportId = orderState.transport.value!.transport_id;
        const dbRef = databaseRef(database, 'messages');
        off(child(dbRef, `${transportId}/transport_state`));
        onValue(child(dbRef, `${transportId}/transport_state`), (snap) => {
            const value: TTransportStateResponse = snap.val();
            // console.log({ transport_state: value }) // added by Bearer Web Developer
            value && orderState.searchRider.searchResponse(value);
        })
    },
    // trackingOnline:async()=>{
    //     const dbRef = databaseRef(database, 'messages');
    // },
    loadTransportStateFromFirebase: async (orderState: IOrderState) => {
        try {
            // commented by Bearer Web Developer start
            // const transportId = orderState.transport.value!.transport_id;
            // const dbRef = databaseRef(database, 'messages');
            // const snap = await get(child(dbRef, `${transportId}/transport_state`));
            // const value = snap.val();
            // console.log({ transport_state_on_load: value }) // added by Bearer Web Developer
            // log({ transport_state: value })
            // value && orderState.searchRider.searchResponse(value)
            // commented by Bearer Web Developer end
            BearerApi.registerForTransportState(orderState) // modify by Bearer Web Developer (before code: !value && BearerApi.registerForTransportState(orderState))
        } catch (err) {
            error(err); // modify by Bearer Web Developer
        }
    },
    CustomerCancelTransportBeforeAccept: async (data: TCancelTransportBeforeAcceptRequest): Promise<TCancelTransportBeforeAcceptResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TCancelTransportBeforeAcceptRequest, TCancelTransportBeforeAcceptResponse>(functions, 'customerCancelTransportBeforAccept');
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    sendMessage: async (message: string, senderId: string, transportId: string, uniqueID: string, timestamp: number, user_type: string) => { // user_type added by Bearer Web Developer
        try {
            const dbRef = databaseRef(database, 'messages');
            await set(child(dbRef, `${transportId}/chats/${uniqueID}`), { message, sender_id: senderId, timestamp, user_type }) // user_type added by Bearer Web Developer
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    processChatMessage: (msg: any, orderState: IOrderState) => {
        // added by Bearer Web Developer start
        if (orderState.chat?.messages?.length === 0 && msg?.user_type === "customer") {
            // Firebase Analytics Event Name Chat Started
            // App Event First Message Sent by User
            logEvent(analytics, "chat_started_by_customer");
        }
        // added by Bearer Web Developer end
        const index = orderState.chat.messages.findIndex(old_msg => old_msg.id === msg.key);
        if (index === -1) {
            if (msg.message && msg.sender_id && msg.timestamp && msg.user_type) {
                const message: IMessageStateSnapshot = {
                    message: msg.message,
                    id: msg.key,
                    sender_id: msg.sender_id,
                    timestamp: msg.timestamp,
                    user_type: msg.user_type,
                };
                orderState.chat.receiveMessage(message);
                // TODO save received in firebase
            }
        } else {
            const message = orderState.chat.messages[index];
            message.setSent(true);
            if (msg.received) {
                message.setReceived(true);
            }
        }

    },
    registerForChat: (orderState: IOrderState, transportId: string) => {
        const dbRef = databaseRef(database, 'messages');
        off(child(dbRef, `${transportId}/chats`));
        onValue(child(dbRef, `${transportId}/chats`), (snap) => {
            const value = snap.val();
            const messages = convertObjectToArray(value).sort((a, b) => a.timestamp - b.timestamp);
            messages.forEach(m => {
                BearerApi.processChatMessage(m, orderState);
            })
        });
    },
    loadChatMessagesFromFirebase: async (orderState: IOrderState, transportId: string,) => {
        try {
            const dbRef = databaseRef(database, 'messages')
            const snap = await get(child(dbRef, `${transportId}/chats`))
            const value = snap.val();
            const messages = convertObjectToArray(value).sort((a, b) => a.timestamp - b.timestamp);
            messages.forEach(m => {
                BearerApi.processChatMessage(m, orderState);
            })
        } catch (err) {
            error(err); // modify by Bearer Web Developer
        }
    },
    // riderLocation added by Bearer Web Developer start
    riderLocation: async (orderState: IOrderState, transportId: string,) => {
        try {
            const dbRef = databaseRef(database, 'messages');
            off(child(dbRef, `${transportId}/rider_location`));
            onValue(child(dbRef, `${transportId}/rider_location`), (snap) => {
                const value = snap.val();

                if (value?.lat && value?.lng) {

                    orderState.rider.setRiderLocation(value)

                }
            });

        } catch (err) {
            error(err); // modify by Bearer Web Developer
        }
    },
    // riderLocation added by Bearer Web Developer end
    // userStatus added by Bearer Web Developer start
    userStatus: async (uid: string) => {

        try {
            const dbRef = databaseRef(database, 'userStatus');
            off(child(dbRef, `${uid}`));
            onValue(child(dbRef, `${uid}`), (snap) => {
                const value = snap.val();

                if (value?.status) {

                    BearerApi.appState?.userStatus.setUserState(value);
                    if (value?.status !== "active") {
                        BearerApi.appState?.setSnackMessage({
                            message: "User logged out!",
                            handleClick: () => {
                                BearerApi.appState?.login.logout();
                                window.location.reload();
                            }
                        })
                    }
                }

            });

        } catch (err) {
            error(err); // modify by Bearer Web Developer
        }
    },
    // userStatus added by Bearer Web Developer end
    // getEnvironmentCode added by Bearer Web Developer start
    getEnvironmentCode: () => {
        const environmentCode = getValue(remoteConfig, "environment_code").asString();
        log({ environmentCode });
        return environmentCode;
    },
    // getEnvironmentCode added by Bearer Web Developer end
    customerCancelTransportAfterAccept: async (data: TcustomerCancelTransportAfterAcceptRequest): Promise<TCustomerCancelTransportAfterAcceptResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TcustomerCancelTransportAfterAcceptRequest, TCustomerCancelTransportAfterAcceptResponse>(functions, 'customerCancelTransportAfterAccept');
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    customerRateTransport: async (data: TCustomerRateTransportRequest): Promise<TCustomerRateTransportResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TCustomerRateTransportRequest, TCustomerRateTransportResponse>(functions, 'customerRateTransport');
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    updateMessageToken: async (): Promise<string | void> => { // void type added by Bearer Web Developer
        try {
            // modify by Bearer Web Developer start
            const supported = await isSupported();
            log({ supported });
            if (supported) {
                const messaging = getMessaging(firebaseApp);
                const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VapIdKey });
                return token;
            }
            // modify by Bearer Web Developer end
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    transportRequestAgain: async (data: TTransportRequestAgainRequest): Promise<TTransportRequestAgainResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TTransportRequestAgainRequest, TTransportRequestAgainResponse>(functions, 'transportRequestAgain');
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    customerCancelTransportAfterCancelByRider: async (data: TCustomerCancelTransportAfterCancelByRiderRequest): Promise<TCustomerCancelTransportAfterCancelByRiderResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TCustomerCancelTransportAfterCancelByRiderRequest, TCustomerCancelTransportAfterCancelByRiderResponse>(functions, 'customerCancelTransportAfterCancelByRider');
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    customerTransportHistory: async (cursor_transport_id: string | null): Promise<TCustomerTransportHistoryResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<any, TCustomerTransportHistoryResponse>(functions, 'customerTransportHistory');
            const result = await t({ cursor_transport_id });
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer 
        }
    },

    emailTransportReceipt: async (data: TEmailTransportReceiptRequest): Promise<TEmailTransportReceiptResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TEmailTransportReceiptRequest, TEmailTransportReceiptResponse>(functions, 'emailTransportReceipt');
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    addFavouritePlaces: async (data: TAddFavouritePlacesRequest): Promise<TAddFavouritePlacesResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TAddFavouritePlacesRequest, TAddFavouritePlacesResponse>(functions, 'addFavouritePlaces');
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },


    removeFavouritePlaces: async (data: TRemoveFavouritePlacesRequest): Promise<TRemoveFavouritePlacesResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TRemoveFavouritePlacesRequest, TRemoveFavouritePlacesResponse>(functions, 'removeFavouritePlaces');
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    getFavouritePlaces: async (): Promise<TGetFavouritePlacesResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<void, TGetFavouritePlacesResponse>(functions, 'getFavouritePlaces');
            const result = await t();
            // console.log({ getFavouritePlaces: result }); // added by Bearer Web Developer
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    checkCustomerLoginPermission: async (): Promise<TCheckCustomerLoginPermissionResponse | void> => { // void type added by Bearer Web Developer
        try {
            // added by Bearer Web Developer start
            // add the location string as you call getFunctions
            const functions = getFunctions(firebaseApp, 'australia-southeast2');
            // added by Bearer Web Developer end
            // modify the following function name from checkCustomerLoginPermission to checkCustomerLoginPermissionSecondGen by Bearer Web Developer
            const t = httpsCallable<any, TCheckCustomerLoginPermissionResponse>(functions, 'checkCustomerLoginPermissionSecondGen');
            const result = await t({ platform_type: 'web' });
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    updateCustomerFcmToken: async (): Promise<TUpdateCustomerFcmTokenResponse | void> => { // void type added by Bearer Web Developer
        try {
            // added by Bearer Web Developer start
            // add the location string as you call getFunctions
            const functions = getFunctions(firebaseApp, 'australia-southeast2');
            // added by Bearer Web Developer end
            // modify by Bearer Web Developer start
            const supported = await isSupported();
            log({ supported });
            if (supported) {
                const messaging = getMessaging(firebaseApp);
                const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VapIdKey });
                const fcm_token = token;
                const fpPromise = FingerprintJS.load();
                const fp = await fpPromise
                const key = await fp.get()
                const unique_device_id = key.visitorId;
                // console.log({ updateCustomerFcmTokenData: { platform_type: 'web', fcm_token, unique_device_id }}) // added by Bearer Web Developer
                // modify the following function name from updateCustomerFcmToken to updateCustomerFcmTokenSecondGen by Bearer Web Developer
                const t = httpsCallable<{ platform_type: 'web', fcm_token: string, unique_device_id: string }, TUpdateCustomerFcmTokenResponse>(functions, 'updateCustomerFcmTokenSecondGen');
                const result = await t({ platform_type: 'web', fcm_token, unique_device_id });
                return result.data;
            } else {
                // modify the following function name from updateCustomerFcmToken to updateCustomerFcmTokenSecondGen by Bearer Web Developer
                const t = httpsCallable<{ platform_type: 'web', fcm_token: string, unique_device_id: string }, TUpdateCustomerFcmTokenResponse>(functions, 'updateCustomerFcmTokenSecondGen');
                const result = await t({ platform_type: 'web', fcm_token: '', unique_device_id: '' });
                return result?.data;
            }
            // modify by Bearer Web Developer end
        } catch (err: any) {
            // console.log({ updateCustomerFcmTokenError: err }); // added by Bearer Web Developer
            error(err); // modify by Bearer Web Developer 
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    getCustomerAppState: async (data: TGetCustomerAppStateRequest): Promise<TGetCustomerAppStateResponse | void> => { // void type added by Bearer Web Developer
        try {
            // added by Bearer Web Developer start
            // add the location string as you call getFunctions
            const functions = getFunctions(firebaseApp, 'australia-southeast2');
            // added by Bearer Web Developer end
            // modify the following function name from getCustomerAppState to getCustomerAppStateSecondGen by Bearer Web Developer
            const t = httpsCallable<TGetCustomerAppStateRequest, TGetCustomerAppStateResponse>(functions, 'getCustomerAppStateSecondGen');
            const result = await t(data);
            return result.data;

        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    chooseAnotherPaymentCard: async (data: TChooseAnotherPaymentCardRequest): Promise<TChooseAnotherPaymentCardResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TChooseAnotherPaymentCardRequest, TChooseAnotherPaymentCardResponse>(functions, 'chooseAnotherPaymentCard');
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    customerCancelTransportBeforePayment: async (data: TCustomerCancelTransportBeforPaymentRequest): Promise<TCustomerCancelTransportBeforPaymentResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TCustomerCancelTransportBeforPaymentRequest, TCustomerCancelTransportBeforPaymentResponse>(functions, 'customerCancelTransportBeforPayment');
            const result = await t(data);
            return result.data;

        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    customerSkipRating: async (data: TCustomerSkipRatingRequest): Promise<TCustomerSkipRatingResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TCustomerSkipRatingRequest, TCustomerSkipRatingResponse>(functions, 'customerSkipRating');
            const result = await t(data);
            return result.data;

        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    customerWebAppUpdateProfile: async (data: TCustomerUpdateProfileRequest): Promise<TCustomerUpdateProfileResponse | void> => { // void type added by Bearer Web Developer
        try {
            const t = httpsCallable<TCustomerUpdateProfileRequest, TCustomerUpdateProfileResponse>(functions, 'businessCustomerAppUpdateProfile');
            const result = await t(data);
            return result.data;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },

    uploadProfileImage: async (uid: string, image: Blob) => {
        try {
            // const imageRef = ref(storage, 'images/mountains.jpg');
            const imageRef = ref(storage, `customerUserAvatars/${uid}/${uid}.JPEG`);
            const r = await uploadBytesResumable(imageRef, image);
            return r.metadata.fullPath;
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    deleteProfileImage: async (uid: string,) => {
        try {
            const imageRef = ref(storage, `customerUserAvatars/${uid}/${uid}.JPEG`);
            await deleteObject(imageRef)
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    fetchRemoteConfigurations: async () => {
        try {
            await fetchAndActivate(remoteConfig)
            //  detect Private Browsing or Incagnito mode of browser added by Bearer Web Developer start
            detectIncognito().then((result) => {

                if (result.isPrivate) {
                    const appState = BearerApi.appState;
                    appState?.setErrorMessage({
                        message: 'We don’t support Private Browsing feature',
                        error: MessageEnum.Error401
                    });
                }
            });
            //  detect Private Browsing or Incagnito mode of browser added by Bearer Web Developer end
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
    resetPassword: async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email);
        } catch (err: any) {
            error(err); // modify by Bearer Web Developer
            error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer
        }
    },
}

export type TBearerApi = (typeof BearerApi);


export function decodePolyline(encoded: string): Location[] {
    const latlng = polyUtil.decode(encoded);

    const p_point = latlng.map((element: any) => ({ lat: element[0], lng: element[1] }));
    return p_point;
}

export function convertObjectToArray(value: any): any[] {
    const a = [];
    if (value) {
        for (let key in value) {
            if (value.hasOwnProperty(key)) {
                const msg = value[key];
                a.push({ ...msg, key });
            }
        }
    }
    return a;
}
