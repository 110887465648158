import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import { TransportTypeEnum, useAppState } from "../../states/app-state";
import { TTransportsHistoryInfo } from "../../api/bearer-api";
import TransportHistoryDetail from "./TransportHistoryDetail";
import TransportTypeImage from "../transport/TransportTypeImage";
import styles from './TransportHistoriesPopup.module.scss'; // added by Bearer Web Developer
import { log } from "../../app/log"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

interface ITransportHistoriesPopupProps { open: boolean, handleClose: () => void }
const TransportHistoriesPopup: React.FC<ITransportHistoriesPopupProps> = observer(({ open, handleClose }) => {
    const appState = useAppState();
    const { transportsHistory, hasMore, fetchNextCustomerTransportHistory } = appState.transportHistory;
    const [loading, setLoading] = useState(true); // added by Bearer Web Developer
    // modify by Bearer Web Developer start
    const fetchNext = async (next: boolean) => {
        try {
            setLoading(true);
            await fetchNextCustomerTransportHistory({ next }); // add next property for this function to understand that the data which we want is first data or next data by Bearer Web Developer
            setLoading(false);
        } catch (error) {
            log(error)
            setLoading(false);
        }
    }
    // modify by Bearer Web Developer end
    React.useEffect(() => {
        // the following commented by Bearer Web Developer
        // if (open) fetchNextCustomerTransportHistory({ next: false }); // add next property for this function to understand that the data which we want is first data or next data by Bearer Web Developer
        if (open) fetchNext(false); // add next property for this function to understand that the data which we want is first data or next data by Bearer Web Developer

    }, [open]); // removed fetchNextCustomerTransportHistory and add open state by Bearer Web Developer
    // modified by Bearer Web Developer start
    return (
        <Dialog
            PaperProps={{ style: { borderRadius: "10px" } }}
            className={styles.root}
            open={open}
            onClose={handleClose}>
            <div className={styles.main}>
                <div className={styles.header}>
                    <div>
                        <h2>Order History</h2>
                    </div>
                    <Button className={styles.cancel} disableRipple={true} onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M9.79992 19.833L13.9999 15.633L18.1999 19.833L19.8333 18.1997L15.6333 13.9997L19.8333 9.79967L18.1999 8.16634L13.9999 12.3663L9.79992 8.16634L8.16659 9.79967L12.3666 13.9997L8.16659 18.1997L9.79992 19.833ZM13.9999 25.6663C12.386 25.6663 10.8694 25.3601 9.44992 24.7476C8.03047 24.1351 6.79575 23.3038 5.74575 22.2538C4.69575 21.2038 3.8645 19.9691 3.252 18.5497C2.6395 17.1302 2.33325 15.6136 2.33325 13.9997C2.33325 12.3858 2.6395 10.8691 3.252 9.44967C3.8645 8.03023 4.69575 6.79551 5.74575 5.74551C6.79575 4.69551 8.03047 3.86426 9.44992 3.25176C10.8694 2.63926 12.386 2.33301 13.9999 2.33301C15.6138 2.33301 17.1305 2.63926 18.5499 3.25176C19.9694 3.86426 21.2041 4.69551 22.2541 5.74551C23.3041 6.79551 24.1353 8.03023 24.7478 9.44967C25.3603 10.8691 25.6666 12.3858 25.6666 13.9997C25.6666 15.6136 25.3603 17.1302 24.7478 18.5497C24.1353 19.9691 23.3041 21.2038 22.2541 22.2538C21.2041 23.3038 19.9694 24.1351 18.5499 24.7476C17.1305 25.3601 15.6138 25.6663 13.9999 25.6663ZM13.9999 23.333C16.6055 23.333 18.8124 22.4288 20.6208 20.6205C22.4291 18.8122 23.3333 16.6052 23.3333 13.9997C23.3333 11.3941 22.4291 9.18717 20.6208 7.37884C18.8124 5.57051 16.6055 4.66634 13.9999 4.66634C11.3944 4.66634 9.18742 5.57051 7.37909 7.37884C5.57075 9.18717 4.66659 11.3941 4.66659 13.9997C4.66659 16.6052 5.57075 18.8122 7.37909 20.6205C9.18742 22.4288 11.3944 23.333 13.9999 23.333Z" fill="#858585" />
                        </svg>
                    </Button>
                </div>
                <div className={styles.transports_history}>
                    <div style={{ height: '400px', overflow: 'auto' }}>
                        {/* added by Bearer Web Developer for add loading progress (following line)*/}
                        {loading && <div className={styles.loading}><CircularProgress /></div>}
                        {/* !loading codition added by Bearer Web Developer for manage loading progress (following line)*/}
                        {!loading && transportsHistory.map((transportsHistoryOption, i) => <TransportHistory key={transportsHistoryOption.transport_id} transportsHistory={transportsHistoryOption} />)}
                        {/* {!transportsHistory && <CircularProgress />} */}
                    </div>
                    <div className={styles.load_more_items_block}>
                        {/* disabled={loading} added by Bearer Web Developer */}
                        {hasMore && <Button disabled={loading} className={styles.load_more_items} onClick={() => { fetchNext(true) }}>Load more items</Button>}
                    </div>
                    {/* {!hasMore && <Typography align="center">no item</Typography>} */}
                </div>
            </div>
        </Dialog>
    )
    // modified by Bearer Web Developer end
});
export default TransportHistoriesPopup;



const TransportHistory: React.FC<{ transportsHistory: TTransportsHistoryInfo }> = observer(({ transportsHistory }) => {
    // modified by Bearer Web Developer start
    const price = transportsHistory?.transport_original_price;
    const discounted = transportsHistory?.transport_original_price !== transportsHistory?.transport_receivable_price;
    const discountedPrice = transportsHistory?.transport_receivable_price; // added by Bearer Web Developer
    const showRealPrice = !(discountedPrice && (Number(discountedPrice) > Number(price))); // added by Bearer Web Developer
    // modified by Bearer Web Developer end
    const time = new Date(transportsHistory.transport_time._seconds * 1000); // modified by Bearer Web Developer
    const completedTransport = transportsHistory.transport_state === 'Completed';
    const canceledByCustomer = transportsHistory.transport_state === 'Canceled by Customer';
    const transportImage = Object.values(TransportTypeEnum).find(i => i === transportsHistory.assigned_bearer_type);
    // added by Bearer Web Developer start
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const openHandler = (panel: string) => {
        setExpanded(panel !== expanded ? panel : false);
    };
    // added by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return (

        <Accordion expanded={expanded === transportsHistory.transport_id} elevation={0}
            onChange={() => openHandler(transportsHistory.transport_id)}
            className={styles.accordion}>
            <AccordionSummary
                classes={{
                    content: styles.MuiAccordionSummary_content,
                    root: styles.MuiAccordionSummary_root,
                }}

            >
                <div className={styles.transport_main}>
                    <div className={styles.transport}>
                        <div className={styles.transport_info}>
                            <div className={styles.transport_image}>
                                <TransportTypeImage type={transportImage!} />
                            </div>

                            {
                                !discounted ?
                                    <div>
                                        <p className={styles.price}>A$ {price}</p>
                                        {completedTransport &&
                                            <p className={styles.receive_color} >{transportsHistory.transport_state}</p>
                                        }
                                        {canceledByCustomer &&
                                            <p className={styles.cancel_color}>{transportsHistory.transport_state}</p>
                                        }
                                    </div>
                                    :
                                    <div>
                                        {/* add showRealPrice condition by Bearer Web Developer start */}
                                        {showRealPrice && <p className={styles.price_main}>A$ {price}</p>}
                                        {
                                            transportsHistory?.transport_receivable_price === 0 ?
                                                <p className={styles.free}>Free!</p> :
                                                <p className={styles.price_discounted}>
                                                    A$ {transportsHistory?.transport_receivable_price}
                                                    {
                                                        !canceledByCustomer && showRealPrice &&
                                                        <span className={styles.discounted}>Discounted</span>
                                                    }
                                                </p>
                                        }
                                        {/* add showRealPrice condition by Bearer Web Developer end */}
                                        {completedTransport &&
                                            <p className={styles.receive_color} >{transportsHistory.transport_state}</p>
                                        }
                                        {canceledByCustomer &&
                                            <p className={styles.cancel_color}>{transportsHistory.transport_state}</p>
                                        }
                                    </div>
                            }

                        </div>
                        <div className={styles.time}>
                            <div>
                                <img src="calendar-month.png" alt="date" />
                                <span>{time.toLocaleDateString()}</span>
                            </div>
                            <div>
                                <img src="schedule.png" alt="clock" />
                                <span>{time.toLocaleTimeString()}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <AddressLine transportsHistory={transportsHistory} />
                    </div>
                    <div className={styles.more_data}>
                        <p><img src="/pin.svg" alt="" /> Transport Number : {transportsHistory.order_number}</p>
                        <div className={styles.more}>
                            {
                                !expanded &&
                                <Button onClick={() => { openHandler(transportsHistory.transport_id) }}>
                                    <img src="expand-more-bottom.png" alt="" />
                                    <span>More Details</span>
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails
                classes={{
                    root: styles.Mui_accordion_details_root
                }}>
                <TransportHistoryDetail transportsHistory={transportsHistory} />
            </AccordionDetails>
            <div className={styles.less}>
                {
                    expanded &&
                    <Button onClick={() => { setExpanded(false) }}>
                        <img src="expand-more-top.png" alt="" />
                        <span>Less Detail</span>
                    </Button>
                }
            </div>
        </Accordion>
    )
    // modified by Bearer Web Developer end
})




const AddressLine: React.FC<{ transportsHistory: TTransportsHistoryInfo }> = observer(({ transportsHistory }) => {
    // modified by Bearer Web Developer start
    return (
        <Timeline classes={{ root: styles.time_line }}>
            <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content }}>
                <TimelineSeparator>
                    <img src="/origin.png" alt="origin" />
                    <TimelineConnector className={''} />
                </TimelineSeparator>
                <TimelineContent className={''}>{transportsHistory.origin_address}</TimelineContent>
            </TimelineItem>
            <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content, root: styles.timelineItem_root }}>
                <TimelineSeparator >
                    <img src="/destination.png" alt="destination" />
                </TimelineSeparator>
                <TimelineContent className={''}>{transportsHistory.destination_address}</TimelineContent>
            </TimelineItem>
        </Timeline>
    )
    // modified by Bearer Web Developer end
})

