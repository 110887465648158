import React, { useState } from "react";
import { Observer, observer } from "mobx-react-lite";
import { analytics, fetchDownloadUrl, TRiderInfo, TTransportStateResponse } from "../../api/bearer-api";
import { IChatState, IMessageState, TransportStateEnum, useAppState } from "../../states/app-state";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import CancelOrderPopup from "./CancelOrderPopup";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import StateOfTransport from "./StateOfTransport";
import ShowImage from "../showImage/ShowImage";
import CancelByRider from "./CancelByRider";
import { IRiderState } from "../../states/riderState"; // added by Bearer Web Developer
import styles from './TransportProgress.module.scss'; // added by Bearer Web Developer
import ShowSteps from "./showSteps";
import BearerTextarea from "../../components/fields/BearerTextarea";
import { logEvent } from "firebase/analytics"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const TransportProgress: React.FC = observer(() => {

    const appState = useAppState();
    const order = appState.selectedOrder!;
    const riderInfo = order!.transport.riderInfo;
    const { step } = order.transport;
    const searchRider = order.searchRider;

    const [cancelOrder, setCancelOrder] = React.useState(false);
    const handleCancelOrder = () => {
        // added by Bearer Web Developer start
        // Firebase Analytics Event Name Order Cancel Tapped After Rider Acceptance
        // App Event Cancel Button while waiting for Rider to Arrive Clicked
        logEvent(analytics,"cancel_button_clicked_after_acceptance",{
            transport_id: appState.selectedOrder?.transport?.value?.transport_id,
        });
        // added by Bearer Web Developer end
        setCancelOrder(true);
    }
    const handleCancelOrderClose = () => {
        setCancelOrder(false);
    }
    React.useEffect(() => {
        appState.getWebAppRemoteConfigParameters();
    }, [appState]);

    const notCancelApplicable = order.isNotCancelApplicable;
    // commented by Bearer Web Developer start, move to LeftSidePanel parrent component cause of rerendering and multi realtime 
    // React.useEffect(() => {
    //     appState.selectedOrder?.searchRider.loadTransportState();
    // }, [appState, searchRider])
    // commented by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return (
        <>
            {step !== TransportStateEnum.RiderCancelledTransport &&
                <CancelOrderPopup open={cancelOrder} handleClose={handleCancelOrderClose} />
            }
            {step === TransportStateEnum.RiderCancelledTransport && <CancelByRider />}
            {riderInfo && step !== TransportStateEnum.RiderCancelledTransport &&
                <div className={styles.transport_info_container}>
                    <RiderInfo riderInfo={riderInfo} searchResponse={order.searchRider.SearchRiderResponse} />
                    {/* move StateOfTransport component in RiderInfo component by Bearer Web Developer*/}
                    <AddressInfo riderInfo={riderInfo} />
                    <ChatBox chat={order.chat} rider={order.rider} />
                    <div className={styles.transport_progress}>
                        {!notCancelApplicable && <Button onClick={handleCancelOrder} className={styles.cancel} >Cancel Order</Button>}
                        {notCancelApplicable &&
                            <div className={styles.call}>
                                <img src="/call.png" alt="" />
                                <span color="primary">Call Support : {appState.webAppRemoteConfigParameters?.supportNumber}</span>
                            </div>
                        }
                    </div>
                </div>}
        </>
    )
    // modified by Bearer Web Developer end
});
export default TransportProgress;

const RiderInfo: React.FC<{ riderInfo: TRiderInfo, searchResponse: TTransportStateResponse | null }> = observer(({ riderInfo, searchResponse }) => {
    const appState = useAppState(); // added by Bearer Web Developer
    const order = appState.selectedOrder!; // added by Bearer Web Developer
    const riding = riderInfo.bearer_type === "riding";
    const realtime_code = searchResponse?.realtime_code;
    // added by Bearer Web Developer start
    const realTimeParcelTrackingUrl = appState?.webAppRemoteConfigParameters?.realTimeParcelTrackingUrl;
    const order_number = appState?.selectedOrder?.transport.value?.order_number;
    const [copySectret, setCopySectret] = useState(false);
    const [copyOrder, setCopyOrder] = useState(false);
    function copyTextField(text : string | undefined) {
        if (text) {
            // Copy the text inside the text field
            navigator?.clipboard?.writeText(text);
            // Alert the copied text
            // console.log("Copied the text: " + copyText?.value);
        }
    }
    // added by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return (
        <>
            <div className={styles.rider_info}>
                <div className={styles.image}>
                    <div className={styles.image_box}>
                        <ShowImage fireBaseUrl={riderInfo.rider_avatar_url} />
                    </div>
                    <p>{riderInfo.rider_given_name} {riderInfo.rider_last_name}</p>
                </div>
                {riding &&
                    <div className={styles.type_riding}>
                        <p>{riderInfo.motorbike_make} {riderInfo.motorbike_model}</p>
                        <div className={styles.motorbike_registration}>
                            <h5> {riderInfo.motorbike_registration_number}</h5>
                            <h6>{riderInfo.motorbike_registration_state}</h6>
                        </div>
                    </div>
                }
                {!riding &&
                    <div className={styles.not_rider}>
                        <div className={''}>
                            {riderInfo.bearer_type === "walking" ? <img src="/directions_run_transport_info.png" alt="walking" />
                                : <img src="/pedal_bike_transport_info.png" alt="cycling" />
                            }
                        </div>
                        <h5>
                            {riderInfo.bearer_type.slice(0, 1).toLocaleUpperCase()
                                + riderInfo.bearer_type.slice(1).toLocaleLowerCase()}
                        </h5>
                    </div>
                }
            </div>
            {
                (realtime_code === 3 || realtime_code === 7 || realtime_code === 8
                    || realtime_code === 9 || realtime_code === 10)
                && <ShowSteps searchResponse={searchResponse} />
            }
            {order.transportMessage && <StateOfTransport transportMessage={order?.transportMessage} />}
            <div className={styles.secret_code}>
                <div className={styles.code}>
                    <p>Secret Code: </p> 
                    <div className={styles.code_data}>
                        {(riderInfo?.order_secret || '')?.toString().split("")?.map((item,index)=>(<span key={index}>{item}</span>))}
                    </div>
                </div>
                {/* modified by Bearer Web Developer: Tooltip for order_number added  start */}
                <Tooltip title={`${copySectret ? "Value Copied" : "Copy"}`} arrow={true} 
                    classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
                    onClose={()=>{ if (copySectret) setCopySectret(false); }}>
                    <div className={styles.copy} 
                        onClick={()=>{ 
                            copyTextField(`Bearer Secret Code is: ${riderInfo?.order_secret}`);
                            setCopySectret(true);
                        }}>
                        <img src="/copy-content.webp" alt="copy" width={24} height={24} />
                    </div>
                </Tooltip>
                {/* modified by Bearer Web Developer: Tooltip for order_number added end */}
            </div>
            {/* order_number added by Bearer Web Developer start */}
            <div className={styles.order_number}>
                <p>Order Number: {order_number}</p>
                <Tooltip title={`${copyOrder ? "Value Copied" : "Copy"}`} arrow={true}
                    classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
                    onClose={()=>{ if (copyOrder) setCopyOrder(false); }}>
                    <div className={styles.copy}
                        onClick={()=>{ 
                            copyTextField(`Track the delivery by ᵔᴥᵔ Bearer in real-time here: ${realTimeParcelTrackingUrl}/?ordernumber=${order_number}`);
                            setCopyOrder(true);
                        }}>
                        <img src="/copy-content.webp" alt="copy" width={24} height={24} />
                    </div>
                </Tooltip>
            </div>
            {/* order_number added by Bearer Web Developer end */}
        </>
    )
    // modified by Bearer Web Developer end

})

const AddressInfo: React.FC<{ riderInfo: TRiderInfo }> = observer(({ riderInfo }) => {
    return (
        <AddressLine />
    )
})

const ChatBox: React.FC<{ chat: IChatState, rider: IRiderState }> = observer(({ chat, rider }) => { // added rider by Bearer Web Developer

    React.useEffect(() => {
        chat.register();
        rider.loadRiderLocation();
    }, [chat]);
    // modified by Bearer Web Developer start
    return (
        <div className={styles.chat_box}>
            <MessageBox chat={chat} />
            <TypeBox chat={chat} />
        </div>
    )
    // modified by Bearer Web Developer end
})

const MessageBox: React.FC<{ chat: IChatState }> = observer(({ chat }) => {

    const scrollRef = React.useRef<HTMLDivElement | null>(null);

    const scrollToEnd = () => {
        if (scrollRef.current) {
            const top = scrollRef.current.scrollHeight;
            scrollRef.current.scrollTo({ top, behavior: 'smooth' });
        }
    }

    React.useEffect(() => {
        scrollToEnd();
    });
    // added by Bearer Web Developer start
    const [loading, setLoading] = useState(true);
    const [profileImage, setProfileImage] = useState('');
    const [riderImage, setRiderImage] = useState('');
    const appState = useAppState();
    const riderInfo = appState?.selectedOrder?.transport.riderInfo;
    const rider_name = `${riderInfo?.rider_given_name} ${riderInfo?.rider_last_name}`;
    const profile_avatar = appState.profile.avatar || '';
    const rider_avatar = riderInfo?.rider_avatar_url || '';
    const getAvatars = async () => {
        const profile_avatar_url = await fetchDownloadUrl(profile_avatar);
        const rider_avatar_url = await fetchDownloadUrl(rider_avatar);
        if (profile_avatar_url) setProfileImage(profile_avatar_url);
        if (rider_avatar_url) setRiderImage(rider_avatar_url);
        setLoading(false);
    }
    React.useEffect( () => {
        getAvatars();
    },[profile_avatar,rider_avatar]);
    if (loading) return <div className={styles.loading}><CircularProgress /></div> 
    // added by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return (
        <div ref={scrollRef} className={styles.message_box}>
            <div>
                {chat.messages.map(message => {
                    if (message.isMyMessage) {
                        return <MyMessage key={message.id} message={message} image={profileImage}  /> // image added by Bearer Web Developer
                    } else if (message.isRiderMessage) {
                        return <RiderMessage key={message.id} message={message} image={riderImage} name={rider_name} /> // image, name added by Bearer Web Developer
                    } else if (message.isAdminMessage) {
                        return <AdminMessage key={message.id} message={message} /> // AdminMessage added by Bearer Web Developer
                    }
                    return <OtherMessage key={message.id} />
                })}
            </div>
        </div>

    )
    // modified by Bearer Web Developer end
})
// modified by Bearer Web Developer (image added) start
const MyMessage: React.FC<{ message: IMessageState,image: string | undefined }> = ({ message, image }) => {
    // modified by Bearer Web Developer start
    return (
        <div className={styles.my_message_box_part}>
            <div className={styles.my_message_box}>
                <div className={styles.my_message} style={{ whiteSpace: "pre-line" }}>
                    <p>{message.message}</p>
                    <p className={styles.my_message_time}>{new Date(message.timestamp).toLocaleTimeString()}</p>
                </div>
            </div>
            <div className={styles.arrow}></div>
            <div className={styles.image_part}>
                <div className={styles.image}>
                    <img src={image || "/base-avatar.webp"} alt="" width={32} height={32} />
                </div>
            </div>
        </div>
    )
    // modified by Bearer Web Developer end
}
// modified by Bearer Web Developer (image added) end
// modified by Bearer Web Developer (image, name added) start
const RiderMessage: React.FC<{ message: IMessageState, image: string | undefined, name:string }> = ({ message, image, name }) => {
    // modified by Bearer Web Developer start
    return (
        <div className={styles.rider_message_box_part}>
            <div className={styles.image_part}>
                <div className={styles.image}>
                    <img src={image || "/base-avatar.webp"} alt="" width={32} height={32} />
                </div>
            </div>
            <div className={styles.arrow}></div>
            <div className={styles.rider_message_box}>
                <div className={styles.rider_message} style={{ whiteSpace: "pre-line" }}>
                    <div className={styles.name}>{name}</div>
                    <p>{message.message}</p>
                    <p className={styles.rider_message_time}>{new Date(message.timestamp).toLocaleTimeString()}</p>
                </div>
            </div>
        </div>

    )
    // modified by Bearer Web Developer end
}
// modified by Bearer Web Developer (image, name added) end
// AdminMessage added by Bearer Web Developer start
const AdminMessage: React.FC<{ message: IMessageState }> = ({ message }) => {
    // modified by Bearer Web Developer start
    return (
        <div className={styles.admin_message_box_part}>
             <div className={styles.image_part}>
             <div className={styles.image_support}>
                    <img src="/support-image.webp" alt="" width={24} height={24} />
                </div>
            </div>
            <div className={styles.arrow}></div>
            <div className={styles.admin_message_box}>
                <div className={styles.admin_message} style={{ whiteSpace: "pre-line" }}>
                    <div className={styles.support}>Support</div>
                    <p>{message.message}</p>
                    <p className={styles.admin_message_time}>{new Date(message.timestamp).toLocaleTimeString()}</p>
                </div>
            </div>
        </div>
    )
    // modified by Bearer Web Developer end
}
// AdminMessage added by Bearer Web Developer end
const OtherMessage: React.FC = () => {
    // TODO it might be helpful
    return (
        <>hello</>
    )
}

const TypeBox: React.FC<{ chat: IChatState }> = observer(({ chat }) => {
    const [message, setMessage] = React.useState("");
    const handleSendMessage = async () => {
        const valid = !!message && message.trim()
        if (valid) {
            await chat.sendMessage(message);
            setMessage("");
        }
    }
    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    }
    // modified by Bearer Web Developer start
    return (
        <div className={styles.type_box}>

            <div className={styles.field}>
                <BearerTextarea
                    colorStyle="gray"
                    className={styles.text_field}
                    placeholder="Type Something..."
                    multiline
                    variant="outlined"
                    value={message}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)} // set onChange type by Bearer Web Developer
                    onKeyPress={handleKeyPress}
                />
            </div>
            <div>
                <Observer>{() => {
                    const valid = !!message && message.trim();
                    return (
                        <div className={styles.send_part}>
                            <Button className={styles.send} onClick={handleSendMessage} disabled={!valid}>
                                <img src="/send.png" alt=">" />
                            </Button>
                        </div>
                    );
                }}</Observer>
            </div>
        </div>
    )
    // modified by Bearer Web Developer end
})

const AddressLine: React.FC = () => {
    const appState = useAppState();
    const riderInfo = appState.selectedOrder?.transport.riderInfo;
    // modified by Bearer Web Developer start
    return (
        <Timeline classes={{ root: styles.time_line }}>
            <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content }}>
                <TimelineSeparator>
                    <img src="/origin.png" alt="origin" />
                    <TimelineConnector className={''} />
                </TimelineSeparator>
                <TimelineContent className={styles.content}>{riderInfo!.origin_address}</TimelineContent>
            </TimelineItem>
            <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content, root: styles.timelineItem_root }}>
                <TimelineSeparator >
                    <img src="/destination.png" alt="destination" />
                </TimelineSeparator>
                <TimelineContent className={styles.content}>{riderInfo!.destination_address}</TimelineContent>
            </TimelineItem>
        </Timeline>
    )
    // modified by Bearer Web Developer end
}



