// this file added by Bearer Web Developer
import React from "react";
import styles from './endStep.module.scss';
import { Button } from "@material-ui/core";
import { useAppState } from "../../../states/app-state";

const EndStep: React.FC = () => {

    const appState = useAppState();
    const { createAccount, accountVerification } = appState;
    const handleClose = () => {
        if (createAccount?.complete_registeration) accountVerification?.setOpen(true);
        createAccount?.setOpen(false);
    }

    return (
        <div className={styles.end_step}>
            <div className={styles.header}>
                <h5>Welcome to Bearer!</h5>
                <div><Button onClick={handleClose}><img src="/cancel.png" alt="X" /></Button></div>
            </div>
            <div className={styles.content}>
                <p>
                    Ready to simplify your deliveries with us?
                    <br />
                    Simply choose:
                    <br />
                    + Pickup Address
                    <br />
                    + Drop-off Address
                    <br />
                    + Parcel type
                    <br />
                    + Preferred delivery method
                    <br /><br />
                    Let’s get started!
                </p>
                <img src="/four-choices.webp" alt="" width={189} height={221} />
            </div>

            <div className={''}>
                <Button className={styles.submit} onClick={handleClose}>OK</Button>
            </div>
        </div>
    )
}
export default EndStep;