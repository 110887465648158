// this file added by Bearer Web Developer
import React, { useEffect, useRef } from 'react';
import styles from './second.module.scss';
import { useAppState } from '../../../../../../../states/app-state';

const Second: React.FC = () => {

    const appState = useAppState();
    const { accountVerification } = appState;
    const second = useRef<null | any>(null);
    useEffect(() => {
        let setIntervalForSeconds: any = null;
        if (second?.current) {
            setIntervalForSeconds = setInterval(() => {
                const text = second?.current;
                if (text?.innerHTML === "00:00") {
                    clearInterval(setIntervalForSeconds);
                    accountVerification?.setEmailCodeSituation("Edit")
                    return;
                }
                if (text?.innerHTML === "01:00") text?.setHTMLUnsafe("00:59");
                else {
                    const num = Number(text?.innerHTML.split(":")[1]);
                    const newNum = num <= 10 ? `0${(num - 1)}` : (num - 1); // add 0 when second is less than 10
                    text?.setHTMLUnsafe(`${"00"}:${newNum}`);
                }
            }, 1000);
        }
        return () => {
            // Cleanup code to run when the component unmounts
            if (setIntervalForSeconds) clearInterval(setIntervalForSeconds);
        };
    }, [])

    return (
        <div className={styles.second_part}
        // onClick={() => { accountVerification.setEmailCodeSituation("Edit") }} // added by myself for test
        >
            <div className={styles.second} ref={second}>01:00</div>
        </div>
    )
}
export default Second;