// this file added by Bearer Web Developer
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Dialog } from '@material-ui/core';
import styles from './completeProfilePopup.module.scss';
import { useAppState } from '../../states/app-state';
import ProfileForm from './profileForm';
import Congratulations from './congratulations';

interface Props {
    open: boolean
}
const CompleteProfilePopup: React.FunctionComponent<Props> = observer(({ open }) => {
    const appState = useAppState();
    const { completeProfile, createAccount } = appState;
    const sign_up_stage = appState?.createAccount?.sign_up_stage;

    const handleClose = () => {
        if (sign_up_stage === 3) {
            window.location.reload();
            completeProfile?.setOpen(false);
        } else {
            // sign_up_stage = 2
            if (createAccount?.complete_registeration) return;
            completeProfile?.setOpen(false);
        }
    }
    return (
        <Dialog open={open} PaperProps={{ className: styles.root, style: { borderRadius: "10px" } }} onClose={handleClose}>
            {
                sign_up_stage === 3 ? <Congratulations /> :
                    <div className={styles.main_content}>
                        <header>
                            <h5>Complete Your Profile</h5>
                            <Button onClick={handleClose}><img src="/cancel.png" alt="X" width={28} height={28} /></Button>
                        </header>
                        <p>Complete your profile to unlock all the exciting features of Bearer!</p>
                        <ProfileForm />
                    </div>
            }
        </Dialog>
    );
});
export default CompleteProfilePopup;
