import React, { useContext } from "react";
import { Button, Drawer } from "@material-ui/core";
import ProfileMenu from "../../components/profile/ProfileMenu";
import { useAppState } from "../../states/app-state";
import { Observer } from "mobx-react-lite";
import LoginPopup from "../login/LoginPopup";
import MenuDrawer from "../../containers/menudrawer/MenuDrawer";
import styles from './BearerMenu.module.scss'; // added by Bearer Web Developer
import { MenuDrawerContext, toggleMenuDrawer } from "../../states/contexts/menuDrawer"; // added by Bearer Web Developer
import CompleteProfileButton from "./completeProfileButton"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer
// added by Bearer Web Developer start
interface Props {
    logInPopupOpen: boolean,
    setLogInPopupOpen: React.Dispatch<React.SetStateAction<boolean>>
}
// added by Bearer Web Developer end
export default function BearerMenu({logInPopupOpen,setLogInPopupOpen}: Props) { // set arguments by Bearer Web Developer

    const appState = useAppState();
    const sign_up_stage = appState?.createAccount?.sign_up_stage; // added by Bearer Web Developer
    // move login state to parent component by Bearer Web Developer
    const handleLoginPopup = () => {
        setLogInPopupOpen(true);
    }
    const handleLoginInPopupClose = () => {
        setLogInPopupOpen(false);
    }
    const menuDrawer = useContext(MenuDrawerContext); // added by Bearer Web Developer
    // modified by Bearer Web Developer start
    return (
        <>
            <LoginPopup open={logInPopupOpen} handleClose={handleLoginInPopupClose} />
            <div className={styles.root}>
                <Observer>{() => (
                    <>
                        <div className={styles.actions}>
                            <MenuDrawer />
                        </div>
                        {/* modified by Bearer Web Developer start (add CompleteProfileButton and sign_up_stage) */}
                        <div className={styles.actions}>
                            {appState.login.loggedin ? <ProfileMenu /> :
                                (sign_up_stage !== 0) ? <CompleteProfileButton /> :
                                    <Button className={styles.login} onClick={handleLoginPopup} 
                                        disableRipple={true}>Log in</Button>
                            }
                        </div>
                        {/* modified by Bearer Web Developer end (add CompleteProfileButton and sign_up_stage) */}
                        {/* added for mobile screen by Bearer Web Developer start */}
                        {
                            appState.login.loggedin &&
                            <Drawer
                                anchor={'left'}
                                open={menuDrawer.openDrawer}
                                onClose={toggleMenuDrawer(false, menuDrawer.setOpenDrawer)}
                                PaperProps={{ style: { background: "none", boxShadow: "none" } }}
                                className={styles.main_drawer_menu}
                            >
                                <div className={styles.drawer_menu}>
                                    <div className={styles.drawer_menu_items}>
                                        <div className={styles.actions}>
                                            <MenuDrawer />
                                        </div>
                                        <div className={styles.actions}>
                                            <ProfileMenu />
                                        </div>
                                    </div>
                                </div>
                            </Drawer>
                        }
                        {/* added for mobile screen by Bearer Web Developer end */}
                    </>
                )}</Observer>
            </div>
        </>
    );
    // modified by Bearer Web Developer end
}
