import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import TransportHistoriesPopup from "../order/TransportHistoriesPopup";
import { useAppState } from "../../states/app-state";
import FavouritePlacesList from "../favouritePlaces/FavouritePlacesList";
import LoginPopup from "../login/LoginPopup";
import PaymentMethod from "../price/PaymentMethod";
import { log } from "../../app/log"; // added by Bearer Web Developer
import styles from './MenuDrawer.module.scss'; // added by Bearer Web Developer
import BusinessName from "./businessName"; // added by Bearer Web Developer
import ReservedOrdersPopup from "../reservedOrdersPopup"; // added by Bearer Web Developer
import { observer } from "mobx-react-lite"; // added by Bearer Web Developer
import CreateAccountPopup from "../createAccountPopup"; // added by Bearer Web Developer
import AccountVerificationPopup from "../accountVerificationPopup"; // added by Bearer Web Developer
import CompleteProfilePopup from "../completeProfilePopup"; // added by Bearer Web Developer
import { AccessingFeatureMenuContext } from "../../states/contexts/accessingFeatureMenu"; // added by Bearer Web Developer
import AccountVerificationTwoStepPopup from "../accountVerificationTwoStepPopup"; // added by Bearer Web Developer
// this component modified by Bearer Web Developer
const MenuDrawer: React.FC = observer(() => { // observer added by Bearer Web Developer

    const appState = useAppState();
    const login = appState.login.loggedin;
    React.useEffect(() => {
        appState.getWebAppRemoteConfigParameters();
    }, [appState]);

    const [transportHistoryOpen, setTransportHistoryOpen] = React.useState(false);
    const handleTransportHistory = () => {
        setTransportHistoryOpen(true);
    }
    const handleTransportHistoryClose = () => {
        setTransportHistoryOpen(false);
    }
    // added by Bearer Web Developer start (reservedOrders state)
    const { reservedOrdersOpen, setReservedOrdersOpen } = appState?.ReservedOrders;
    const handleReservedOrders = () => {
        setReservedOrdersOpen(true);
    }
    const handleReservedOrdersClose = () => {
        setReservedOrdersOpen(false);
    }
    // added by Bearer Web Developer end (reservedOrders state)
    const { createAccount, accountVerification, completeProfile } = appState; // added by Bearer Web Developer
    const [paymentMethodOpen, setPaymentMethodOpen] = React.useState(false);
    // const handlePaymentMethod = () => {
    //     setPaymentMethodOpen(true);
    // }
    const handlePaymentMethodClose = () => {
        setPaymentMethodOpen(false);
    }

    const [logInPopupOpen, setLogInPopupOpen] = React.useState(false);
    const handleLoginInPopupClose = () => {
        setLogInPopupOpen(false);
    }
    const [favouritePlacesListOpen, setFavouritePlacesListOpen] = React.useState(false);
    const handleFavouritePlacesList = () => {
        if (!appState.login.loggedin) {
            setLogInPopupOpen(true)
        } else {
            setFavouritePlacesListOpen(true);
        }
    }
    const handleFavouritePlacesListClose = () => {
        setFavouritePlacesListOpen(false);
    }
    //  added by Bearer Web Developer start
    const { setAccessingFeatureMenuElement } = useContext(AccessingFeatureMenuContext);
    const handleOpenVerificationMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (appState?.createAccount?.sign_up_stage > 0 && appState?.createAccount?.sign_up_stage < 3) {
            setAccessingFeatureMenuElement(event?.currentTarget);
        }
    }
    //  added by Bearer Web Developer end
    //  modified by Bearer Web Developer start
    return (
        <div className={styles.root}>
            <LoginPopup open={logInPopupOpen} handleClose={handleLoginInPopupClose} />
            {/* added by Bearer Web Developer start */}
            {createAccount?.open && <CreateAccountPopup open={createAccount?.open} />}
            {completeProfile?.open && <CompleteProfilePopup open={completeProfile?.open} />}
            {accountVerification?.open && <AccountVerificationPopup open={accountVerification?.open} />}
            {accountVerification?.openTwoStep && <AccountVerificationTwoStepPopup open={accountVerification?.openTwoStep} />}
            {/* added by Bearer Web Developer end */}
            {/* the following log modified by Bearer Web Developer */}
            <FavouritePlacesList onSelect={(v) => { log(v) }} open={favouritePlacesListOpen} handleClose={handleFavouritePlacesListClose} />
            {/* added by Bearer Web Developer start  */}
            {
                login &&
                <>
                    <Button
                        onClick={handleTransportHistory}
                        disableRipple={true}
                        className={styles.button}>
                        <span className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                                <path d="M12.4583 20.125V16.2917C12.1708 15.2694 11.6636 14.5148 10.9369 14.0276C10.2102 13.5405 9.41554 13.2969 8.55304 13.2969C8.37735 13.2969 8.20165 13.3089 8.02596 13.3328C7.85027 13.3568 7.67457 13.3847 7.49888 13.4167L9.00825 14.95L7.66659 16.2917L3.83325 12.4583L7.66659 8.625L9.00825 9.96667L7.49888 11.5C7.64263 11.4681 7.79436 11.4441 7.95409 11.4281C8.11381 11.4122 8.28152 11.4042 8.45721 11.4042C9.15999 11.4042 9.86277 11.512 10.5655 11.7276C11.2683 11.9432 11.8992 12.2986 12.4583 12.7937V6.54062L10.9249 8.07396L9.58325 6.70833L13.4166 2.875L17.2499 6.70833L15.9083 8.05L14.3749 6.54062V20.125H12.4583Z" fill="#14438E" />
                            </svg>
                        </span>
                        <span>Order History</span>
                    </Button>
                    <Button
                        onClick={handleFavouritePlacesList}
                        disableRipple={true}
                        className={styles.button}>
                        <span className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M2.625 20.125V6.125C2.625 5.64375 2.79635 5.23177 3.13906 4.88906C3.48177 4.54635 3.89375 4.375 4.375 4.375H13.125C13.6062 4.375 14.0182 4.54635 14.3609 4.88906C14.7036 5.23177 14.875 5.64375 14.875 6.125V20.125L8.75 17.5L2.625 20.125ZM4.375 17.4562L8.75 15.575L13.125 17.4562V6.125H4.375V17.4562ZM16.625 17.5V2.625H5.25V0.875H16.625C17.1062 0.875 17.5182 1.04635 17.8609 1.38906C18.2036 1.73177 18.375 2.14375 18.375 2.625V17.5H16.625Z" fill="#14438E" />
                            </svg>
                        </span>
                        <span>Saved Addresses</span>
                    </Button>
                    {/* added by Bearer Web Developer start */}
                    <Button
                        onClick={handleReservedOrders}
                        disableRipple={true}
                        className={styles.button}>
                        <span className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M15.1156 17.7187L18.1562 14.7L17.2375 13.7812L15.1156 15.8594L14.2625 15.0062L13.3437 15.9469L15.1156 17.7187ZM5.25 7.875H15.75V6.125H5.25V7.875ZM15.75 20.125C14.5396 20.125 13.5078 19.6984 12.6547 18.8453C11.8016 17.9922 11.375 16.9604 11.375 15.75C11.375 14.5396 11.8016 13.5078 12.6547 12.6547C13.5078 11.8016 14.5396 11.375 15.75 11.375C16.9604 11.375 17.9922 11.8016 18.8453 12.6547C19.6984 13.5078 20.125 14.5396 20.125 15.75C20.125 16.9604 19.6984 17.9922 18.8453 18.8453C17.9922 19.6984 16.9604 20.125 15.75 20.125ZM2.625 19.25V4.375C2.625 3.89375 2.79635 3.48177 3.13906 3.13906C3.48177 2.79635 3.89375 2.625 4.375 2.625H16.625C17.1062 2.625 17.5182 2.79635 17.8609 3.13906C18.2036 3.48177 18.375 3.89375 18.375 4.375V10.2156C18.0979 10.0844 17.8135 9.975 17.5219 9.8875C17.2302 9.8 16.9312 9.73437 16.625 9.69062V4.375H4.375V16.6687H9.69062C9.76354 17.1208 9.87656 17.551 10.0297 17.9594C10.1828 18.3677 10.3833 18.7542 10.6312 19.1187L10.5 19.25L9.1875 17.9375L7.875 19.25L6.5625 17.9375L5.25 19.25L3.9375 17.9375L2.625 19.25ZM5.25 14.875H9.69062C9.73437 14.5687 9.8 14.2698 9.8875 13.9781C9.975 13.6865 10.0844 13.4021 10.2156 13.125H5.25V14.875ZM5.25 11.375H11.4625C12.0167 10.8354 12.662 10.4089 13.3984 10.0953C14.1349 9.78177 14.9187 9.625 15.75 9.625H5.25V11.375Z" fill="#14438E" />
                            </svg>
                        </span>
                        <span>Reserved Orders</span>
                    </Button>
                    {/* added by Bearer Web Developer end */}
                </>
            }
            {
                !login &&
                <>
                    <Button
                        onClick={handleOpenVerificationMenu} // added by Bearer Web Developer
                        disabled={appState?.createAccount?.sign_up_stage > 0 ? false : true} // modified by Bearer Web Developer
                        className={styles.button}>
                        <span className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                                <path d="M12.4583 20.125V16.2917C12.1708 15.2694 11.6636 14.5148 10.9369 14.0276C10.2102 13.5405 9.41554 13.2969 8.55304 13.2969C8.37735 13.2969 8.20165 13.3089 8.02596 13.3328C7.85027 13.3568 7.67457 13.3847 7.49888 13.4167L9.00825 14.95L7.66659 16.2917L3.83325 12.4583L7.66659 8.625L9.00825 9.96667L7.49888 11.5C7.64263 11.4681 7.79436 11.4441 7.95409 11.4281C8.11381 11.4122 8.28152 11.4042 8.45721 11.4042C9.15999 11.4042 9.86277 11.512 10.5655 11.7276C11.2683 11.9432 11.8992 12.2986 12.4583 12.7937V6.54062L10.9249 8.07396L9.58325 6.70833L13.4166 2.875L17.2499 6.70833L15.9083 8.05L14.3749 6.54062V20.125H12.4583Z" fill="#14438E" />
                            </svg>
                        </span>
                        <span>Order History</span>
                    </Button>
                    <Button
                        onClick={handleOpenVerificationMenu} // added by Bearer Web Developer
                        disabled={appState?.createAccount?.sign_up_stage > 0 ? false : true} // modified by Bearer Web Developer
                        className={styles.button}>
                        <span className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M2.625 20.125V6.125C2.625 5.64375 2.79635 5.23177 3.13906 4.88906C3.48177 4.54635 3.89375 4.375 4.375 4.375H13.125C13.6062 4.375 14.0182 4.54635 14.3609 4.88906C14.7036 5.23177 14.875 5.64375 14.875 6.125V20.125L8.75 17.5L2.625 20.125ZM4.375 17.4562L8.75 15.575L13.125 17.4562V6.125H4.375V17.4562ZM16.625 17.5V2.625H5.25V0.875H16.625C17.1062 0.875 17.5182 1.04635 17.8609 1.38906C18.2036 1.73177 18.375 2.14375 18.375 2.625V17.5H16.625Z" fill="#14438E" />
                            </svg>
                        </span>
                        <span>Saved Addresses</span>
                    </Button>
                    {/* added by Bearer Web Developer start */}
                    <Button
                        onClick={handleOpenVerificationMenu}  // added by Bearer Web Developer
                        disabled={appState?.createAccount?.sign_up_stage > 0 ? false : true} // modified by Bearer Web Developer
                        disableRipple={true}
                        className={styles.button}>
                        <span className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M15.1156 17.7187L18.1562 14.7L17.2375 13.7812L15.1156 15.8594L14.2625 15.0062L13.3437 15.9469L15.1156 17.7187ZM5.25 7.875H15.75V6.125H5.25V7.875ZM15.75 20.125C14.5396 20.125 13.5078 19.6984 12.6547 18.8453C11.8016 17.9922 11.375 16.9604 11.375 15.75C11.375 14.5396 11.8016 13.5078 12.6547 12.6547C13.5078 11.8016 14.5396 11.375 15.75 11.375C16.9604 11.375 17.9922 11.8016 18.8453 12.6547C19.6984 13.5078 20.125 14.5396 20.125 15.75C20.125 16.9604 19.6984 17.9922 18.8453 18.8453C17.9922 19.6984 16.9604 20.125 15.75 20.125ZM2.625 19.25V4.375C2.625 3.89375 2.79635 3.48177 3.13906 3.13906C3.48177 2.79635 3.89375 2.625 4.375 2.625H16.625C17.1062 2.625 17.5182 2.79635 17.8609 3.13906C18.2036 3.48177 18.375 3.89375 18.375 4.375V10.2156C18.0979 10.0844 17.8135 9.975 17.5219 9.8875C17.2302 9.8 16.9312 9.73437 16.625 9.69062V4.375H4.375V16.6687H9.69062C9.76354 17.1208 9.87656 17.551 10.0297 17.9594C10.1828 18.3677 10.3833 18.7542 10.6312 19.1187L10.5 19.25L9.1875 17.9375L7.875 19.25L6.5625 17.9375L5.25 19.25L3.9375 17.9375L2.625 19.25ZM5.25 14.875H9.69062C9.73437 14.5687 9.8 14.2698 9.8875 13.9781C9.975 13.6865 10.0844 13.4021 10.2156 13.125H5.25V14.875ZM5.25 11.375H11.4625C12.0167 10.8354 12.662 10.4089 13.3984 10.0953C14.1349 9.78177 14.9187 9.625 15.75 9.625H5.25V11.375Z" fill="#14438E" />
                            </svg>
                        </span>
                        <span>Reserved Orders</span>
                    </Button>
                    {/* added by Bearer Web Developer end */}
                </>
            }
            <BusinessName /> {/* added by Bearer Web Developer */}
            {/* added by Bearer Web Developer end  */}
            {appState.login.loggedin &&
                <>
                    <PaymentMethod open={paymentMethodOpen} handleClose={handlePaymentMethodClose} />
                    {/* <SettingPopup open={editSettingOpen} handleClose={handleEditSettingClose} /> */} {/* commented by Bearer Web Developer  */}
                    <TransportHistoriesPopup open={transportHistoryOpen} handleClose={handleTransportHistoryClose} />
                    <ReservedOrdersPopup open={reservedOrdersOpen} handleClose={handleReservedOrdersClose} /> {/* added by Bearer Web Developer */}
                </>
            }
        </div>
    );
    //  modified by Bearer Web Developer end
})
export default MenuDrawer;



