/* this file added by Bearer Web Developer  */
import { Button, Menu } from "@material-ui/core";
import React from "react";
import styles from './removeConfirm.module.scss';

interface Props {
    anchorEl: null | HTMLElement
    handleDelete: () => void
    handleClose: () => void
    open: boolean
    valueItem: string
}

const RemoveConfirm: React.FC<Props> = ({ anchorEl, handleDelete, handleClose, open, valueItem }) => {

    const handleDeleteItem = () => {
        handleClose();
        handleDelete();
    }

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            style={{ top: "55px", borderRadius: "10px" }}
            PaperProps={{
                style: {
                    background: "var(--neutral-hsb-89, #E3E3E3)",
                    boxShadow: "0px 38px 31px -27px rgba(53, 53, 53, 0.37)"
                }
            }}
        >
            <div className={styles.remove_confirm_menu}>
                <h4>By removing this item, all requests handled by {`'${valueItem}'`} will be rejected! Are you sure you want to proceed?</h4>
                <div className={styles.confirm}>
                    <Button onClick={handleClose} disableRipple={true} className={styles.no}><span>No</span></Button>
                    <Button onClick={handleDeleteItem} disableRipple={true} className={styles.yes}><span>Yes</span></Button>
                </div>
            </div>
        </Menu>
    )
}
export default RemoveConfirm;

