// this file added by Bearer Web Developer
import React, { useState } from "react";
import styles from './businessNumberInput.module.scss';
import BearerTextFieldWithSpace from "../../../../../components/fields/BearerTextFieldWithSpace";
import { useAppState } from "../../../../../states/app-state";
import { validateABN } from "./validation";
import { observer } from 'mobx-react-lite';
import { Button, CircularProgress, Tooltip } from "@material-ui/core";

const BusinessNumberInput: React.FC = observer(() => {

    const appState = useAppState();
    const { accountForm } = appState;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const validateBusinessNumber = async (text: string) => {
        setLoading(true);
        const errorText = await accountForm?.fetchCheckCustomerABN(text);
        if (errorText) setError(errorText);
        setLoading(false);
    }
    return (
        <div className={styles.business_number_input}>
            <label className={styles.label} htmlFor={'number'}>
                ABN <span> (Australian Business Number)</span>
                <Tooltip arrow={true}
                    title={"The Australian Business Number (ABN) is a unique 11 digit identifier issued to all entities registered in the Australian Business Register (ABR)."}
                    classes={{ tooltip: styles.tooltip, arrow: styles.arrow, }}>
                    <img src="/help-blue.webp" alt="?" width={18} height={18} />
                </Tooltip>
            </label>
            <div className={`${styles.numbers} ${accountForm?.legal_name ? styles.numbers_confirm : ''}`}>
                <BearerTextFieldWithSpace
                    disabled={loading || !!accountForm?.legal_name}
                    colorStyle="gray"
                    withoutLabel={true}
                    placeholder="___________"
                    inputProps={{ maxLength: "11" }}
                    name="number"
                    value={accountForm.businessNumber}
                    onChange={(e) => {
                        const text = e.target.value?.replace(/\D+/g, ""); // replace(/\D+/g, "") removes every string exept number or digit
                        accountForm.setBusinessNumber(text);
                        if (!validateABN(text)) validateBusinessNumber(text); // 51824753556 example valid Business Number
                    }}
                    clear={() => { accountForm.setBusinessNumber('') }} // added by Bearer Web Developer
                    validator={validateABN}
                />
                {loading && <div className={styles.loading}><CircularProgress /></div>}
                {error &&
                    <div className={styles.error}>
                        <img src="/error-red.png" alt="" />
                        <div>{error}</div>
                    </div>
                }
                {
                    accountForm?.legal_name &&
                    <div className={styles.confirm}>
                        <h4>{accountForm?.legal_name}</h4>
                        <Button className={styles.clear} onClick={() => {
                            accountForm.setBusinessNumber('');
                            accountForm.setLegalName('');
                        }}>
                            <span>Clear</span>
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
})
export default BusinessNumberInput;