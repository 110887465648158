// this file added by Bearer Web Developer
import React from "react";
import styles from './edit.module.scss';
import { Button } from "@material-ui/core";
import { useAppState } from "../../../../../../../states/app-state";

const Edit: React.FC = () => {

    const appState = useAppState();
    const { editMobileVertification } = appState;
    const handleClick = () => {
        editMobileVertification?.setOpen(true);
    }
    return (
        <div className={styles.edit_part}>
            <Button className={styles.edit} disableRipple={true} onClick={handleClick}>Edit</Button>
        </div>
    )
}
export default Edit;